import { Builder_, Dropdown_, IconSvg, Main_Plain, Main_Text_Loader, Slider_, Steps_Actions, ValidatorError } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StepContext } from '../../context/StepContext'
import { useNavigate, useParams } from 'react-router-dom'
import m from '../../services/api/Meta'
import { MetaError } from '../../styles/Layout'
import { Video_Helper } from '../videoHelper/Video_Helper'
const EshopConfigurePixel = styled.div`

    .Slider{
        h3{
            margin-bottom: 0 !important;
        }

        h4{
            margin-top: 4rem;
            margin-bottom: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid var(--greyDark);
            font-family: "fontLight", sans-serif;
            font-size: 2rem;
        }

        h6{
            color: var(--greyDark);
        }

        .NotWordpress{
            margin-top: 1rem;
        }
    }

    .FacebookLink{
        svg{
            width: .8rem;
        }
    }
`

const EshopConfigurePixelHelper = styled.div`
    border: 3px dotted ${p => p.theme.background};
    background: ${p => p.theme.mid};
    padding: var(--normalPads);
    border-radius: 1rem;

    h5{
        color: var(--greyDark);
        margin-bottom: 1rem;
    }

    svg{
        width: 2rem;
        margin-bottom: 5px;
        fill: var(--warning);
    }
`

export default function Eshop_Configure_Pixel(props) {

    const {t} = useTranslation()

    const [instructions, toggleInstructions] = useState(false);
    const { step, setStep } = useContext(StepContext);
    const [error, setError] = useState('');

    const [selectedPixel, setSelectedPixel] = useState(
        props?.Meta?.pixelId && props?.Meta?.pixelName 
          ? { id: props.Meta.pixelId, name: props.Meta.pixelName } 
          : {}
      );
          const navigate = useNavigate();
    const {lang} = useParams();
    const [loading, setLoading] = useState(false);


   // savePixel
    const save_pixel = () =>{
        setLoading(true);
        m.savePixel(selectedPixel?.id, selectedPixel?.name, props?.questId)
        .then(res => {
            // check_for_ad();
            if (res.data?.code === "C2001") navigate(`/${lang}/ad-helper`)
            else setError(res.data?.msg)
            console.log('RESSSSSSSSSSSSS',res);
            setLoading(false);
        })
        .catch((err) => setLoading(false))
    }

    const sectionData = [
        {
          title: (t("Integrate Pixel")),
          icon: "Back",
          content: [
            <h5>
              {t("Navigate to Facebook Events Manager by clicking the following link")}{" "}
              <a href="https://www.facebook.com/events_manager" target="_blank" rel="noopener noreferrer">
                {t("Facebook Events Manager")}
              </a>.
            </h5>,
          ],
        },
        {
          title: (t("Step two")),
          icon: "Back",
          content: [
            <h5>{t('Click the "Add" button, which is highlighted in green, on the left side of the screen.')}</h5>,
          ],
        },
        {
          title: (t("Step three")),
          icon: "Back",
          content: [
            <h5>{t("Enter a name for your Pixel and provide the URL (link) of your website.")}</h5>,
          ],
        },
        {
          title: (t("Step four")),
          icon: "Back",
          content: [
            <h5>{t("After creating the Pixel, get the Pixel ID generated.")}</h5>,
          ],
        },
        {
          title: (t("Step five")),
          icon: "Back",
          content: [
            <h6>{t("WORDPRESS ESHOP THAT YOU HAVE ADMIN ACCESS")}</h6>,
            <h5>
              {t('You need to download a compatible plugin that will help you integrate Facebook Pixel into your eShop like')}{" "}
              <a href="https://wordpress.org/plugins/pixelyoursite/" target="_blank" rel="noopener noreferrer">
                {t("PixelYourSite")}
              </a>.
            </h5>,
            <h5>{t("Activate it and fill in the Facebook Pixel ID in the plugin's configuration settings.")}</h5>,
            <h6 className="NotWordpress">{t("NON-WORDPRESS ESHOPS OR WITHOUT ADMIN ACCESS")}</h6>,
            <h5>{t("Email the Pixel ID to your developer team and tell them to integrate Facebook Pixel into your eShop.")}</h5>,
          ],
        },
      ];
      

    console.log('SEselectedPixel', selectedPixel, props?.questId);
    console.log('PIXELS', props.pixels);
if (loading) return <Main_Text_Loader text={t('Saving pixel..')}/>

  return (
    <EshopConfigurePixel>
      
        <h3>{t("Eshop information")}</h3>
        <Builder_ title={t("Select your facebook pixel")}>
            <Dropdown_ label={t('Pixel')}>
                <select value={selectedPixel?.id ? selectedPixel?.id : 'none'} 
                        onChange={(e) => setSelectedPixel({ id: e.target.value, name: e.target.options[e.target.selectedIndex].text })}
                  >
                    <option value={'none'} disabled>{t('Facebook pixel')}</option>
                    {props.pixels?.map((pixel) => (
                        <option
                            key={pixel.id}
                            value={pixel.id}
                        >
                            {pixel.name}
                        </option>
                    ))}
                </select>
            </Dropdown_>
        </Builder_>
        {error? 
            <ValidatorError>
            <IconSvg Icon ='Xicon' />
            <h6>{error} </h6>
            </ValidatorError> 
        : 
            null
        }
        <Video_Helper
            mainVideo={'https://www.youtube.com/watch?v=rmvoMZQdV-E'}
            mainVideoTitle={t('Questad | How to make facebook Pixel & Conversion API for Meta Ads')}
            title={t('Integrate Pixel')}
            sections = {sectionData}
            textBtn={t('How to setup Facebook Pixel')}
            text={t("If you are having trouble finding your page's pixel in the dropdown list, follow the instructions provided on how to create and successfully instegrate Facebook Pixel in your eshop.")}
        />
        <Steps_Actions
            icon_Grey="Back"
            icon_Main="Next"
            onClick_Grey={() => setStep(step - 1)}
            onClick_Main={() => save_pixel()}
            text_Grey={t("Back")}
            text_Main={t("Next")}
        />
    </EshopConfigurePixel>
  )
}
