import { Actions_, Card, CardsForty, Confirmation_, Facebook_, Grey_, Grey_Link, IconSvg, Main_Plain, PageTitle, Red_, device } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Card_Actions } from './Card_Actions'
import { useSSR, useTranslation } from 'react-i18next'
import l from '../../../services/api/Landings'
import q from '../../../services/api/Quests'
import store from '../../../redux/store'
import { prettifyDates } from '../../../helpers/prettifiers'
import Lead_ from '../../../components/leads/Lead_'
import { findLabelByValue } from '../../../utils/findCtaLabel'
import { getAdImageUrl } from '../../../utils/getAdImageUrl'
import NoImage from '../../../assets/landing/NoImage.jpg'


const clients = require('../../../config/index').config



const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
                process.env.REACT_APP_MINIO_HOST + 
                process.env.REACT_APP_MINIO_PORT+ '/' +
                process.env.REACT_APP_MINIO_BUCKET + '/';

const DashboardRight = styled.div`
    position: sticky;
    top: 6rem;
`

const DashboardRightActions = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 2.5rem;
    gap: 2rem;

    @media ${device.sm} {
        grid-template-columns: 1fr;
    }

    button{
        justify-content: center !important;
    }
`



const DashboardImgCard = styled.div`

`


const QuestionnaireCard = styled.div`
    margin-bottom: 2rem;

     h4{
        margin-bottom: 1rem;
     }
`

const QuestionnaireCardWrapper = styled.div`
    padding: 1rem;
    background: ${p => p.theme.low};
    border-radius: 1rem;
`

const QuestionnaireInnerCard = styled.div`
    margin-bottom: 2rem;
    background: ${p => p.theme.low};
    border-radius: var(--smallRadius);
    padding: var(--largePads);

    h5{
        padding-bottom: 1rem;
    }

    ul{
        display: flex;
        align-items: center;
        gap:1rem;
        flex-wrap: wrap;

        li{
            padding: var(--smallPads);
            border-radius: var(--smallRadius);
            background: ${p => p.theme.mid};
            box-shadow: ${p => p.theme.in};
        }
   }
`

const QuestionnaireCardActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
`

const DashboardImgCardInfo = styled.div`
    margin-top: 1rem;
    
    h3{
        padding-bottom: 2rem ;
        padding-top: 1rem ;
    }

    img {
        object-fit: cover;
        height: 20rem;
        width: 100%;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
`

const DashboardImgCardActionsWrapper = styled.div`

`

const DashboardImgCardActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${p => p.theme.low};
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
`

const DashboardRightTitle = styled.div`
    margin-bottom: 2rem;
`

const DashboardRightPlan = styled(Card)`
    padding: 1rem 1.5rem;
    background: ${p => p.theme.low};
    margin-bottom: 2rem;

    h6{
        color: var(--greyDark);
    }

   

    ul{
        li{
            display: flex;
            align-items: center;
            gap: .5rem;

            svg{
                width: 1.6rem;
            }
        }
    }
`

const DashboardRightPlanPrice = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;

    h1{
        color: var(--main);
    }
`

const DashboardRightLeads = styled.div`
    margin-bottom: 2rem;

    h4{
        margin-bottom: 1rem;
    }
`

const DashboardRightStatus = styled.h6`
    color: ${p => p.status === 'active' ? 'var(--success)' : 'var(--warning)'} !important;
`

const DashboardRightLeadsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-bottom: 1rem;
`

const DashboardRightLeadsActions = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2rem;
    background: ${p => p.theme.low};
    border-radius: 1rem;
`

const DashboardRightEshop = styled.div``

const DashboardRightEshopDisplay = styled.div`
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 2rem;
    padding-bottom: 0;
    border-radius: var(--smallRadius);
    background: ${p => p.theme.low};

    img{
        width: 100%;
        aspect-ratio: 8/4;
        object-fit: cover;
        border-radius: var(--smallRadius);
        margin-top: 1rem;
    }
`


export const Dashboard_Right = (props) => {
    const navigate = useNavigate();
    const {lang} = useParams();
    const [landing,setLanding] = useState();
    const {t} = useTranslation();
    const permissionToEdit = store.getState()?.user?.user?.roles.includes('Squire') ? false : true;

    const get_notifications = () =>{
        q.getNotifications(props?.selectedQuest?._id)
        .then(res => { 
          console.log('notifications',res?.data?.notifications)  
        })
        .catch(err => console.log(err))
      }
  

    const get_landing_by_id = () => {
        l.getLandingById(props?.selectedQuest?._id)
          .then(res => {
            if (res?.data?.code == 'L2003') setLanding(res?.data?.landing, res.data.code);
           // else navigate(`/${lang}/page-not-found`)
          })
    }

    const go_to_preview_ad = () => {
        store.dispatch({type:'REUSE_AD', payload: 'NOT_DECIDED'});
        store.dispatch({type:'ENTER_NEW_FLOW', payload: "EDIT_QUEST"});
        navigate(`/${lang}/preview-ad/${props?.selectedQuest?._id}`);
    }

    const go_to_edit_ad = () => {
        store.dispatch({type:'REUSE_AD', payload: 'NOT_DECIDED'});
        store.dispatch({type:'ENTER_NEW_FLOW', payload: "EDIT_QUEST"});
        if (props.selectedQuest?.draft) navigate(`/${lang}/create-ad`)
        else navigate(`/${lang}/edit-ad/${props?.selectedQuest?._id}`)
    }

    const go_to_edit_landing = () => {
        store.dispatch({type:'REUSE_LANDING', payload: 'NOT_DECIDED'});
        store.dispatch({type:'ENTER_NEW_FLOW', payload: "EDIT_QUEST"});
        if (props.selectedQuest?.draft) navigate(`/${lang}/webpage-setup`)
        else navigate(`/${lang}/edit-webpage/${props?.selectedQuest?._id}`)
    }
    
    const go_to_edit_questionnaire = () => {
        if (props.selectedQuest?.draft) navigate(`/${lang}/questionnaire/beta`)
        else navigate(`/${lang}/questionnaires/beta/edit/${props?.selectedQuest?._id}`)
    }
    
    useEffect(()=>{
        get_landing_by_id();
        get_notifications();
    },[])

    console.log('props.selectedQuest----------------', props.selectedQuest);
    const adDisplayImg= getAdImageUrl(props?.selectedQuest);
    console.log('props.topup', props.setShowTopUp);

  return (
    <DashboardRight>
        <DashboardRightTitle>
            <h5>{props?.selectedQuest?.adInfo?.facebookPageName}</h5>
            <h3>{props?.selectedQuest?.questionnaires[1]?.answer} | {props?.selectedQuest?.questionnaires[0]?.answer}</h3>
            {props?.selectedQuest?.active == true ? 
                <DashboardRightStatus status='active'> {t("Active quest")}</DashboardRightStatus>
            :
                <DashboardRightStatus> {t("Paused quest")}</DashboardRightStatus>
            }
        </DashboardRightTitle>
        <DashboardRightPlan>
            <h6>{t("Current plan")}</h6>
            <DashboardRightPlanPrice>
                <h1>€{props?.selectedQuest?.questionnaires[5]?.answer},00</h1>
                <p>{t("/month")}</p>
            </DashboardRightPlanPrice>
            <ul>
                <li>
                    <IconSvg Icon="Payment" />
                    <h5>{t("Next payment")}:</h5> 
                    {!props?.selectedQuest?.matchingSubId?.cancel_at_period_end ? 
                    <p>{(prettifyDates(props?.selectedQuest?.matchingSubId?.current_period_end, true) ? prettifyDates(props?.selectedQuest?.matchingSubId?.current_period_end, true) : '')}</p>
                    : <p>{t("Auto renewal canceled")}</p> }
                </li>
            </ul>
            {props?.selectedQuest?.draft !== true ?  
                <Actions_>
                    {/* {props?.selectedQuest?.matchingSubId?.status==="canceled" ?  */}
                    <Grey_Link 
                    noPadding
                    text={t("Restart subscription")}   
                    onClick = {()=>props?.setShowTopUp(true)}
                />  
                    {/* :
                    <Grey_Link 
                        noPadding
                        text={t("Manage subscription")}   
                        onClick = {() => window.location.assign(`${clients['griphook']}/${lang}/subscription-questad/?name=QuestAd&quest=${props?.selectedQuest?._id}`)}
                    /> 
                  
                    } */}
                    {props?.active ? 
                        <Red_ noPadding text='Stop Ads' onClick={()=> navigate(`/${lang}/cancel-subscription/${props?.selectedQuest?._id}?customer=${props?.selectedQuest?.matchingSubId?.customer}&date=${props?.selectedQuest?.matchingSubId?.current_period_end}`)}/> 
                    : 
                        null
                    }
                </Actions_>
            : 
                null
            }
        </DashboardRightPlan>
        {props?.selectedQuest?.matchingLead?.length && (props?.selectedQuest?.Meta?.pixelId == ''
        ||  !props?.selectedQuest?.Meta?.pixelId || props?.selectedQuest?.Meta?.pixelId == '2477760679178459') ? 
            <DashboardRightLeads>
                <h4>{t("Leads")}</h4>
                <DashboardRightLeadsGrid>
                    <Lead_ formLeads={props?.selectedQuest?.matchingLead?.slice(0,2)}/>
                </DashboardRightLeadsGrid>
                <DashboardRightLeadsActions>
                    <Main_Plain text={`${t("View")} [${props?.selectedQuest?.matchingLead?.length}] ${t("leads")}`} onClick={()=>navigate(`/${lang}/form-leads/${props.selectedQuest?._id}`)} iconRight="Next" noPadding/>
                </DashboardRightLeadsActions>
            </DashboardRightLeads>
        : 
            null
        }
  
  
        {props?.selectedQuest?.questionnaires[0]?.answer === 'services' ? 
            <CardsForty>
                {/* {props?.selectedQuest?.questionnaires[0]?.answer == 'services' ?  */}
                    <DashboardImgCard>
                        <h5>{t("Landing page")}</h5>
                        <DashboardImgCardInfo>
                            <img src={props.selectedQuest?.landingInfo?.image ? minioUrl+landing?.userId+'/'+props.selectedQuest?.landingInfo?.image : NoImage} />
                        </DashboardImgCardInfo>
                        <DashboardImgCardActionsWrapper>
                            <DashboardImgCardActions>
                                <Grey_Link iconLeft='EyeOpen' text={t('View')} onClick={()=> navigate(`/${lang}/webpage?id=${props?.selectedQuest?._id}`)} />
                                {permissionToEdit? <Main_Plain iconLeft='Edit' text={t('Edit')}  onClick={()=> go_to_edit_landing()}  /> : null}
                            </DashboardImgCardActions>
                        </DashboardImgCardActionsWrapper>
                    </DashboardImgCard>
                {/* :
                    null
                } */}
                <DashboardImgCard>
                    <h5>{t("Ad displays")}</h5>
                    
                        <DashboardImgCardInfo>
                            {/* <img src={ props.selectedQuest?.adInfo?.thumbnail && props.selectedQuest?.adInfo?.thumbnail !='default.jpg'  ?  props.selectedQuest?.adInfo?.thumbnail : (props?.selectedQuest?.adInfo?.visualElement ?  minioUrl+props.selectedQuest?.userId+'/'+props?.selectedQuest?.adInfo?.visualElement  : 'https://picsum.photos/200') } /> */}
                            <img src={adDisplayImg} />
                        </DashboardImgCardInfo>
                
                    <DashboardImgCardActionsWrapper>
                        <DashboardImgCardActions>
                            <Grey_Link iconLeft='EyeOpen' text={t('View')} onClick={()=> go_to_preview_ad()} />
                            {permissionToEdit ? <Main_Plain iconLeft='Edit' text={t('Edit')} onClick={()=> go_to_edit_ad()}/> : null}
                        </DashboardImgCardActions>
                    </DashboardImgCardActionsWrapper>
                </DashboardImgCard>
            </CardsForty>
        :
            <DashboardRightEshop>
                <h4>{t("Ad displays")}</h4>
                    <DashboardRightEshopDisplay>
                    {!adDisplayImg === false ? 
                        <DashboardImgCardInfo>
                            <img src={adDisplayImg} />
                        </DashboardImgCardInfo>
                    : null }
                        <p>{props?.selectedQuest?.adInfo?.caption}</p>
                        <p>{findLabelByValue(props?.selectedQuest?.adInfo?.cta, t)}</p>
                        <p>Selected catalog</p>
                        <p>{props?.selectedQuest?.Meta?.productSetName}</p>
         
                        <DashboardImgCardActions>
                            <div></div>
                            {permissionToEdit ? <Main_Plain iconLeft='Edit' text={t('Edit')} onClick={()=> go_to_edit_ad()}/> :null}
                        </DashboardImgCardActions>
                    </DashboardRightEshopDisplay>
            </DashboardRightEshop>
        }
        <QuestionnaireCard>
            <h4>{t('Campaign setup')}</h4>
            <QuestionnaireCardWrapper>
                <QuestionnaireInnerCard>
                    <h5>{t("Clients’ location")}</h5>
                    <ul>
                        {props?.selectedQuest?.questionnaires[2]?.answer.map((location, index) => (
                            <li key={index}>
                                <p>{location?.name ? location.name: location}{location?.country_name ? ', ' + location?.country_name: ''}{location?.country_code ? ', ' + location?.country_code: ''}</p>
                            </li>
                        ))}
                    </ul>
                </QuestionnaireInnerCard>
                <QuestionnaireInnerCard>
                    <h5>{t("Clients’ interests")}</h5>
                    
                    <ul>
                        {props?.selectedQuest?.questionnaires[3]?.answer.map((interest, index) => (
                            <li key={index}>
                                <p>{interest?.name ? interest.name: interest}</p>
                            </li>
                        ))}
                    </ul>
                </QuestionnaireInnerCard>
                <QuestionnaireInnerCard>
                    <h5>{t("Clients’ age")}</h5>
                    <ul>
                        <li><p>{props?.selectedQuest?.questionnaires[4]?.answer.minAge} - {props?.selectedQuest?.questionnaires[4]?.answer.maxAge}</p></li>
                    </ul>
                </QuestionnaireInnerCard>
                <QuestionnaireInnerCard>
                    <h5>{t("Clients’ gender")}</h5>
                    <ul>
                        <li><p>{props?.selectedQuest?.questionnaires[4]?.answer.gender}</p></li>
                    </ul>
                </QuestionnaireInnerCard>
                <QuestionnaireCardActions>
                    {permissionToEdit ? <Main_Plain iconLeft='Edit' text={t('Edit')}  onClick={()=>go_to_edit_questionnaire()}/> : null}
                </QuestionnaireCardActions>
            </QuestionnaireCardWrapper>
        </QuestionnaireCard>


    </DashboardRight>
  )
}
