import {
  AlertContext,
  Alert_,
  Button,
  Confirmation_,
  Grey_Link,
  IconSvg,
  Main_,
  Main_Plain,
  Notify_,
  PageAlwaysVisible,
  PageDesktopOnly,
  PageDesktopPadding,
  PageSixty,
  PageTitle,
  QuestadComission,
  Slider_,
  device,
} from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Dashboard_Card from "./components/Dashboard_Card";
import q from "../../services/api/Quests";
import p from "../../services/api/Payments";

import {checkIfUserIsNotified, markUserAsNotified} from "../../services/api/Users";
import {
  check_for_existing_lead_field,
  check_status_of_quests_and_organize_them_in_sections,
} from "./utils";
import Dashboard_No_Quests from "./components/noQuests/Dashboard_No_Quests";
import Dashboard_Loading from "./components/loading/Dashboard_Loading";
import NoImg from "../../assets/ad/NoImgAd.jpg";
import { useNavigate, useParams } from "react-router-dom";
import store from "../../redux/store";
import { alertMessage } from "../../utils/messagesAlerts";
import FbLogo from "../../assets/dashboard/FbLogo.png";
import Subscription_Topup from "./components/subscription/Subscription_Topup";
import Dashboard_Notifications from "./components/notifications/Dashboard_Notifications";
import { ViewContext } from "./contexts/ViewContext";
import { SidebarContext } from "./contexts/SidebarContext";
import Dashboard_Archived from "./components/archived/Dashboard_Archived";
import Subscription_Topup_Archived from "./components/subscription/Subscritpion_Topup_Archived";
import Dashboard_Leads from "./components/leads/Dashboard_Leads";
import { campaign_of_type_instant_form_exists } from "../editLanding/utils";
import { getAdImageUrl } from "../../utils/getAdImageUrl";
import { findLabelByValue } from "../../utils/findCtaLabel";
import { PocketAgencyContext } from "../../context/PocketAgencyContext";
import Archived_Old_Preview from "./components/archived/Archived_Old_Preview";
import Notification_ from "../../components/notification/Notification_";

const clients = require('../../config/index').config

const minioUrl =
  process.env.REACT_APP_MINIO_PROTOCOL +
  process.env.REACT_APP_MINIO_HOST +
  process.env.REACT_APP_MINIO_PORT +
  "/" +
  process.env.REACT_APP_MINIO_BUCKET +
  "/";

const Dashboard = styled(PageSixty)`
  grid-template-columns: ${p => p.notifications ? "1fr 25rem" : "1fr"};
  height: calc(100vh - 6rem);
  overflow: hidden;

  @media ${device.md} {
    grid-template-columns: 1fr;
  }

  
 
`;

const DashboardActions = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
`

const DashboardToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const NotifyText = styled.div`
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p{
    padding-bottom: 1rem;
  }
`

const DashboardNotificationsButton = styled.div`
  display: none;

  /* @media ${device.md} {
    display: block;
    background: ${p => p.theme.mid};
    border-radius: var(--normalRadius);

    button{
      background: transparent;
      position: relative;

      svg{
        fill: ${p => p.theme.color};
        width: 2rem;
      }

      span{
        color: ${p => p.theme.color};
      }

      .New{
        position: absolute;
        top: 0rem;
        right: 0rem;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background: var(--error);
      }
    }
  }

  @media ${device.sm} {

    button{
      span{
        display: none;
      }
    }
  } */
`

const DashboardNew = styled.div`
  border-radius: var(--normalRadius);
  background: var(--main);
  position: relative;
`;

const DashboardNewOpen = styled(Button)`
  border-radius: var(--normalRadius);
  background: var(--main);

  span {
    color: var(--white);
  }

  svg {
    fill: var(--white);
    width: 1.2rem;
  }
`;

const DashboardNewList = styled.ul`
  position: absolute;
  right: 0;
  top: 4rem;
  z-index: 3;
  background: var(--main);
  border-radius: 2rem;
  padding: 0.5rem 0;

  .BorderTop {
    border-top: 1px solid ${(p) => p.theme.background};
    padding-top: 1rem;
    margin-top: 0.5rem;
  }
`;

const DashboardNewItem = styled.li`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem 1.5rem;
  cursor: pointer;

  svg {
    fill: var(--white);
    width: 1.5rem;
  }

  h5 {
    white-space: nowrap;
    color: var(--white);
  }
`;

export default function DASHBOARD() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang, questId } = useParams();

  const permissionToEdit = store
    .getState()
    ?.user?.user?.roles.includes("Squire")
    ? false
    : true;

  const { alerts, setAlerts } = useContext(AlertContext);

  const [sidebar, toggleSidebar] = useState(false);
  const [view, setView] = useState('viewStats');
  const [confirmation, setConfirmation] = useState(false);
  const [newQuest, toggleNewQuest] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [quests, setQuests] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedQuest, setSelectedQuest] = useState(false);
  const [selectedArchivedQuest, setSelectedArchivedQuest] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState("clicks");
  const [activeQuests, setActiveQuests] = useState([]) 
  const [archivedQuests, setArchivedQuests] = useState([]);
  const [draftQuests, setDraftQuests] = useState([]);
  const [kpiList, setKpiList] = useState([]);
  const [mergedGraphicalKpis, setMergedGraphicalKpis] = useState([])
  const [mergedGraphicalIntervalKpis, setMergedGraphicalIntervalKpis] = useState([])
  const [adBudget, setAdBudget] = useState(0);
  const [interval, setInterval] = useState(true);
  const [topup, toggleTopup] = useState(false);
  const [slider, setSlider] = useState({active: "",status: false})
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [renewConfirm, setRenewConfirm] = useState(false);
  const {pocketAgencyContext, setPocketAgencyContext} = useContext(PocketAgencyContext)
  const [notifiedUser, setNotifiedUser] = useState(true) 

  const get_my_notifications = () => {
    q.getAllNotifications()
      .then((res) => {
        console.log("notifications", res?.data?.notifications);
        setNotifications(res?.data?.notifications);
      })
      .catch((err) => console.log(err));
  };

  const get_my_quests = () => {
    q.getMyQuestsWithKPIS(false).then((res) => {
      console.log(' res?.data?.data,-----------------------------',res?.data?.data );
      if (res?.data?.code == "Q5006") {
        setQuests(res?.data?.data)
        q.healthCheckQuests().then((health) => {
          console.log('HUH', health?.data?.data)
          check_status_of_quests_and_organize_them_in_sections(
            res?.data?.data,
            setQuests,
            setLoading,
            health?.data?.data
          );
        });
      }
    });
  };

  const check_for_drafts = (quests, navigate, lang, setConfirmation) => {
    let foundDraft = false;
    quests?.forEach((element) => {
      if (element?.draft == true) foundDraft = element?._id;
    });
    if (foundDraft) navigate(`/${lang}/new-quest/${foundDraft}`)
      //  setConfirmation(foundDraft);
    else {
      // notifies frontend that the flow we are about to enter is "START_NEW_QUEST"
      store.dispatch({ type: "REUSE_LANDING", payload: "NOT_DECIDED" });
      store.dispatch({ type: "REUSE_AD", payload: "NOT_DECIDED" });
      store.dispatch({ type: "ENTER_NEW_FLOW", payload: "START_NEW_QUEST" });
      navigate(`/${lang}/questionnaire/alpha`, {
        state: { flow: "START_NEW_QUEST" },
      });
    }
  };



  const get_ad_image = () => {

    if (selectedQuest?.Meta?.mode ==='productCatalog' && selectedQuest?.Meta?.productSetImg) {
      return selectedQuest?.Meta?.productSetImg;
    }

    if (
      selectedQuest?.adInfo?.thumbnail &&
      selectedQuest?.adInfo?.thumbnail !== "default.jpg"
    ) {
      return selectedQuest?.adInfo?.thumbnail;
    }

 
    if (selectedQuest?.Meta?.mode !=='productCatalog' && selectedQuest?.adInfo?.visualElement) {
      return `${minioUrl}${selectedQuest?.userId}/${selectedQuest?.adInfo?.visualElement}`;
    }

    return NoImg;

  };

  const go_to_preview_ad = () => {
    store.dispatch({ type: "REUSE_AD", payload: "NOT_DECIDED" });
    store.dispatch({ type: "ENTER_NEW_FLOW", payload: "EDIT_QUEST" });
    navigate(`/${lang}/preview-ad/${selectedQuest?._id}`);
  };

  const go_to_edit_ad = () => {
    store.dispatch({ type: "REUSE_AD", payload: "NOT_DECIDED" });
    store.dispatch({ type: "ENTER_NEW_FLOW", payload: "EDIT_QUEST" });
    if (selectedQuest?.draft) navigate(`/${lang}/create-ad`);
    else navigate(`/${lang}/edit-ad/${selectedQuest?._id}`);
  };

  const go_to_edit_landing = () => {
    store.dispatch({ type: "REUSE_LANDING", payload: "NOT_DECIDED" });
    store.dispatch({ type: "ENTER_NEW_FLOW", payload: "EDIT_QUEST" });
    if (selectedQuest?.draft) navigate(`/${lang}/webpage-setup`);
    else navigate(`/${lang}/edit-webpage/${selectedQuest?._id}`);
  };

  const go_to_edit_questionnaire = () => {
    if (selectedQuest?.draft) navigate(`/${lang}/questionnaire/beta`);
    else navigate(`/${lang}/questionnaires/beta/edit/${selectedQuest?._id}`);
  };

  const calculate_ad_budget = (amountPaid) => {
    let normalBudget = amountPaid / 100;
    let commision = QuestadComission(normalBudget);
    let budget = normalBudget - commision?.fee - normalBudget * 0.24;
    return budget;
  };

  const mark_notification_read = (id) => {
    let readNotifications = notifications?.filter(notification => notification?.questId === id);
    let readNotificationsIds = readNotifications?.map((notification) => notification?._id);

    console.log('ID', id);
    
    q.markAsRead(readNotificationsIds).then(res => {
      get_my_notifications();

      console.log('res readNotificationsIds', readNotificationsIds);
      
     })
  }

  const handleCancelSubscription = () => {

    p.cancelSubscription(selectedQuest?.matchingSubId?.subscription)
    .then(res => {
      console.log(res);
      if(res.data.code === "CSS2000") {
        setSelectedQuest((prevState) => ({
          ...prevState,
          matchingSubId: {
            ...prevState.matchingSubId,
            cancel_at_period_end: true
          }  
        }));

        setActiveQuests((prevQuests) => prevQuests.map(quest => 
          quest._id === selectedQuest._id ? { 
            ...quest, 
            matchingSubId: { 
              ...quest.matchingSubId, 
              cancel_at_period_end: true 
            } 
          } : quest
        ));

        alertMessage(alerts, setAlerts, (t("Your plan is canceled")), "Success");

      }
      else alertMessage(alerts, setAlerts, (t("Something went wrong")), "Error");
    })
    .catch(err => console.log(err))
  }

  const handleRenewSubscription = () => {
    
    p.renewSubscription(selectedQuest?.matchingSubId?.subscription)
    .then(res => {
      console.log(res);
      if(res.data.code === "UCS2000") {
        setSelectedQuest((prevState) => ({
          ...prevState,
          matchingSubId: {
            ...prevState.matchingSubId,
            cancel_at_period_end: false
          }  
        }));


        setActiveQuests((prevQuests) => prevQuests.map(quest => 
          quest._id === selectedQuest._id ? { 
            ...quest, 
            matchingSubId: { 
              ...quest.matchingSubId, 
              cancel_at_period_end: false 
            } 
          } : quest
        ));

        alertMessage(alerts, setAlerts, (t("Your plan is renewed")), "Success");

      }
      else alertMessage(alerts, setAlerts, (t("Something went wrong")), "Error");
    
    })
    .catch(err => { console.log(err); })
  }

  const check_if_user_is_notified = () => {
    checkIfUserIsNotified()
    .then((res) => {
      setNotifiedUser(res?.data?.data?.notifiedUser)
      console.log(' res?.data?.data, NOTIF USER-----------------------------',res?.data?.data?.notifiedUser );
     
    });
  };

  const mark_user_as_notified = () => {
    markUserAsNotified()
    .then((res) => {
     setNotifiedUser(true)
      console.log(' res?.data?.data, NOTIF USER-----------------------------',res?.data?.data?.notifiedUser );
     
    });
  };


  const calculateDurationInDays = (startTimestamp) => {
    const startDate = new Date(startTimestamp * 1000); // Convert to milliseconds
    const today = new Date(); // Get the current date
  
    // Calculate the difference in days
    return Math.round((today - startDate) / (1000 * 60 * 60 * 24)+1); // Difference in days
  };
  

  useEffect(() => {
    check_if_user_is_notified();
    get_my_notifications();
    get_my_quests();
  }, []);

  useEffect(() => {
    if (quests) {
      setActiveQuests(
        quests
          ?.filter((quest) => quest?.active)
          ?.sort((a, b) => {
            const statusOrder = { ACTIVE: 1, PAUSED: 2 };
            const aStatus = statusOrder[a.campaignStatus] || 3;
            const bStatus = statusOrder[b.campaignStatus] || 3;
            return aStatus - bStatus;
          })
      );
      setArchivedQuests(quests?.filter((quest) => !quest?.draft && !quest?.active))
      setDraftQuests(quests?.filter((quest) => quest?.draft))

      setLoading(false);
    }
  }, [quests]);

  useEffect(() => {
    const savedSelectedQuest = store.getState().selectedQuest;
    console.log('savedSelectedQuest', savedSelectedQuest);
  
    if (savedSelectedQuest && activeQuests.some(item => item._id === savedSelectedQuest)) {
      console.log('savedSelectedQuest', savedSelectedQuest);
  
        const savedSelectedQuestInfo =  activeQuests.find(quest => quest?._id === savedSelectedQuest)
        console.log('savedSelectedQuestInfo', savedSelectedQuestInfo);
        setSelectedQuest(savedSelectedQuestInfo)
     }
   else if (activeQuests?.length) {
      if (!questId) handleSelectedQuest(activeQuests[0]);
      else {
        let index = activeQuests.findIndex(item => item?._id == questId)
        if (index !== -1) handleSelectedQuest(activeQuests[index])
        else handleSelectedQuest(activeQuests[0]);
      }
    }
    else if (archivedQuests?.length) {
      handleSelectedQuest(archivedQuests[0]);
    }
    else if (draftQuests?.length) {
      handleSelectedQuest(draftQuests[0]);
    }
  }, [activeQuests]);

  console.error(selectedQuest?._id)

  useEffect(() => {
    if (selectedQuest) {
      const mergeKpis = (kpis, kpisFromAgency) => {
        const merged = { ...kpis };
        if (kpisFromAgency) {
          Object.entries(kpisFromAgency).forEach(([key, value]) => {
            merged[key] = (merged[key] || 0) + value;
          });
        }
        return merged;
      };
  
      if (interval) {
        const mergedIntervalKpis = mergeKpis(
          selectedQuest?.matchingIntervalKpis,
          selectedQuest?.matchingIntervalKpisFromAgency
        );
  
        setKpiList(
          Object.entries(mergedIntervalKpis)
            .filter(
              ([key]) =>
                key !== "spend" &&
                key !== "page_views" &&
                key !== "post_engagement"
            )
            .map(([key, value]) => ({ key, value }))
        );
      } else {
        const mergedKpis = mergeKpis(
          selectedQuest?.matchingKpis,
          selectedQuest?.matchingKpisFromAgency
        );
  
        setKpiList(
          Object.entries(mergedKpis)
            .filter(
              ([key]) =>
                key !== "spend" &&
                key !== "page_views" &&
                key !== "post_engagement"
            )
            .map(([key, value]) => ({ key, value }))
        );
      }
    }
  }, [selectedQuest, interval]);
  


  const getLandingCustom = (customField) => {
    if (!customField?.length && customField?.label === '') {
      return [];
    }
    if (!customField?.length) {
      return [{ ...customField, type: 'input' }];
    }
    return customField;
  };
  

  const handleSelectedQuest = (quest) =>{
    store.dispatch({type:'SELECTED_QUEST', payload: quest? quest?._id : {}});
    setSelectedQuest(quest);
  }


  console.log("ALL:", quests);
  console.log("ACTIVE:", activeQuests);
  console.log("SELECTED:", selectedQuest);
  console.log("draftQuests:", draftQuests);
  console.log("KPI_LIST", kpiList);
  console.log("SLECTED KPI GRAPH", selectedGraph);
  console.log("RENDERED AD IMAGE", get_ad_image());
  console.log("ALL NOTIFICATIONS", notifications);

  // console.log('getAdImageUrl(selectedQuest)', getAdImageUrl(selectedQuest));
  console.error('CONTEXT', pocketAgencyContext)
  console.log('selectedQuest?.matchingSubId', selectedQuest?.matchingSubId);
  const mergeGraphicalKpis = (kpis1, kpis2) => {
    const result = {};

    const mergeValues = (key, values) => {
        if (!result[key]) {
            result[key] = [];
        }
        result[key] = [...result[key], ...values].sort(
            (a, b) => new Date(a.date) - new Date(b.date)
        );
    };

    kpis1?.forEach(kpi => mergeValues(kpi.key, kpi.values));
    kpis2?.forEach(kpi => mergeValues(kpi.key, kpi.values));

    setMergedGraphicalKpis(Object.entries(result).map(([key, values]) => ({ key, values })))
};


const mergeGraphicalIntervalKpis = (kpis1, kpis2) => {
  const result = {};

  const mergeValues = (key, values) => {
      if (!result[key]) {
          result[key] = [];
      }
      result[key] = [...result[key], ...values].sort(
          (a, b) => new Date(a.date) - new Date(b.date)
      );
  };

  kpis1?.forEach(kpi => mergeValues(kpi.key, kpi.values));
  kpis2?.forEach(kpi => mergeValues(kpi.key, kpi.values));

  setMergedGraphicalIntervalKpis(Object.entries(result).map(([key, values]) => ({ key, values })))
};
  useEffect(() => {
    mergeGraphicalKpis(selectedQuest?.graphicalKpis, selectedQuest?.graphicalKpisFromAgency)
    mergeGraphicalIntervalKpis(selectedQuest?.graphicalIntervalKpis, selectedQuest?.graphicalIntervalKpisFromAgency)
}, [selectedQuest]);


console.log('MERGED', mergedGraphicalIntervalKpis, mergedGraphicalKpis);
console.log('MERGED',  mergedGraphicalKpis[1]?.values?.length, mergedGraphicalIntervalKpis[1]?.values?.length );
console.log('selectedArchivedQuest !@@', selectedArchivedQuest);

  
  return (
    <SidebarContext.Provider value={{sidebar, toggleSidebar}}>
      <ViewContext.Provider value={{view, setView}}>
        <Dashboard notifications={notifications?.length}>
          <Helmet>
            <title>Questad | Dashboard</title>
          </Helmet>
          <Alert_ messages={alerts} />
       
          {cancelConfirm && (
            <Confirmation_
              message={t("Are you sure you want to cancel your subscription?")}
              yes={t("Yes")}
              onYes={() => {handleCancelSubscription(); setCancelConfirm(false)}}
              onNo={() => setCancelConfirm(false)}
              no={t("No")}
            />
            )}
          {renewConfirm && (
              <Confirmation_
              message={t("Are you sure you want to renew your subscription?")}
              yes={t("Yes")}
                onYes={() => {handleRenewSubscription(); setRenewConfirm(false)}}
                onNo={() => setRenewConfirm(false)}
                no={t("No")}
              />
            )}

            {!notifiedUser ? 
              <Notify_ 
                onClose={()=> mark_user_as_notified()}
              >
                <NotifyText>
                  <h2>{t('Important update')}</h2>
                  <p>{t('We would like to inform you about the update to our pricing policy, effective as of today. Specifically, the tiered commission (ranging from 9% to 4%, depending on the advertising budget) previously in effect will now be replaced by a flat 13% commission, excluding VAT, applied to all new advertising quests created from this date forward.')}</p>
                  <p><strong>{t("Important Note,")}</strong> {t('Existing subscriptions and all advertising campaigns (quests) that are active or were created prior to this update will continue to be billed under the previous pricing policy.')}</p>
                  <p>{t("We remain at your disposal for any questions or clarifications you may need.")}</p>
                  <Main_ text={t("Got it")} iconLeft='Check' onClick={()=> mark_user_as_notified()}
                  />
                </NotifyText>
              </Notify_>
              : null}
            
   
          {slider.status ? 
            <>
              {slider.active === 'topup' ?
                <Slider_ onClose={() => setSlider({active:"",status:!slider.status})} title={t("Edit subscription's adspend")}>
                  <Subscription_Topup
                    selectedQuestId={selectedQuest?._id}
                    customerId={selectedQuest?.matchingSubId?.customer}
                    selectedSubId={selectedQuest?.matchingSubId?.subscription}                
                    isOldBudgetUser={selectedQuest?.matchingSubId?.isOldBudgetUser || false}                
                    totalBudget={selectedQuest?.amountPaid / 100}
                    onSubscriptionTopupCancel={() => setSlider({active:"",status:!slider.status})}
                    subscriptionTopup={t("Increase budget")}
                    subscriptionTopupMinimum={1}
                    adspend={selectedQuest?.questionnaires[5]?.answer}
                    onTopupSuccess={() => get_my_quests()}
                    subscriptionNextPayment={
                      selectedQuest?.matchingSubId?.current_period_end
                    }
                  />
                </Slider_>
              : slider.active === 'previewArchived' ?
                //  {selectedQuest ? 
                    <Archived_Old_Preview
                      onClose={() => setSlider({active:"",status:!slider.status})}
                      // archivedQuests={props.archivedQuests}
                      selectedQuest={selectedArchivedQuest}
                      // setSelectedQuest={setSelectedQuest}
          
                    />    
                    // :null }
              :slider.active === 'leads' ?
                <Slider_ title={t("Prospect leads")} text={t("Done")} onClick={() => setSlider({active:"",status:!slider.status})} onClose={() => setSlider({active:"",status:!slider.status})}>
                  <Dashboard_Leads
                    selectedQuestId={selectedQuest?._id}
                    leadsTotal={selectedQuest?.matchingLeadTotal}
                  />
                </Slider_>
                
              : slider.active === 'topupArchived' ? (
            <Slider_ onClose={() => setSlider({active:"",status:!slider.status})} title={t("Edit subscription's budget")}>
              <Subscription_Topup_Archived
                adspend={selectedQuest?.questionnaires[5]?.answer}
                selectedQuestId={selectedArchivedQuest?._id}
                selectedSubId={selectedArchivedQuest?.matchingSubId?.subscription}  
                isOldBudgetUser={selectedQuest?.matchingSubId?.isOldBudgetUser || false}                         
                totalBudget={selectedArchivedQuest?.amountPaid / 100}
                onSubscriptionTopupCancel={() => setSlider({active:"",status:!slider.status})}
                subscriptionTopup={t("Increase budget")}
                subscriptionTopupMinimum={1}
                customerId={selectedArchivedQuest?.matchingSubId?.customer}
              />
            </Slider_>
          )
            : slider.active === 'archivedLeads' ?
              <Slider_ title={t("Prospect leads")} text={t("Done")} onClick={() => setSlider({active:"",status:!slider.status})} onClose={() => setSlider({active:"",status:!slider.status})}>
                <Dashboard_Leads
                  selectedArchivedQuest={selectedArchivedQuest?._id}
                  leadsTotal={selectedQuest?.matchingLeadTotal}
                />
              </Slider_> : null}
            </>
          : 
            null
          }
             
          <PageAlwaysVisible>
            {notifications?.find(n=>n.source==='Miniverse' && !n.read) ?
              <Notification_ 
                onClose={()=>{
                  mark_notification_read(notifications?.find(n=>n.source==='Miniverse' && !n.read)?.questId)
                  window.open(`${clients['miniverse']}/${lang}/`)}
                }
                onMain={()=>mark_notification_read(notifications?.find(n=>n.source==='Miniverse' && !n.read)?.questId)}
              />
            : null}
            <PageTitle cta>
              <h1>{t("Dashboard")}</h1>
              {!loading ?
                <Main_
                  iconLeft="Add"
                  text={t("New quest")}
                  onClick={() =>
                    check_for_drafts(quests, navigate, lang, setConfirmation)
                  }
                />
              :
                null
              }
            </PageTitle>
            {quests?.length && selectedQuest ?
              <DashboardActions>
                <DashboardToolbar>
                  {!sidebar ? (
                    <Main_Plain
                      noPadding
                      text={t("Show toolbar")}
                      iconRight="Next"
                      onClick={() => toggleSidebar(!sidebar)}
                    />
                  ) : (
                    <Main_Plain
                      noPadding
                      text={t("Hide toolbar")}
                      iconLeft="Back"
                      onClick={() => toggleSidebar(!sidebar)}
                    />
                  )}
                </DashboardToolbar>
                <DashboardNotificationsButton>
                  <Button size="normal">
                    <IconSvg Icon="Notifications" />
                    <div className="New" ></div>
                    <span>{t("Notifications")}</span>
                  </Button>
                </DashboardNotificationsButton>
              </DashboardActions>
            :
              null
            }
            {quests?.length && selectedQuest ? (
              <Dashboard_Card
                //GLOBAL
                isPocketAgencyUser = {pocketAgencyContext === 'E2000' ?  true : false}
                selectedQuestId={selectedQuest?._id}
                onNewQuest={() => check_for_drafts(quests, navigate, lang, setConfirmation)}
                // avatar={selectedQuest?.Meta?.facebookPagePicture} //Uncomment when we save fb images to our server
                // avatar={FbLogo}
                adspend={selectedQuest?.questionnaires[5]?.answer}
                // adspend = {Number(selectedQuest?.questionnaires[5]?.answer || 0).toFixed(2)}
                // adspend = {Number(selectedQuest?.questionnaires[5]?.answer || 0).toFixed(2).replace(".", ",")}

                page={selectedQuest?.Meta?.facebookPageName}
                pagePicture={selectedQuest?.Meta?.facebookPagePicture}
                type={selectedQuest?.questionnaires[0]?.answer}
                industry={selectedQuest?.questionnaires[1]?.answer}
                onChangeActiveQuest={(newSelectedQuest) =>
                  handleSelectedQuest(newSelectedQuest)
                }
                activeQuests={activeQuests}
                archivedQuests={archivedQuests}
                draftQuests={draftQuests}
                //STATS
                status={selectedQuest?.campaignStatus}
                leadsOverview={interval 
                  ? selectedQuest?.leadsIntervalTotal
                  : selectedQuest?.matchingLeadTotal
                }
                amountPaid={selectedQuest?.amountPaid / 100 || selectedQuest?.matchingSubId?.amount / 100}
                amountTopUp={selectedQuest?.newBudget ? selectedQuest.newBudget / 100 : false}
                subscriptionStatus={selectedQuest?.matchingSubId?.status}
                subscriptionCancelOnPeriodEnd={selectedQuest?.matchingSubId?.cancel_at_period_end}
                subscriptionNextPayment={
                  selectedQuest?.matchingSubId?.current_period_end
                }
                totalBudget={calculate_ad_budget(selectedQuest?.amountPaid)}
                // days={
                //   interval
                //     ? selectedQuest?.graphicalIntervalKpis[1]?.values?.length
                //     : selectedQuest?.mergedGraphicalKpis[1]?.values?.length
                // }
                days={
                  interval
                    ?calculateDurationInDays(selectedQuest?.matchingSubId?.current_period_start) 
                    : mergedGraphicalKpis[1]?.values?.length
                }
                spentBudget={
                  interval
                    ? selectedQuest?.matchingIntervalKpis?.spend
                    : selectedQuest?.matchingKpis?.spend
                }
                engagement={
                  interval
                    ? selectedQuest?.matchingIntervalKpis?.post_engagement
                    : selectedQuest?.matchingKpis?.post_engagement
                }
                pageviews={
                  interval
                    ? selectedQuest?.matchingIntervalKpis?.page_views
                    : selectedQuest?.matchingKpis?.page_views
                }
                //LEADS
                onInterval={() => setInterval(!interval)}
                interval={interval}
                leads={interval 
                  ? selectedQuest?.matchingIntervalLead
                  : selectedQuest?.matchingLead
                }
                leadsTotal={selectedQuest?.matchingLeadTotal}
                leadName={check_for_existing_lead_field(
                  selectedQuest?.matchingLead,
                  "Name"
                )}
                leadSurname={check_for_existing_lead_field(
                  selectedQuest?.matchingLead,
                  "Surname"
                )}
                leadTel={check_for_existing_lead_field(
                  selectedQuest?.matchingLead,
                  "Tel"
                )}
                leadEmail={check_for_existing_lead_field(
                  selectedQuest?.matchingLead,
                  "Email"
                )}
                leadCustomField={check_for_existing_lead_field(
                  selectedQuest?.matchingLead,
                  "CustomField"
                )}
                leadCreatedAt={check_for_existing_lead_field(
                  selectedQuest?.matchingLead,
                  "createdAt"
                )}
                graphTotal={
                  interval
                    ? selectedQuest?.matchingIntervalKpis[selectedGraph]
                    : selectedQuest?.matchingKpis[selectedGraph]
                }
                graphTitle={selectedGraph}
                // graphData={
                //   interval
                //     ? selectedQuest?.graphicalIntervalKpis?.find(
                //         (item) => item?.key == selectedGraph
                //       )?.values
                //     : selectedQuest?.mergeGraphicalKpis?.find(
                //         (item) => item?.key == selectedGraph
                //       )?.values
                // }
                graphData={
                  interval
                    ? mergedGraphicalIntervalKpis?.find(
                        (item) => item?.key == selectedGraph
                      )?.values
                    : mergedGraphicalKpis?.find(
                        (item) => item?.key == selectedGraph
                      )?.values
                }

                kpis={kpiList}
                onKpi={(kpi) => setSelectedGraph(kpi)}
                kpiSelected={selectedGraph}
                //HISTORY
                historyNotifications={notifications?.filter( (notification) => notification?.questId === selectedQuest?._id && notification?.source === 'History')}
                //RECCOMENDATIONS
                reccomendationsNotifications={notifications?.filter( (notification) => notification?.questId === selectedQuest?._id && notification?.source === 'Admin')}
                //EDIT
                adCaption={selectedQuest?.adInfo?.caption}
                // adImg={get_ad_image()}
                adImg={get_ad_image()}
                adTitle={selectedQuest?.adInfo?.title}
                adUrl={selectedQuest?.eShopUrl}
                adCta={findLabelByValue(selectedQuest?.adInfo?.cta,t)}
                onAdView={() => go_to_preview_ad()}
                onAdEdit={() => go_to_edit_ad()}
                landingImg={campaign_of_type_instant_form_exists(selectedQuest?.campaigns) ? get_ad_image() :`${minioUrl}${selectedQuest?.userId}/${selectedQuest?.landingInfo?.image}`}
                landingHero={selectedQuest?.landingInfo?.title}
                landingSub={selectedQuest?.landingInfo?.subtitle}
                landingFields={selectedQuest?.landingInfo?.defaultFormFields}
                landingCustom={getLandingCustom(selectedQuest?.landingInfo?.customFormField)}
                // landingCustom={!selectedQuest?.landingInfo?.customFormField?.length && selectedQuest?.landingInfo?.customFormField?.label ==='' ? [] 
                //               : convert_to_array(selectedQuest?.landingInfo?.customFormField)}
                onLandingView={() =>
                  navigate(`/${lang}/webpage?id=${selectedQuest?._id}`)
                }
                hasLandingEdit={permissionToEdit}
                onLandingEdit={() => go_to_edit_landing()}
                questLocations={selectedQuest?.questionnaires[2]?.answer}
                questInterests={selectedQuest?.questionnaires[3]?.answer}
                questMinAge={selectedQuest?.questionnaires[4].answer?.minAge}
                questMaxAge={selectedQuest?.questionnaires[4].answer?.maxAge}
                questGender={selectedQuest?.questionnaires[4].answer?.gender}
                questSpecialCategory={selectedQuest?.questionnaires[1]?.specialAnswer}
                onQuestEdit={() => go_to_edit_questionnaire()}
                //ACTIVE
                activeSetSelectedQuest={(quest) => setSelectedQuest(quest)}
                // pendingCancelationQuests
                //ARCHIVED
                archivedSetSelectedQuest={(quest) => setSelectedArchivedQuest(quest)}
                archivedOpenBudget={() => setSlider({active:"topupArchived",status:!slider.status})}
                //activeOpenBudget={() => setSlider({active:"topup",status:!slider.status})}
                archivedRestart={t("Restart subscription")}
                archivedOpenLeads={() => setSlider({active:"archivedLeads",status:!slider.status}) }
                //SUBSCRIPTION
                subscriptionTopup={t("Increase budget")}
                onSubscriptionTopup={() => setSlider({active:"topup",status:!slider.status})}
                // onSubscriptionDetails={props.onSubscriptionDetails}
                onSubscriptionRenew={() => setRenewConfirm(true)}
                onSubscriptionCancel={() => setCancelConfirm(true)}
                customerId={selectedQuest?.matchingSubId?.customer}
                isOldBudgetUser={selectedQuest?.matchingSubId?.isOldBudgetUser || false}       
                onPreviewArchived={() => setSlider({active:"previewArchived",status:!slider.status})}
              />
            ) : loading ? (
              <Dashboard_Loading />
            ) 
            :
            (
              <Dashboard_No_Quests onNoQuestsAdd={() => check_for_drafts(quests, navigate, lang, setConfirmation)} />
            )}
          </PageAlwaysVisible>
          {notifications?.length ?
            <PageDesktopOnly>
              <PageDesktopPadding>
                <Dashboard_Notifications
                  quests={quests}
                  notifications={notifications}
                  notificationsSetViewHistory={() => setView('viewHistory')}
                  notificationsSetViewReccomendations={() => setView('reccomendations')}
                  notificationsSetSelectedQuest={(quest) => setSelectedQuest(quest)}
                  notificationsMarkRead={(notification) => mark_notification_read(notification)}
                />
              </PageDesktopPadding>
            </PageDesktopOnly>
          :
            null
          }
        </Dashboard>
      </ViewContext.Provider>
    </SidebarContext.Provider>
  );
}

