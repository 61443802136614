import { AlertContext, Alert_, Builder_, Dropdown_, FormList, Grey_, IconSvg, Input_, Main_Text_Loader, Partial_Loader, Red_, Steps_Actions } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { StepContext } from '../../context/StepContext';
import serviceJobs from '../../data/serviceJobs.json'
import { push_new_answer, renderStepDescription } from '../../pages/questionnaireB/utils';
import store from '../../redux/store';
import { StepsTitle, StepsTitleInfo } from '../../styles/Layout';
import { useDebounce } from 'use-debounce';
import m from '../../services/api/Meta';
import { alertMessage } from '../../utils/messagesAlerts';
import {Carousel_} from './Carousel_';
import Interest_Tabs from './Interest_Tabs';

const ClientInterests = styled.div``



const CategoriesWrapper = styled.div`
  display: flex; 
  align-items: center;
 // flex-wrap: wrap;
  gap:1rem;
  padding: 1rem 0;


`

const CategoryLabel = styled.div`

  padding: var(--normalPads);
  border-radius: var(--normalRadius);
  background: ${props => (props.active ? 'var(--grey)' : 'transparent')};
  cursor: pointer;
  &:hover {
    background: ${props => (props.active ? 'var(--greyDark)' : 'var(--grey)')};
  }

`

const ClientInterestsResults = styled.div`

`

const InterestSuggestions = styled.ul`
  background: ${(props) => props.theme.background};
  box-shadow: ${(props) => props.theme.out};
  border-radius: var(--smallRadius);
  overflow: hidden;
  transition: all 0.5s ease;
  margin-bottom: 1rem;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    padding: var(--normalPads);
    transition: background 0.5s;

    &:hover {
      background: ${(props) => props.theme.low};
    }
  }

  .interestSelected {
    background: ${(props) => props.theme.low};
  }
`;


const InterestList = styled(FormList)`
  width: 100%;
  margin: 0;
  padding-top: 0.5rem;
  h5 {
    padding: 0 .5rem;
    color: var(--greyDark);
    font-size: 1.4rem;
  }

  li{
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;

    main{
      display: flex;
      gap: .5rem;
      align-items: center;

      svg{
        width: 1rem;
      }
    }
  }
`;

const EshopConfigurePixelHelper = styled.div`
    border: 3px dotted ${p => p.theme.background};
    background: ${p => p.theme.mid};
    padding: var(--normalPads);
    border-radius: 1rem;

    h5{
        color: var(--greyDark);
        margin-bottom: 1rem;
    }

    svg{
        width: 2rem;
        margin-bottom: 5px;
        fill: var(--warning);
    }
`

const CarouselNavButton = styled.button`
  padding-top: 0.5rem;
  border-radius: var(--normalRadius);
  border: none;

  cursor: pointer;
  &:hover {

  }

  &:disabled {
    display: none;
  }

  svg{
    width: 1.2rem;
    
  }
`;


export default function Client_Interests(props) {

    const { step, setStep } = useContext(StepContext);
    const { t } = useTranslation();
    const { alerts, setAlerts } = useContext(AlertContext);
    const [interestQuery, setInterestQuery] = useState('');
    const [interestQueryD] = useDebounce(interestQuery, 1000);

    const [loading, setLoading] = useState(false);
    const [showResults, toggleShowResults] = useState(false);
    const [selectedInterests, setSelectedInterests] = useState([]);
    const user = store.getState()?.user?.user;
    const isKnight = user?.roles?.includes('Knight');
 
    const [groupedInterests, setGroupedInterests] = useState({});
    const [selectedSource, setSelectedSource] = useState('');


    const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
        question: (t(`${props?.Q2?.question}`)),
        type: `${props?.Q2?.type}`,
        answer: props?.Q2?.answer || null
      })

    const [errors, setErrors] = useState([]);
    const [highlighted, setHighlighted] = useState(0);


    console.log('stepppp', stepInfo.answer);

    const handleAddToList = (interest) => {
      console.log('inter', interest);
      setInterestQuery('');
      setStepInfo((prevStepInfo) => ({
        ...prevStepInfo,
        answer: [
            ...prevStepInfo.answer,
            { id: interest?.id, name: interest?.name, source: interest?.source }
        ],
    }));
      toggleShowResults(false)
     // setSelectedInterests([...selectedInterests, interest]);
  };
  
    const handleDeleteItem = (index) => {
        setStepInfo((prevStepInfo) => {
          const newAnswer = { ...prevStepInfo };
          newAnswer.answer.splice(index, 1);
          return newAnswer;
        });
      };

    const get_interests = () => {
    
      m.getSearchInterests([interestQueryD])
        .then( res => {
          setLoading(false)
          console.log('res', res?.data?.matchingKeywords)
          if (res?.data?.matchingKeywords?.length) {

           const grouped = groupBySource(res?.data?.matchingKeywords)
           setGroupedInterests(grouped);
          if (!selectedSource) setSelectedSource(Object.keys(grouped)[0]); 
          }
        })
    }



    const groupBySource = (data) => {
      return data.reduce((acc, item) => {
        (acc[item.source] = acc[item.source] || []).push(item);
        return acc;
      }, {});
    };


      const nextStep = () => {
      //  if (interests?.length && !stepInfo?.answer?.length) {
      //   alertMessage(alerts, setAlerts, (t('Please select at least one interest from the dropdown list')), 'Error');
      //  }
      //  else if (stepInfo.answer.length === 0) { // the correct 
      //   alertMessage(alerts, setAlerts, (t('We could not find any relevant results. Try something different.')), 'Error');
      // } 
      // else
      console.log('stepnextSTep', step, stepInfo);
       
       setStep({step: step?.step + 1, answers: push_new_answer(step, stepInfo)})
      }

  


      //for fb review 
      useEffect(() => {
        if (isKnight && !stepInfo.answer.length) {
            // Assuming 'interest' is defined somewhere in the component
            setStepInfo((prevStepInfo) => ({
                ...prevStepInfo,
                answer: [
                    ...prevStepInfo.answer,
                    { id: '6003317870288', name:'Tennis ball' },
                    { id: '6003160002408', name:'Team sport' }
                ],
            }));
        }
    }, []); // Include dependencies in the dependency array
    

  

      useEffect(() => {
        if (interestQueryD?.length) 
          get_interests(interestQueryD);
        else {
          setGroupedInterests({});
          setSelectedSource('');
        }
      }, [interestQueryD]);

  

      useEffect(()=>{
        toggleShowResults(true);
        // if (interests?.length) setErrors([])
          if (Object.keys(groupedInterests).length) setErrors([]);
      },[Object.keys(groupedInterests).length])
    
      useEffect(() => {
        if (interestQuery?.length) setLoading(true)
      },[interestQuery])

      //console.log('intersts', interests);
      console.log('groupedInterests', groupedInterests, selectedSource);

      console.log('step', step);
      
  return (
    <ClientInterests>
      <StepsTitle>
        <h3>{t('Provide some interests for your clients')}</h3>
        {renderStepDescription(step?.step, t)}
        
      </StepsTitle>
        <Builder_ title={t('Fill in the interests of your target group')}>
            <Input_
                value={interestQuery}
                onChange = {(e)=>setInterestQuery(e.target.value)}
                placeholder={t("Interest key word")}
               // tips = {[(t('e.g. Tennis'))]}
                errors = {errors}
            />
          {loading && <Partial_Loader /> }
            {showResults && (
              <ClientInterestsResults>
                <Carousel_ refreshWidth={Object.keys(groupedInterests)}>
                  <Interest_Tabs 
                    categories={Object.keys(groupedInterests)}
                    onCategory={(category) => setSelectedSource(category)}
                    categorySelected={selectedSource}
                  />
                </Carousel_>
                { groupedInterests[selectedSource]?.length ? 
                    <InterestSuggestions className="ElasticSuggestions">
                      {selectedSource && groupedInterests[selectedSource]?.map((interest, i) => (
                        <li
                          className={i === (highlighted % groupedInterests[selectedSource]?.length) - 1 ? "interestSelected" : ""}
                          key={i}
                          onClick={() => handleAddToList(interest)}
                        >
                          <p>{interest?.name}</p>
                          <p>{interest?.source}</p>
                        </li>
                      ))}
                    </InterestSuggestions>
                :null}

                  </ClientInterestsResults>
                )} 
            {stepInfo.answer?.length ?
              <InterestList>
                <h5>{t('Selected interests')}</h5>
                {stepInfo.answer?.map((selection, i) => (
                  <li key={i}>
                    <main>
                      <IconSvg Icon="Bullet" />
                      <h6>{selection?.name}</h6>
                    </main>
                    <Red_
                      noPadding
                      size="small"
                      iconLeft="Delete"
                      onClick={() => handleDeleteItem(i)}
                    />
                  </li>
                ))}
              </InterestList>
            : null }
            </Builder_>
            <EshopConfigurePixelHelper>
                <IconSvg Icon="Tip" />
                <h5>{t("This step is optional.")}</h5>
                <h5>{t("Skip it if you are not sure of what to input as it may hurt your advertisement.")}</h5>
            </EshopConfigurePixelHelper>
            <Steps_Actions
                icon_Grey="Back"
                icon_Main="Next"
                onClick_Grey={() => setStep({...step, step: step?.step - 1})}
                onClick_Main={()=>nextStep()}
                text_Grey={t("Back")}
                text_Main={t("Next")}
            />
    </ClientInterests>
  )
}
