import { Builder_, Dates_, Grey_, Grey_Link, Slider_ } from 'monica-alexandria'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DashboardLeadsDate = styled.div`
  .Slider {
    z-index: 10000;
  }
`
export default function Dashboard_Leads_Date(props) {
  const {t} = useTranslation()

  const onChange = (selections) => {
    // props.onDates(selections)
    props.setDateFilters({...props.dateFilters, startDate: selections[0],endDate: selections[1], renderedDates: selections})
}

  return (
    <DashboardLeadsDate>
    <Slider_
        text={t("Export")}
        onClose={()=>props.setLeadDates(false)}
        title={t("Select dates")}
        onClick={()=>props.get_all_leads()}
    >
      <Builder_ title={t('Select date range or leave empty for all time leads.')}>
        <Dates_
            allowPartialRange={true}
            onChange={onChange} 
            value={props.dateFilters?.renderedDates} 
            selectRange={true} 
            dateFullCellRender={(date) => date?.date()} 
            maxDate={new Date()}
        />
        <Grey_Link text={t('Clear dates')} iconLeft='Xicon' onClick={()=>    props.setDateFilters({...props.dateFilters, startDate: '',endDate: '', renderedDates: ''})}/>
      </Builder_>
    </Slider_>
    </DashboardLeadsDate>
  )
}
