import { AlertContext, Alert_, Grey_Link, Main_Loader, PageAlwaysVisible, PageForty, PageSubtitle, PageTitle, Steps_, PageDesktopOnly, PageIllu} from 'monica-alexandria'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { StepContext } from "../../context/StepContext";
import styled from 'styled-components';
import store from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../localization/i18next'
import q from '../../services/api/Quests'
import Client_Location from '../../components/questionnaireB/Client_Location';
import Client_Interests from '../../components/questionnaireB/Client_Interests';
import Client_Age_Gender from '../../components/questionnaireB/Client_Age_Gender';
import Monthly_Budget from '../../components/questionnaireB/Monthly_Budget';
import questionnaireSvg from '../../library/imgs/questionnaire.svg'
import { Client_Location_Map } from '../../components/questionnaireB/Client_Location_Map';
import { LocationsContext } from '../../context/LocationsContext';
import { push_new_answer } from '../questionnaireB/utils';

const lang = i18n.language ? i18n.language : 'el';

const QuestionnaireDescription = styled.div`
  padding: 2rem;
`

const SelectedPage = styled.div`
  
`
export default function EDIT_QUESTIONNAIRE_B() {
    const [step, setStep] = useState({step: 0, answers: []});
    const { t } = useTranslation();
    const { alerts, setAlerts } = useContext(AlertContext);
    const [questionnaireB, setQuestionnaireB] = useState([]);
    const [questionnaireA, setQuestionnaireA] = useState([]);
    const [loading, setLoading] = useState(true);
    const [quest, setQuest] = useState(false);
    const navigate = useNavigate();
    const {questId} = useParams();
    const {locationsCtx, setLocationsCtx} = useContext(LocationsContext);

    const [mapCenter, setMapCenter] = useState([39.16227, 23.49089]);

    const facebook_page = store.getState().metaSource?.page_selection_info?.name;

    const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
      question: (t(`${questionnaireB[0]?.question}`)),
      type: `${questionnaireB[0]?.type}`,
      answer: questionnaireB[0]?.answer || []
    })

    
    const check_if_draft_exists = () =>{
      q.getMyQuest(questId)
      .then(res => { 
        console.log('draft',res?.data)
        if(res?.data?.quest) {
          setQuestionnaireB(res?.data?.quest?.questionnaires.slice(2))
          setQuestionnaireA(res?.data?.quest?.questionnaires.slice(0,2))
          console.log('draft questionnaire',res?.data?.quest.questionnaires)
          setQuest(res?.data?.quest)
          setLoading(false)
        }     
      })
      .catch(err => console.log(err))
    }

      const skipInterests= ()=>{
          const stepData ={    question: (t(`${questionnaireB[1]?.question}`)),
          type: `${questionnaireB[1]?.type}`,
          answer: null}

          const stepInterests =  {
            step: 1,
            answers: []
          }
          setStep({step: step?.step + 2, answers: push_new_answer(stepInterests, stepData)})
        }

    useEffect(() => {
      
      // TODO: not totally correct for the future
      // const questionnaireA = store.getState().answers;
      // console.log('questionnaireA', questionnaireA);
      
    //  if (!questionnaireA) navigate(`/${lang}/questionnaire/alpha/`) else 
    
      check_if_draft_exists();
    }, [])

    useEffect(() =>{
      if(questionnaireB?.length < 0) setLoading(true)
    }, [questionnaireB])

    console.log('questionnaireABB', questionnaireA, questionnaireB);

    console.log('stepInf', stepInfo);
  if(loading) return  <Main_Loader />
  else return (
    <PageForty>
        <Alert_
            messages={alerts}
            duration={6000}
        />
      <PageAlwaysVisible>
        <PageTitle>
          <Grey_Link iconLeft="Back" noPadding onClick={()=>navigate(`/${lang}/dashboard`)} />
          <div>
            <h1>{t("Edit the questionnaire below")}</h1>
            <p>{facebook_page}</p>
          </div>
        </PageTitle>
        <StepContext.Provider value={{ step, setStep }}>
            <Steps_
              currentStep={step?.step}
              steps={[
                <Client_Location Q1 = {questionnaireB[0]} stepInfo={stepInfo} setStepInfo={setStepInfo} mapCenter={mapCenter} setMapCenter={setMapCenter} specialCategory={questionnaireA[1]?.specialAnswer} skipInterests={skipInterests}/>,
                <Client_Interests Q2 = {questionnaireB[1]}/> ,
                <Client_Age_Gender Q3 = {questionnaireB[2]} specialCategory={questionnaireA[1]?.specialAnswer} />,
                <Monthly_Budget Q4 = {questionnaireB[3]} questionnairea={questionnaireA}  questId={quest._id} draftQuest={quest?.draft} editQB={true} setLoading={(status) => setLoading(status)}/>,
              ]}
            />
        </StepContext.Provider>
      </PageAlwaysVisible>
      <PageDesktopOnly>
        {step.step === 0 ?
          <Client_Location_Map locations={locationsCtx} mapCenter={mapCenter} setMapCenter={setMapCenter}/>
        :<PageIllu>
          <img src={questionnaireSvg} />
        </PageIllu>}
      </PageDesktopOnly>
    </PageForty>
  )
}
