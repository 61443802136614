import { AlertContext, Alert_, Grey_, IconSvg, Main_Plain, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, PageTitle, Steps_ } from 'monica-alexandria'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { StepContext } from "../../context/StepContext";
import Business_Type from './components/Business_Type';
import Business_Field from './components/Business_Field';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store';
import styled from 'styled-components';
import { AuthContext } from '../../services/authentication/Guard';
import { Ad_Templates } from '../../components/ad/Ad_Templates';
import q from '../../services/api/Quests';
import questionnaireSvg from '../../library/imgs/questionnaire.svg'
import { Helmet } from 'react-helmet';
import { PocketAgencyContext } from "../../context/PocketAgencyContext";
import { Video_Helper } from '../../components/videoHelper/Video_Helper';

const QuestionnaireActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: .5rem;
  padding-top: 2rem;
`


export default function QUESTIONNAIRE_A(props) {
  
    const [step, setStep] = useState({step: 0, answers: []});
    const { t } = useTranslation();
    const { alerts, setAlerts } = useContext(AlertContext);
    const navigate = useNavigate();
    const {lang} = useParams();
    const auth = useContext(AuthContext);
    const {pocketAgencyContext, setPocketAgencyContext} = useContext(PocketAgencyContext)

    console.log('auth', auth.authenticated)

    const owns_pocket_agency = () => {
      q.ownsPocketAgency().then(res => setPocketAgencyContext(res?.data?.code))
    }

    useEffect(() => {
      if (auth.authenticated) owns_pocket_agency();
    }, [auth?.authenticated])

    console.error(pocketAgencyContext)

  return (
    <PageForty>
      <Helmet>
        <title>Doitforme | Questionnaire: Business</title>
      </Helmet>
      <Alert_
        messages={alerts}
        duration={3000}
      />
      <PageAlwaysVisible>
        <PageTitle>
            <h1>{t("Run Ads on Facebook & Instagram")}</h1>
        </PageTitle>
      
        <StepContext.Provider value={{ step, setStep }}>
            <Steps_
              currentStep={step?.step}
              steps={[
                  <Business_Type/>,
                  <Business_Field/>
              ]}
            />
        </StepContext.Provider>
        {!auth.authenticated ? 
          <QuestionnaireActions>
            <h5>{t('Already a Questad subscriber?')}</h5><Main_Plain text={t('Login')} onClick={() => auth?.login(false, window.location.origin + `/${lang}/dashboard`)} noPadding/>
          </QuestionnaireActions>
        : null}
      </PageAlwaysVisible>
      <PageDesktopOnly>
        <PageIllu>
          <img src={questionnaireSvg} />
        </PageIllu>
      </PageDesktopOnly>
    </PageForty>
  )
}
