import React from 'react'
import styled from 'styled-components'
import Edit_Ad from './Edit_Ad'
import Edit_Landing from './Edit_Landing'
import Edit_Questionnaire from './Edit_Questionnaire'
import { Main_, Red_, device } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'

const DashboardEdit = styled.div``

const DashboardEditGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;

  @media ${device.xl} {
    grid-template-columns: 1fr;
  }
`

export default function Dashboard_Edit(props) {

  const {t} = useTranslation();
  console.log('props.landingCustom' , props.landingCustom);
  return (
    <DashboardEdit>
      <DashboardEditGrid>
        <Edit_Ad 
          adAvatar={props.adAvatar}
          adPage={props.adPage}
          adCaption={props.adCaption}
          adImg={props.adImg}
          adTitle={props.adTitle}
          adUrl={props.adUrl}
          adCta={props.adCta}
          onAdView={props.onAdView}
          onAdEdit={props.onAdEdit}
        />
        {props.type ===  'services' ?
          <Edit_Landing 
            landingCustom={props.landingCustom}
            landingImg={props.landingImg}
            landingHero={props.landingHero}
            landingSub={props.landingSub}
            landingFields={props.landingFields}
            onLandingView={props.onLandingView}
            hasLandingEdit={props.hasLandingEdit}
            onLandingEdit={props.onLandingEdit}
            page={props.page}
            pagePicture={props.pagePicture}
          />
        :
          null
        }
        <Edit_Questionnaire 
          questLocations={props.questLocations}
          questInterests={props.questInterests}
          questMinAge={props.questMinAge}
          questMaxAge={props.questMaxAge}
          questGender={props.questGender}
          questSpecialCategory={props.questSpecialCategory}
          onQuestEdit={props.onQuestEdit}
          type={props.type}

        />
      </DashboardEditGrid>
    </DashboardEdit>
  )
}
