import Cookies from 'js-cookie';
import store from '../redux/store';
import { get_preferences, save_preferences } from '../services/api/Users';
import i18n from 'i18next';


export const get_language_from_url = () => {

    const currentURL = window.location.href;

    let lang = false;

    const supportedLanguages = ['en', 'el', 'de']; // Add more if needed

    const urlSegments = currentURL.split('/');

    for (const segment of urlSegments) {
        if (supportedLanguages.includes(segment)) {
            console.log("Language from url: ", segment);
            lang = segment;
            return segment;
        }
    }

    return lang;
}

export const get_init_menu_options = async (user) => {

    let userId = user?.userId ? user.userId : false;
    let langUrl = get_language_from_url();

    if ( userId ) {
       
        // var { theme, lang, region } = await get_preferences(userId)
        var theme = store.getState().user?.user?.preferences?.theme;
        var lang = store.getState().user?.user?.preferences?.language;
        var region = store.getState().user?.user?.preferences?.region;

        console.log('FETCHED FROM LOCAL STORAGE', theme, lang, region);
        
        if ( !theme || !lang || !region ) {
            let options = fetch_options_from_browser()
            theme = options?.theme;
            lang = options?.lang;
            region = options?.region;
        }
    }
    else {
        let options = fetch_options_from_browser()
        theme = options?.theme;
        lang = langUrl ? langUrl: options?.lang;
        region = options?.region;
    }

    if (langUrl && i18n?.services?.languageUtils) i18n.changeLanguage(lang);
    
    save_options_cookie(theme, lang, region)

    console.log('FINAL PREFERENCES', theme, lang, region);

    return {
        init_theme_value: theme,
        init_lang_value: lang,
        init_region_value: region
    }

}


const fetch_options_from_browser = () => {

    // Default values
    let theme = 'light'
    let lang = 'el'
    let region = 'Greece'

    // Try to find options cookie
    let cookie_options = Cookies.get("menu_options")
    if ( cookie_options ) cookie_options = JSON.parse(cookie_options)

    if ( 
        cookie_options && 
        cookie_options?.theme &&
        cookie_options?.lang &&
        cookie_options?.region 
    ) {
        console.log('RETURNING FROM COOKIE > ', cookie_options);
        return {
            theme: cookie_options.theme,
            lang: cookie_options.lang,
            region: cookie_options.region
        }
    }

    //  Try to fetch user preferences
    let userLanguage = match_system_language(window.navigator.language);
    
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    if ( userLanguage ) {

        theme = prefersDarkMode ? 'dark' : 'light'
        lang = userLanguage 
        // region = region
        
        console.log('RETURNING FROM USER PREFERENCES > ',theme, lang, region);
        return {
            theme: theme,
            lang: lang,
            region: region
        }
    }
    
    // Return defaults
    console.log('RETURNING defaults > ',theme, lang, region);
    return {
        theme: theme,
        lang: lang,
        region: region
    }

}


const save_options_cookie = (theme, lang, region) => {

    const options = {
        theme: theme,
        lang: lang,
        region: region
    }
    
    Cookies.set("menu_options", JSON.stringify(options), { expires: 7 }); // 7 days
    
    store.dispatch({type: "THEME_UPDATE", payload: theme})
    store.dispatch({type: "LANGUAGE_UPDATE", payload: lang})
    store.dispatch({type: "COUNTRY_UPDATE", payload: region})
    
    return;
}


export const update_options_cookie = (userId) => {

    const theme = store.getState()?.theme;
    const lang = store.getState()?.selectedLanguage;
    const region = store.getState()?.country;

    if ( !theme || !lang || !region ) return;

    const options = {
        theme: theme,
        lang: lang,
        region: region
    }
    
    Cookies.set("menu_options", JSON.stringify(options), { expires: 7 }); // 7 days
    
    store.dispatch({type: "THEME_UPDATE", payload: theme})
    store.dispatch({type: "LANGUAGE_UPDATE", payload: lang})
    store.dispatch({type: "COUNTRY_UPDATE", payload: region})

    const user = store.getState().user;
    if ( userId && user?.user?.preferences ) {
        save_preferences(userId);
        
        user.user.preferences.theme = theme
        user.user.preferences.language = lang
        user.user.preferences.region = region
        
        store.dispatch({type: 'KEYCLOAK', payload: user})
    }

    return;
}


const match_system_language = (countryCode) => {
    
    const defaultLang = 'el';
    const supportedLangs = [
        'el',
        'en',
        'de'
    ];

    if ( countryCode && countryCode.includes('-') ) countryCode = countryCode.split('-')[0];

    if ( supportedLangs.includes(countryCode) ) return countryCode;

    return defaultLang;
}


export const init = async (user, setTheme, setLanguage, setRegion) => {


    const {
      init_theme_value,
      init_lang_value,
      init_region_value
    } = await get_init_menu_options(user);

    console.log('BEFORE INIT OPTIONS', user)
    console.log("INIT MENU OPTIONS", init_theme_value, init_lang_value, init_region_value, window.location.href)

    setTheme(init_theme_value);
    setLanguage(init_lang_value);
    setRegion(init_region_value);
};

export const reconstructUrl = (language) => {
    let path = window.location.pathname.split('/');
    let params = window.location.search;
    path[1] = language;
    let newPath = path.join('/') + '/';
    if ( !newPath.includes('//') ) return `${newPath}${params}`;
    else return false;
}

export const addTawkScript = (language) => {

    let widgetSrc = '';

    if (language == 'el') {
        widgetSrc = 'https://embed.tawk.to/672cc3624304e3196adec674/1ic3dmemg';
    }
    else {
        widgetSrc = 'https://embed.tawk.to/672cc3624304e3196adec674/1icitdsbr';
    }
    // Remove any existing Tawk.to script
    const existingScript = document.querySelector('script[src*="tawk.to"]');
    if (existingScript) {
      existingScript.remove();
    }

    // Create a new script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='${widgetSrc}';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();
    `;

    // Append the script to the body
    document.body.appendChild(script);
  };

  export const addConsentV2Script = (language) => {
    return new Promise((resolve, reject) => {

      const minioUrl = process.env.REACT_APP_MINIO_PROTOCOL + process.env.REACT_APP_MINIO_HOST + process.env.REACT_APP_MINIO_PORT;
      
      // fallback
      if (language !== 'en' && language !== 'en') {
        language = 'el';
      }

      // environment
      let environmentPrefix;
      if (process.env.REACT_APP_SERVER == 'LOCAL') environmentPrefix = '';
      else if (process.env.REACT_APP_SERVER == 'STAGING') environmentPrefix = '-s';
      else environmentPrefix = '-p';
  
      // Check if script already exists
      const existingScript = document.querySelector(`script[src="${minioUrl}/mongol/css/cookieConsent/cookie-consent-${language}${environmentPrefix}.js"]`);
      if (existingScript) {
        resolve();
        return;
      }
  
      const script = document.createElement('script');
      script.src = `${minioUrl}/mongol/css/cookieConsent/cookie-consent-${language}${environmentPrefix}.js`;
      script.async = true;
      
      script.onload = () => resolve();
      script.onerror = () => {
        console.error(`Failed to load cookie consent script for ${language}`);
        resolve();
        return;
      }
      
      document.head.appendChild(script);
    });
  };