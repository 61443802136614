export const validateURL = (url, t) => {
    let helpers = [];
    let successes = [];

    // Regular expression to match a valid URL format
    const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

    if (url !== '') {
        if (!urlRegex.test(url)) {
            helpers.push(t("Correct URL format"));
        } else {
            successes.push("URL is valid.");
        }
    }

    return {helpers, successes};
}