import React, { useContext, useEffect, useState } from "react";
import { useDebounce } from 'use-debounce';
import { StepContext } from "../../context/StepContext";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  AlertContext,
  Builder_,
  Radio_,
  Input_,
  Red_,
  FormList,
  Steps_Actions,
  Partial_Loader,
  IconSvg,
  Grey_,
  Toggle_,
} from "monica-alexandria";
import styled from "styled-components";
import { push_new_answer, renderStepDescription } from "../../pages/questionnaireB/utils";
import { Google_Map_Search } from "../../library/form/Google_Map_Search";
import store from "../../redux/store";
import { StepsTitle } from "../../styles/Layout";
import { alertMessage } from "../../utils/messagesAlerts";
import m from '../../services/api/Meta';
import { Range_ } from "../../library/range/Range_";
import { Client_Location_Map } from "./Client_Location_Map";
import { LocationsContext } from "../../context/LocationsContext";

const ClientLocations = styled.div``


const LocationSuggestions = styled.ul`
  margin-top: 1rem;
  background: ${(props) => props.theme.background};
  box-shadow: ${(props) => props.theme.out};
  border-radius: var(--smallRadius);
  overflow: hidden;
  transition: all 0.5s ease;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    padding: var(--normalPads);
    transition: background 0.5s;

    &:hover {
      background: ${(props) => props.theme.low};
    }
  }

  .locationSelected {
    background: ${(props) => props.theme.low};
  }
`;


const LocationList = styled(FormList)`
  width: 100%;
  margin: 0;
  padding-bottom: 2rem;
  h5 {
    padding: 0 .5rem;
    color: var(--greyDark);
    font-size: 1.4rem;
  }

  li{
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    gap: 0.5rem;
    border: 1px solid var(--greyDark);
    border-radius: var(--smallRadius);
    margin-top: 1rem;


    main{
      display: flex;
      align-items: center;
    }
  }
`;

const LocationListItem = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 0.5rem;
  cursor: pointer;
`

const LocationListItemToggle = styled.div`
  display: flex;
  gap: .5rem;
  cursor: pointer;
`

const LocationListRadius = styled.div`
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 3;
`

const LocationListRadiusSet = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: .5rem;

  h4{
    grid-column-start: 2;
    grid-column-end: 3;
    margin-top: .5rem;
  }
`

export default function Client_Location({Q1,mapCenter, setMapCenter,business_id, ad_account_id, questId,skipInterests, specialCategory}) {

  const { step, setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const { alerts, setAlerts } = useContext(AlertContext);
  const [locationQuery, setLocationQuery] = useState('');
  const [locationQueryD] = useDebounce(locationQuery, 1000);
  const navigate = useNavigate();
  const {lang} = useParams();
  const {locationsCtx, setLocationsCtx} = useContext(LocationsContext);

  const [loading, setLoading] = useState(false);

  const [showResults, toggleShowResults] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const user = store.getState()?.user?.user;
  const isKnight = user?.roles?.includes('Knight');
  const [activeRadiuses, setActiveRadiuses] = useState([])

  const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
      question: (t(`${Q1?.question}`)),
      type: `${Q1?.type}`,
      answer: Q1?.answer || null
    })

  const [locations, setLocations] = useState([]);
  const [errors, setErrors] = useState([]);
  const [highlighted, setHighlighted] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  console.log('stepppp', stepInfo.answer);

  const handleAddToList = (location) => {
    console.log('locat', location);
    let tempStepInfo = JSON.parse(JSON.stringify(stepInfo));
    let newAnswers = tempStepInfo?.answer;
    newAnswers.push({ key: location?.key, name: location?.name, distance_unit: 'kilometer', country_code: location?.country_code, country_name: location?.country_name, type: location?.type ,longitude: location?.longitude, latitude: location?.latitude})
    tempStepInfo.answer = newAnswers;
    setLocationQuery('');
    setStepInfo(tempStepInfo);
    toggleShowResults(false)
};

  const handleDeleteItem = (index) => {
   // props.setLocations(prevLocations => [...prevLocations, locations]);
      setStepInfo((prevStepInfo) => {
        const newAnswer = { ...prevStepInfo };
        newAnswer.answer.splice(index, 1);
        return newAnswer;
      });
    };

  const get_locations = () => {
    m.getSearchLocations([locationQueryD], specialCategory)
      .then( res => {
        setLoading(false)
        console.log('res', res?.data?.matchingLocations)
        if (res?.data?.matchingLocations?.length) {
          setLocations(res?.data?.matchingLocations);
        }
      })
  }

  const get_missing_lat_or_lng_from_locations = () => {
      m.getCoordinatesForLocations(Q1?.answer)
        .then( res => {
          setLoading(false)
          console.log('res res res', res?.data?.data?.matchingLocations)
          if (res?.data?.data?.matchingLocations?.length) {
            setStepInfo({...stepInfo, answer: res?.data?.data?.matchingLocations});
          }
        })
  }
  

    const nextStep = () => {
     if (locations?.length && !stepInfo?.answer?.length) {
      alertMessage(alerts, setAlerts, (t('Please select at least one location from the dropdown list')), 'Error');
     }
     else if (stepInfo.answer.length === 0) { // the correct 
      alertMessage(alerts, setAlerts, (t('We could not find any relevant locations. Try something different.')), 'Error');
    } 
    else {
      // skip interests
      // if (specialCategory){
      //   setStep({step: step?.step + 2, answers: skipInterests(step, stepInfo) })
         
      // }
      // else 
      setStep({step: specialCategory ? step?.step + 2 : step?.step + 1, answers: push_new_answer(step, stepInfo, specialCategory, t)})
      
    }
    }



    const handleRadius = (selection, action) => {
      // Find the index of the selection in the answer array
      const selectionIndex = stepInfo.answer.findIndex(item => item.key === selection.key);
      
      // Create a new copy of active radiuses
      let newActiveRadiuses = [...activeRadiuses];
      
      if (selectionIndex !== -1) {
        // If selection is found in the answer array, update its radius
        let newAnswer = [...stepInfo.answer];
  
        if (newAnswer[selectionIndex].radius) {
          delete newAnswer[selectionIndex].radius;
          newActiveRadiuses = newActiveRadiuses.filter(key => key !== selection.key);
        }
        else {
          newAnswer[selectionIndex] = { ...newAnswer[selectionIndex], radius: 2};
          newActiveRadiuses.push(selection.key);
        }
        // Update stepInfo with the modified answer
        setStepInfo(prevState => ({
          ...prevState,
          answer: newAnswer
        }));
      }
      // Update active radiuses
      setActiveRadiuses(newActiveRadiuses);
    };

    const handleBackNav = () =>{
      if(business_id && ad_account_id) navigate(`/${lang}/ad-accounts`)
      else if (questId) navigate(`/${lang}/dashboard`);
      else navigate(`/${lang}/choose-facebook-page`);
  }
  
    //for fb review 
  //   useEffect(() => {
  //     if (isKnight && !stepInfo.answer.length) {
  //         // Assuming 'location' is defined somewhere in the component
  //         setStepInfo((prevStepInfo) => ({
  //             ...prevStepInfo,
  //             answer: [
  //                 ...prevStepInfo.answer,
  //                 {country_code: 'IS', country_name: 'Iceland', key: 'IS', type: 'country', name: 'Iceland'},
  //                 {country_code: 'GR', country_name: 'Greece', key: 'GR', type: 'country', name: 'Greece'}
  //             ],
  //         }));
  //     }
  // }, []); 

    useEffect(() => {
      if (locationQueryD?.length) get_locations(locationQueryD);
      else setLocations([])
    }, [locationQueryD]);
     

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);

      get_missing_lat_or_lng_from_locations();

  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(()=>{
      toggleShowResults(true);
      if (locations?.length) setErrors([])
    },[locations])

    useEffect(()=>{
      setLocationsCtx(stepInfo?.answer);
    },[stepInfo?.answer])

    useEffect(() => {
      if (locationQueryD?.length) setLoading(true)
    },[locationQueryD])

    console.log('aaaaaaaaaa (old locations)', Q1)
    console.log('aaaaaaaaaa (new locations)', locations)
    console.log('aaaaaaaaaa (CTX)', locationsCtx)
    console.log('mapCenter', mapCenter)
    console.log('answers', locationQueryD)
    console.log('stepLocations', step, stepInfo)

return (
  <ClientLocations>
    <StepsTitle>
      <h3>{t('Where do you want to find clients?')}</h3>
      {renderStepDescription(step?.step, t)}
    </StepsTitle>
      <Builder_ title={t('Fill in the locations of your target group')}>
          <Input_
              value={locationQuery}
              onChange = {(e)=>setLocationQuery(e.target.value)}
              placeholder={t("Location")}
             // tips = {[(t('e.g. Iceland'))]}
              errors = {errors}
          />
          {loading && <Partial_Loader /> }
          {showResults &&
            <LocationSuggestions className="ElasticSuggestions">
              {locations?.map((location,i) =>  (
                  <li
                      className={
                        i === (highlighted % locations?.length ) -1
                          ? "locationSelected"
                          : ""
                      }
                    key={i}
                    onClick={() => handleAddToList(location)}
                    >
                  <p>{location?.name}, {location?.country_name}, {location?.country_code}</p>
                </li>
              ))}
            </LocationSuggestions> 
          }
          <LocationList>
            <h5>{t('Selected locations')}</h5>
            {stepInfo?.answer?.map((selection, i) => (
              <li key={i} onClick={()=>setMapCenter([selection.latitude, selection.longitude])}>
                <LocationListItem>
                  <IconSvg Icon="Location" />
                  <h6>
                    {selection?.name ? selection.name: selection}{selection?.country_name ? ', ' + selection?.country_name: ''}{selection?.country_code ? ', ' + selection?.country_code: ''}</h6>
                </LocationListItem>
                <Red_
                  size="small"
                  iconLeft="Delete"
                  onClick={() => handleDeleteItem(i)}
                  noPadding
                />
                 {!specialCategory && selection?.type !== 'country' && selection?.type !== 'region' && selection?.type !== 'state' ?
                  <LocationListRadius>
                    <LocationListItemToggle onClick={()=> handleRadius(selection)}>
                      <Toggle_ active={activeRadiuses.includes(selection?.key) || selection?.radius} onClick={() => {

                      }}/>
                      <h6>{t("Radius")}</h6>
                    </LocationListItemToggle>
                    {activeRadiuses.includes(selection?.key) || selection?.radius?
                      <LocationListRadiusSet>
                           <Range_ min={2} max={50} step={1} value={selection?.radius} onChange={(value) => setStepInfo(prevState => ({
                            ...prevState,
                            answer: prevState.answer.map((item, index) => index === i ? { ...item, radius: value } : item)
                          }))} />
                          <h4>{selection.radius || selection.radius !== 0 ? selection.radius : 2} {t('km')}</h4>
                      </LocationListRadiusSet>
                    :
                      null
                    }
                  </LocationListRadius> : null}
              </li>
            ))}
 
          </LocationList>
        {windowWidth < 992 ? 
          <Client_Location_Map locations={locationsCtx} mapCenter={mapCenter} setMapCenter={setMapCenter}/> : null}
      </Builder_>
      <Steps_Actions
          icon_Grey="Back"
          icon_Main="Next"
          onClick_Grey={handleBackNav}
          onClick_Main={()=>nextStep()}
          text_Grey={t("Back")}
          text_Main={t("Next")}
      />
  </ClientLocations>
)
}
