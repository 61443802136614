import { Actions_, Alert, AlertContext, Alert_, Builder_, Dropdown_, Grey_, Grey_Link, Input_, Main_, Main_Plain, Page, PageAlwaysVisible, Section_Title, PageDesktopOnly, PageForty, PageTitle, Upload_Media, device, Main_Loader, Partial_Loader, Partial_Text_Loader, Main_Text_Loader, Confirmation_, Form, Slider, IconSvg, Textarea_, PageDesktopPadding, Toggle_Stat, ValidatorError } from 'monica-alexandria'
import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { alertMessage } from '../../utils/messagesAlerts';
import a from '../../services/api/Ads'
import m from '../../services/api/Meta'
import q from '../../services/api/Quests'
import u from '../../services/api/Uploads'
import styled from 'styled-components';
import { load_my_ad, prepare_ad_for_saving_in_DB } from './utils';
import { Preview_Ad_Mockup } from '../../components/ad/Preview_Ad_Mockup';
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { Ad_Templates } from '../../components/ad/Ad_Templates';
import { Helmet } from 'react-helmet';
import Preview_Catalog_Mockup from '../../components/ad/Preview_Catalog_Mockup';
import { ModifyAdToggle } from '../../styles/components/ModifyAd';
import { Create_Ad_Service_Only_Fields } from '../../components/ad/Create_Ad_Service_Only_Fields';
import { Create_Ad_Fields } from '../../components/ad/Create_Ad_Fields';
import { PocketAgencyContext } from '../../context/PocketAgencyContext';
import { Video_Helper } from '../../components/videoHelper/Video_Helper';

const CreateAd = styled.div`
    .unclickable{
        opacity: 0.7;
        pointer-events: none;
    }
    .UploadMediaGrid{
        background: ${p => p.theme.background};
        padding: 1rem;
        border-radius: 1rem;
    }
`

const AdMockupWrapper = styled.div`
    padding-top: 6rem;
    display: flex;
    align-items: center;

    @media ${device.md} {
       padding-top: 0rem;
    }
`

const CatalogMockupWrapper = styled.div`



`

const CreateAdEshopType = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

const CreateAdEshopTypeChoice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-left-radius: ${p => p.activeType ? '0': '2rem'};
    border-bottom-right-radius: ${p => p.activeType ? '0': '2rem'};
    background: ${p => p.theme.low};
    cursor: pointer;
    margin: ${p => p.activeType ? '0rem 0rem' : '.5rem 1.5rem'};
    padding: ${p => p.activeType ? '1rem 2rem': '0rem 0rem'};

    span{
        transition: color .5s;
        font-family: 'fontMedium', sans-serif;
        font-size: 1.8rem;
        color: ${p => p.activeType ? 'var(--main)' : 'var(--greyDark)'};
    }

    svg{
        width: 2rem;
        fill: ${p => p.activeType ? 'var(--main)' : 'var(--greyDark)'};
    }

    &:hover{

        span{
            transition: color .5s;
            /* color: ${p => p.theme.color}; */
            color: var(--mainLight);
        }        
        svg{
            fill: var(--mainLight)
        }
    }
`

const AdForm = styled(Form)`
    border-top-left-radius: ${p => p.activeType === 'productCatalog' ? '0' : p.activeType === 'default' ? '3rem' : '3rem'};
    border-top-right-radius: ${p => p.activeType === 'productCatalog' ? '3rem' : p.activeType === 'default' ? '0' : '3rem' }; 
`


export default function CREATE_AD() {

    const {t} = useTranslation();
    const {alerts, setAlerts} = useContext(AlertContext);
    const {pocketAgencyContext, setPocketAgencyContext} = useContext(PocketAgencyContext)

    const {lang} = useParams();
    const navigate = useNavigate();

    const [adInfo, setAdInfo] = useState({caption:"", title: "",cta:"", media:""})
    const [loading, setLoading] = useState(false);

    // eshop flow
    const [businessType, setBusinessType] = useState(false);
    
    const [productCatalogs, setProductCatalogs] = useState([]);
    const [productSets, setProductSets] = useState([]);
    const [products, setProducts] = useState([])

    const [confirmation, setConfirmation] = useState(false);
    const [adsLoading, setAdsLoading] = useState(true);

    const [showTemplates, setShowTemplates] = useState({id:'', slider:''});

    const [emptyFields, setEmptyFields] = useState([]);
    const [mediaErrors, setMediaErrors] = useState([]);
    const [ads, setAds] = useState([]);

    const [loadingProductSets, setLoadingProductSets] = useState(false)


    const [leadNotif, toggleLeadNotif] = useState(false);

    const [showSalePrice, setShowSalePrice ] = useState(false)

    const user = store.getState()?.user?.user;
    const isKnight = user?.roles?.includes('Knight');

    const [messages, setMessages] = useState({errors: [], helpers: [], successes: [] });

 
    // Searches for previous quests in order to reuse an ad, if possible
    // const check_for_previous_quests = () =>{
    //     q.getMyQuests()
    //     .then(res => {
    //         if (res?.data?.code == 'Q5006') {

    //             let quests = res?.data?.data;

    //             if (quests?.length > 1 && store?.getState()?.reuseAd !== 'DECIDED') {
    //             setConfirmation(true);
    //             }
    //         }
    //     })
    // }

    const sectionData = [
        {
          title: t("Step one"),
          icon: "Back",
          content: [
            <h5>
              {t("Navigate to Facebook Commerce Manager by clicking the following link")}{" "}
              <a href="https://www.facebook.com/commerce_manager" target="_blank" rel="noopener noreferrer">
                {t("Facebook Commerce Manager")}
              </a>.
            </h5>,
          ],
        },
        {
          title: t("Step two"),
          icon: "Back",
          content: [
            <h5>{t("Click on «Create a Catalog» and select the type of products you want to list")}.</h5>,
          ],
        },
        {
          title: t("Step three"),
          icon: "Back",
          content: [
            <h5>
              {t('Choose how you want to add products')}
              : 
              {t('"Upload Product Info" manually or "Connect eCommerce Platform" like Shopify or WooCommerce.')}
            </h5>,
          ],
        },
        {
          title: t("Step four"),
          icon: "Back",
          content: [
            <h5>
              {t("If uploading manually, enter product details such as name, description, price, and image. If connecting an eCommerce platform, follow the provided integration steps.")}
            </h5>,
          ],
        },
        {
          title: t("Step five"),
          icon: "Back",
          content: [
            <h5>
              {t("Once your catalog is created, go to the Facebook Business Manager settings and assign permissions to relevant users for management.")}.
            </h5>,
          ],
        },
        {
          title: t("Step six"),
          icon: "Back",
          content: [
            <h5>
              {t("To use the catalog for ads, go to Ads Manager and create a Dynamic Ad, selecting the newly created catalog as the source.")}
            </h5>,
          ],
        },
      ];
      

    
    const get_my_ad = async(questId) => {
        try {
            await a.getMyAd(questId)
                .then(res => {
                    if (res?.data?.code == "A2002") {
                        setAdInfo((prev) => ({
                            ...prev,
                            ...load_my_ad(res?.data?.ad),
                        }));
                        
                        if (res?.data?.ad?.flow === 'eshop' && res?.data?.ad?.mode==="productCatalog"){
                            get_product_catalogs();
                            get_product_sets(res?.data?.ad?.catalogId, false)
                            // get_products_from_product_set(res?.data?.ad?.productSetId ,'')
                        } 
                    }
            })
        } catch (err) {
            console.log(err);
        }
    }





    // Saving the decision in the cache because we do not want to spam the user with the popup
    const choice_no = () => {
        store.dispatch({type:'REUSE_AD', payload: 'DECIDED'});
        setConfirmation(false);
    }

    // Saving the decision in the cache because we do not want to spam the user with the popup
    const choice_yes = () => {
        store.dispatch({type:'REUSE_AD', payload: 'DECIDED'});
        setConfirmation(false);
        setShowTemplates({...showTemplates, slider:true})
        // navigate(`/${lang}/choose-ad`);
    }

    const check_for_ad = async() => {
        await a.checkForAd()
        .then(res => {
            console.log('CHECK FOR AD', res?.data)
            setBusinessType(res?.data?.field);
            if (res?.data?.ad_exists) get_my_ad(res?.data?.questId)
            else setAdInfo({...adInfo, questId: res?.data?.questId, mode: res?.data?.mode, flow: res?.data?.flow || '', catalogId: res?.data?.catalogId || ''});
        })
        .catch(err => console.log(err))
    }



    const get_my_ads = async() => {
        try {
            await a.getMyAds()
            .then(res => {
                if (res?.data?.code == 'A2006') {
                    console.log('RESADS',res?.data?.ads );
                    handleFilter(adInfo.flow,adInfo?.mode, res?.data?.ads )
                    //setAds(res?.data?.ads);
                }
                else {
                    alertMessage(alerts, setAlerts, (t('Something went wrong')), 'Error');
                }
                setAdsLoading(false);
            })
        } catch (err) {
            console.log(err);
        }
    }

    //template ads 
    const handleFilter = (flow, mode, ads) => {
        const filteredData = ads.filter((item) => {

        if (flow === 'eshop' && mode) {
            return item.flow === flow && item.mode === mode;
        } else if (flow) {
            return item.flow === flow;
        } else {
            return item; // No filter applied, return everything
        }
        })
        
        // if (filteredData?.length > 1 && store?.getState()?.reuseAd !== 'DECIDED') {
        //     setConfirmation(true);
        // }

         setAds(filteredData);
    };




    const validateFields = () => {
        const emptyFields = [];
     
        if (!adInfo.caption) {
            emptyFields.push('Caption');
        }
        if ((!adInfo.title || adInfo?.title === "") && adInfo?.mode !== 'productCatalog') {
            emptyFields.push('Headline');
        }
        if (adInfo.cta === 'none' || !adInfo.cta) { // Check if cta is 'none' or empty
            emptyFields.push('Cta');
        }
        if (adInfo.ctaUrl && messages?.helpers?.length) { // Check if cta is 'none' or empty
            setMessages({successes: [], helpers:[], errors: messages?.helpers})
            setAdInfo({...adInfo, ctaUrl:''})
            emptyFields.push('Cta Url');
        }      
        if (!adInfo.media && !adInfo?.visualElement &&  adInfo?.mode !== 'productCatalog') {
            emptyFields.push('Media');
            setMediaErrors([])
        }
        if (businessType == 'eshop' && adInfo?.mode === 'productCatalog' && (!adInfo?.catalogId ||adInfo?.catalogId ==='') ) {
            emptyFields.push('Catalog');
        }
        if (businessType == 'eshop' && adInfo?.mode === 'productCatalog' && productSets?.length  && !adInfo?.productSetId)  {
            emptyFields.push('ProductSet');
        }
       setEmptyFields(emptyFields)
        return emptyFields;
    };

    //onSave
    const edit_ad = async(isDraft) => {

        let fileData, fileName = false;
    
        let adFinal = prepare_ad_for_saving_in_DB(adInfo, isDraft);
        console.error('adFinal',adFinal);
        setLoading(t('Uploading media and creating your ad. This may take a moment...'));

        console.log("+++---", adInfo?.file)
        if (adInfo?.file && adInfo?.file?.preview) {
            fileData = await u.upload_media(adInfo?.file, setLoading);
            fileName = fileData?.fileName;
        }

        a.editAd(adFinal, fileName, 'draft')
         .then(res => {
             console.error('RESSSSS', res)
             setLoading(false);
             // eshop flow
             if ((businessType == 'eshop' || businessType == 'products offline' || businessType == 'restaurants') && res?.data?.code !== 'C5200') navigate(`/${lang}/preview-ad`)
             else if (res?.data?.code == 'A2001') navigate(`/${lang}/webpage-helper`)
             else alertMessage(alerts, setAlerts, res?.data?.msg,'Error');
        })
    }


    const edit_quest_mode = async (newMode) => {
        const newMetaField = {
            'Meta.mode': newMode,
            questId: adInfo?.questId
        };
    
        try {
            await q.editQuestMode(newMetaField);
            setAdInfo({ ...adInfo, mode: newMode });
    
            if (newMode === 'productCatalog') {
                if (adInfo?.catalogId && adInfo?.productSetId) {
                    await get_product_catalogs();
                    await get_product_sets(adInfo?.catalogId, false);
                    await get_products_from_product_set(adInfo?.productSetId, adInfo?.productSetName);
                } else {
                    await get_product_catalogs();
                }
            }
        } catch (error) {
            console.error('Error updating quest mode:', error);
        }
    };


    const get_product_catalogs = async() => {
        try {
            await m.getProductCatalogs()
            .then(res => {
                console.log('adsfgd', res)
                if (res?.data?.code == 'C4011') {
                    alertMessage(alerts, setAlerts, res?.data?.msg, 'Error')
                }
                else {
                    setProductCatalogs(res?.data?.productCatalogs)
                }
            // get_product_sets()
                
            })
        } catch (err) {
            console.log(err);
        }
    }

    //called on change dropdown
    const save_product_catalog = async(catalogId) =>{
        setAdInfo((prev) => ({
            ...prev,
            catalogId: catalogId,
          }));
        const selectedCatalog = productCatalogs.find(catalog => catalogId === catalog?.catalogId);
        console.log('catalog--',selectedCatalog);
      
       
        
        try {
            await m.saveCatalog(selectedCatalog?.catalogId, selectedCatalog?.catalogName, selectedCatalog?.catalogBusinessId, adInfo?.questId);
        } catch (err) {
            console.log(err);
        }

        get_product_sets(catalogId, true)
      }



      const get_product_sets = async (catalogId, changed) => {
        setLoadingProductSets(true)
        try {
            await m.getProductSets(catalogId)
            .then(res => {
                console.log('adsfgd++++++++', res?.data?.productSets[0])
                setProductSets(res?.data?.productSets)
                setLoadingProductSets(false)

                if(changed) {
                    setAdInfo(prev => ({
                        ...prev,
                        productSetId: res?.data?.productSets[0]?.id || '',
                        productSetName: res?.data?.productSets[0]?.name || '',
                    }));
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    const get_products_from_product_set = async(productSetId, productSetName) => {
        try {
            await q.getProductsFromProductSet(adInfo?.questId, productSetId)
            .then(res => {
                console.log('RESPRODSET', res?.data);
                
                if (!["Q2010", "Q2011"]?.includes(res?.data?.code)) {
                    setProducts([]);
                    console.log('res?.data?.msg', res?.data?.msg);
                    
                    if (adInfo?.mode == 'productCatalog') alertMessage(alerts, setAlerts, res?.data?.msg, 'Error')
                }
                else {
                    setProducts(res?.data?.products);
                    edit_product_set(productSetId, productSetName,res?.data?.products[0]?.image_url  )
                
                }
            })
        } catch (error) {
            console.error('Error fetching product sets:', error);
            // Handle the error, e.g., set an error state or show an alert
        }
    }

    const edit_product_set = async(productSetId, productSetName, productSetImg) =>{
        const newMetaField = {
            'Meta.productSetId': productSetId,
            'Meta.productSetName': productSetName,
            'Meta.productSetImg': productSetImg,

            questId: adInfo?.questId
        };
        
    
        setAdInfo(prev => ({
            ...prev,
            productSetId: productSetId,
            productSetName: productSetName,
        }));

        
        try {
            await q.editQuestMode(newMetaField);
        } catch (err) {
            console.log(err);
        }
    }



    const haveProductsLoaded = () => {
        return products?.length && products.some(product => product?.name && product?.name !== '');
    };
    
    const handleBackNav = () =>{
        let business_id = store.getState()?.metaSource?.account_selection_info?.business_id || false;
        let ad_account_id = store.getState()?.metaSource?.account_selection_info?.ad_account_id || false;
        if(pocketAgencyContext==="E2000" && business_id && ad_account_id){

            navigate(`/${lang}/questionnaire/beta?business_id=${business_id}&ad_account_id=${ad_account_id}`)
        }
        else navigate(`/${lang}/questionnaire/beta/`);
    }
    
    console.log('productCatelgs', productCatalogs);
    console.log('productSets', productSets);
    console.error('adinfo', adInfo);
    

    useEffect(() => {
        check_for_ad();
        // check_for_previous_quests();
      //  get_my_ads();
    }, [])

    useEffect(()=>{
        get_my_ads();
    }, [adInfo?.flow, adInfo?.mode])


    useEffect(() => {
       if (adInfo?.productSetId) get_products_from_product_set(adInfo?.productSetId, adInfo?.productSetName)
    }, [adInfo?.productSetId])

    console.log('adInfo----', adInfo);
    console.log('products----', products);
    
    console.error('CONTEXT', pocketAgencyContext)


    
  if (loading) return <Main_Text_Loader text={loading}/>
  return (
    <CreateAd>
        <Helmet>
            <title>Questad | Create advertisement</title>
        </Helmet>
        {showTemplates?.slider && 
            <Ad_Templates adInfo={adInfo} ads={ads} setAds={setAds} adsLoading={adsLoading} setShowTemplates={setShowTemplates}/>
        }
    <PageForty>
        {/* {confirmation  ? <Confirmation_
                message={t("Would you like to reuse an already existing ad?")}
                no={t("No")}
                onNo={() => choice_no()}
                onYes={()=> choice_yes()}
                yes={t("Yes")}
          
          /> : <></>} */}
        <Alert_
            messages={alerts}
            duration = {10000}
        />
     
        <PageAlwaysVisible>
                <PageTitle cta>
                    <h1>{t('Create ad')}</h1>
                    {/* {store?.getState()?.reuseAd == 'DECIDED' ? <Main_Plain text={t('Reuse Ad')} iconLeft='Recycle' onClick={()=> setShowTemplates({...showTemplates, slider:true})} /> : <></>} */}
                    {/* {ads?.length ? <Main_Plain text={t('Reuse Ad')} iconLeft='Recycle' onClick={()=> setShowTemplates({...showTemplates, slider:true})}  /> : null} */}
                </PageTitle>
                {adInfo?.flow == 'eshop' ? 
                    <ModifyAdToggle>
                     
                        <CreateAdEshopType>
                            <CreateAdEshopTypeChoice activeType={adInfo?.mode == 'productCatalog'} onClick={() => edit_quest_mode('productCatalog')}>
                                <IconSvg Icon='Products'/>
                                <span>{t('Product catalog')} </span>
                            </CreateAdEshopTypeChoice>
                            <CreateAdEshopTypeChoice activeType={adInfo?.mode == 'default'} onClick={() => edit_quest_mode('default')}>
                                <IconSvg Icon='UploadImage'/>
                                <span> {t('Media')}</span>
                            </CreateAdEshopTypeChoice>
                        </CreateAdEshopType>
                    </ModifyAdToggle>
                : 
                    null
                }

                {isKnight ? 
                    <Builder_ >
                        <ModifyAdToggle>
                            <Toggle_Stat
                                text={t('Real-time Lead Notifications')}
                                onClick={() => toggleLeadNotif(!leadNotif)}
                                active={leadNotif} 
                            /> 
                        </ModifyAdToggle>
                    </Builder_>
                : 
                    null
                }
            <AdForm activeType={adInfo?.flow == 'eshop' ? adInfo?.mode : 'none'}>
                <Create_Ad_Fields
                    adInfo={adInfo} 
                    setAdInfo={setAdInfo} 
                    emptyFields={emptyFields} 
                    setEmptyFields={setEmptyFields}
                    messages={messages}
                    setMessages={setMessages}
                    // setShowSalePrice
                />

                {adInfo?.flow == 'eshop' && adInfo?.mode == 'productCatalog' ?  null :
                    <Create_Ad_Service_Only_Fields
                        adInfo={adInfo} 
                        setAdInfo={setAdInfo} 
                        emptyFields={emptyFields} 
                        setEmptyFields={setEmptyFields}
                        mediaErrors={mediaErrors}
                        setMediaErrors={setMediaErrors}
                    />
                    }
                    



                {adInfo?.flow == 'eshop' && adInfo.mode == 'productCatalog'  ? 
                    <div>
                   
                    <Builder_ title={t('Product Pricing Options')}>
                        <Toggle_Stat
                            active={adInfo?.showDiscount}
                            text={t("Show discounted prices on products")}
                            onClick={() => setAdInfo({ ...adInfo, showDiscount: !adInfo?.showDiscount })}
                        />
                    </Builder_>

                        <Builder_ title={t('Select a product catalog')}>
                            <Dropdown_ label={t('Catalog')}>
                                <select 
                                    value ={adInfo?.catalogId || ''} 
                                    onChange={(e) => save_product_catalog(e.target.value)}
                                >
                                    <option value={''} disabled>{t('Product catalog')}</option>
                                    {productCatalogs?.map((catalog) => (
                                        <option
                                            key={catalog?.catalogId}
                                            value={catalog?.catalogId}
                                        >
                                            {catalog?.catalogName}
                                        </option>
                                    ))}
                                </select>
                            </Dropdown_>

                            {emptyFields?.includes('Catalog') ? (
                                    <ValidatorError>
                                        <IconSvg Icon="Error" />
                                        <h6>{t('Product catalog must be selected')}</h6>
                                    </ValidatorError>
                           ) : null} 
                        </Builder_>


                        <Builder_ title={t('Select a product set')}>
                            {loadingProductSets ? <Partial_Loader /> : 
                                <Dropdown_ label={t('Product set')}>
                                    <select 
                                        value ={adInfo?.productSetId || ''} 
                                        onChange={(e) => edit_product_set(e.target.value, e.target.selectedOptions[0].text)}
                                    >
                                        <option value={''} disabled>{t('Product set')}</option>
                                        {productSets?.map((product) => (
                                            <option
                                                key={product.id}
                                                value={product.id}
                                            >
                                                {product.name}
                                            </option>
                                        ))}
                                    </select>
                                </Dropdown_>
                            }
                            {emptyFields?.includes('ProductSet') ? (
                                <ValidatorError>
                                    <IconSvg Icon="Error" />
                                    <h6>{t('Product set must be selected')}</h6>
                                </ValidatorError>
                             ) : null} 
                            </Builder_>

                             <Builder_>
                             <Video_Helper
                                mainVideo={'https://www.youtube.com/watch?v=UA3WlvXS0HA&t=67s'}
                                mainVideoTitle={t('Questad | How do I create a product catalog on Facebook?')}
                                title={t('Create product gatalog')}
                                sections = {sectionData}
                                textBtn={t('How to create a product catalog')}
                                text={t("If you are having trouble finding your product catalog in the dropdown list, follow the instructions provided on how to create and successfully integrate a product catalog on Facebook for your eShop.")}
                            />
                             </Builder_>



                    </div>
                : null
                }
            </AdForm>




           <Actions_>
                <Grey_ iconLeft="Back" text={t('Back')} onClick={handleBackNav} noPadding />
                {/* {store?.getState()?.reuseAd == 'DECIDED' ? <Main_Plain text={t('Reuse Ad')} iconLeft='EyeOpen' onClick={()=> navigate(`/${lang}/choose-ad`)} /> : <></>} */}
   
                <Main_ 
                    text={t('Next')} 
                    className = {adInfo?.flow == 'eshop' && adInfo.mode == 'productCatalog' && !products?.length ? 'unclickable' :''}
                    iconRight='EyeOpen'
                        onClick={() => {
                            if (validateFields()?.length ) {
                                alertMessage(alerts, setAlerts, (t('Please check fields')),'Error')
                                return; }
                            else edit_ad(false)
                        }}  
                />
           </Actions_>
        </PageAlwaysVisible>
        <PageDesktopOnly>
            { adInfo?.flow == 'eshop' && adInfo?.mode == 'productCatalog'  ?
                <AdMockupWrapper>
                    <Preview_Catalog_Mockup products={products} edit_product_set={''} adInfo={adInfo} setAdInfo={setAdInfo}/>
                </AdMockupWrapper>
            :
                <AdMockupWrapper>
                    <Preview_Ad_Mockup adInfo={adInfo} />
                </AdMockupWrapper>
            }
        </PageDesktopOnly>
    </PageForty>
    </CreateAd>
  )
}



