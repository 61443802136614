import { AlertContext, Alert_, Edit_, Focus_, Focus_Plain, Grey_, Grey_Link, Main_, Main_Loader, Main_Plain, PageFull, PageTitle, device, Confirmation_, Partial_Loader, Slider, Slider_, PageSubtitle, Main_Text_Loader, Filters_, P } from 'monica-alexandria'
import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../../localization/i18next'
import { Landing_ } from '../../components/landing/Landing_'
import { LandingContext } from '../../context/LandingContext'
import l from '../../services/api/Landings'
import q from '../../services/api/Quests'
import a from '../../services/api/Ads'
import { load_my_landing, prepare_landing_for_saving_in_DB } from './utils'
import { alertMessage } from '../../utils/messagesAlerts'
import { Landing_Preview } from '../../components/landing/Landing_Preview'
import store from '../../redux/store'
import Landing_Templates from '../../components/landing/Landing_Templates'
import { FixedTitle, LandingActions, LandingActionsDropdown, LandingBtnWrapper, LandingWrapper } from '../../styles/Layout'
import { DailyprofitSvg } from '../../svg/DailyprofitSvg'
import { Ad_Templates } from '../../components/ad/Ad_Templates'
import { Dropdown_Button } from '../../library/buttons/Dropdown_Button'
import { Helmet } from 'react-helmet'
import Landing_New from '../../components/landing/Landing_New'
import { Landing_Form_Preview } from '../../components/landing/Landing_Form_Preview'


const LandingBuilder = styled.div`
 
`

let minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
process.env.REACT_APP_MINIO_HOST + 
process.env.REACT_APP_MINIO_PORT+ '/' +
process.env.REACT_APP_MINIO_BUCKET + '/';


const LandingBuilderWrapper = styled.div``

export default function LANDING_BUILDER() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language ? i18n.language : 'el';

  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const {landing, setLanding} = useContext(LandingContext)
  const {alerts, setAlerts} = useContext(AlertContext);

  const [adImg, setAdImg] = useState('')

  const [showOptions, toggleShowOptions] = useState(false);
  const [showTemplates, setShowTemplates] = useState({id:'', slider:''});
  // const {alerts, setAlerts} = useContext(AlertContext)

  const [landings, setLandings] = useState([]);
  const [landingsLoading, setLandingsLoading] = useState(true);

  console.log('LANDING BUILDER', landing);
  const [landingPreview, toggleLandingPreview] = useState(false);


  const dropdownPopupRef = useRef(null);

  // Function to close the OptionsPopup when clicking outside
  const closeOptionsPopup = (event) => {
    if (dropdownPopupRef.current && !dropdownPopupRef.current.contains(event.target)) {
      toggleShowOptions(false);
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener('mousedown', closeOptionsPopup);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', closeOptionsPopup);
    };
  }, []);


  // Searches for previous quests in order to reuse a landing, if possible
  // const check_for_previous_quests = () =>{
  //   q.getMyQuests()
  //   .then(res => {
  //       if (res?.data?.code == 'Q5006') {

  //           let quests = res?.data?.data;

  //           if (quests?.length > 1 && store?.getState()?.reuseLanding !== 'DECIDED') {
  //             setConfirmation(true);
  //           }
  //       }
  //   })
  // }

  // Saving the decision in the cache because we do not want to spam the user with the popup
  const choice_no = () => {
   // store.dispatch({type:'REUSE_LANDING', payload: 'DECIDED'});
    setConfirmation(false);
  }

  // Saving the decision in the cache because we do not want to spam the user with the popup
  const choice_yes = () => {
   // store.dispatch({type:'REUSE_LANDING', payload: 'DECIDED'});
    setConfirmation(false);
    setShowTemplates({...showTemplates, slider:true})
   // navigate(`/${lang}/choose-webpage`);
  }

  const check_for_landing = () => {
    l.checkForLanding()
      .then(res => {
        // eshop flow
        console.log('CHECK FOR LANDING', res?.data)
        if (res?.data?.field == 'eshop' || res?.data?.field == 'products offline') navigate(`/${lang}/preview-ad/`);
        else if (res?.data?.page_exists) {
          get_my_landing(res?.data?.questId)
        //  get_my_ad(res?.data?.questId)
        }

        else setLanding({...landing, questId: res?.data?.questId, code: 'L2002'});
      })
      .catch(err => console.log(err))
  }

  const get_my_landing = (questId) => {
    l.getMyLanding(questId)
      .then(res => {
        console.log('RESS LANDING', res?.data);
        if (res?.data?.code == 'L2002') setLanding(load_my_landing(res?.data?.landing, res?.data?.code));
      })
  }

  const get_my_landings = () => {

    l.getMyLandings()
    .then(res => {
        console.log('RESPONSE GET MY LANDINGS' , res);
        if (res?.data?.code == 'L2007') {
            let landingsTemplates =  res?.data?.landings;
            setLandings(landingsTemplates);

            // if (landingsTemplates?.length > 1) {
            //   setConfirmation(true);
            // }
        }
        else {
          alertMessage(alerts, setAlerts, res?.data?.msg, 'Success');
        }
        setLandingsLoading(false);
    })
}

  const validateFields = () => {
    const emptyFields = [];
    // Check if any required field is empty and add it to the emptyFields array
    if (!landing?.header?.title) {
        emptyFields.push('Title');
    }
    if (!landing?.header?.subtitle) {
        emptyFields.push('Subtitle');
    }
  //   if (!landing?.customFormField?.label) {
  //     emptyFields.push('Custom field');
  // }

    if (!landing?.customFormField || landing.customFormField.length === 0) {
      emptyFields.push('Custom fields');
  } else {
      landing.customFormField.forEach((field, index) => {
          if (!field.label) {
              emptyFields.push(`Custom field #${index + 1} title`);
          }
          if (field.type === 'multiple') {
              field.choices.forEach((choice, choiceIndex) => {
                  if (!choice) {
                      emptyFields.push(`Custom field #${index + 1} choice #${choiceIndex + 1}`);
                  }
              });
          }
      });}
    // Return true if all required fields are filled, otherwise return false
   // return emptyFields.length === 0 ? true : emptyFields;
    return emptyFields;
};

console.log('LANDING_________', landing);
  const edit_landing_page = (isDraft) => {
    console.log('LANDING', landing);

    setLoading(true)
    const validationResult = validateFields();
    if (validationResult.length > 0) {
      const emptyFieldsMessage = `Please fill in the following fields: ${validationResult.join(', ')}`;
      alertMessage(alerts, setAlerts, emptyFieldsMessage, 'Error');
      setLoading(false);  // Ensure loading state is reset
      return;  // Skip calling editLanding if validation fails
    }
  
    let landingFinal = prepare_landing_for_saving_in_DB(landing, isDraft);

    console.log('landingFinal', landingFinal);
  
    l.editLanding(landingFinal, landing.file , landing.fileLogo)
    .then(res => {

      console.log('RESSSSSSSSSSSSSSS', res.data);
      if (isDraft && res?.data?.code == 'L2001') alertMessage(alerts, setAlerts, (t('Draft has been saved')), 'Success');
      else if (!isDraft && res?.data?.code == 'L2001') navigate(`/${lang}/preview-ad/`);
      else if (res?.data?.code.startsWith('L4001')) {

        // const validationResult = validateFields();

        // console.log('validationResult', validationResult);
        // const emptyFieldsMessage = `Please fill in the following fields: ${validationResult.join(', ')}`;
        // alertMessage(alerts, setAlerts, emptyFieldsMessage, 'Error');
      }
      else if (res?.data?.code?.startsWith('C5190')) console.log('Meta session expired')
      else alertMessage(alerts, setAlerts, res?.data?.msg, 'Error');

      setLoading(false)
     })
 
  }



  const get_my_ad = ()=> {

    a.getMyAd(landing?.questId)
    .then(res => {
        if (res?.data?.code == "A2002") {
          // const image = res?.data?.ad?.thumbnail && res.data?.ad?.thumbnail !== 'default.jpg' ?  res.data?.ad?.thumbnail  : `${minioUrl}${res.data?.ad?.userId}/${res.data?.ad?.visualElement}`
          const image = res?.data?.ad?.thumbnail && res.data?.ad?.thumbnail !== 'default.jpg' ?  res.data?.ad?.thumbnail  : `${minioUrl}${res.data?.ad?.userId}/${res.data?.ad?.visualElement}` ;
          setAdImg(image)
           console.log('res?.data?.ad', res?.data?.ad);
        }
    })
  }

  const is_special_ad_category = () => {

    if (landing?.specialAnswer) return true;

    let answers = store.getState()?.answers;
    if (answers?.length > 1) {
      if (answers[1]?.specialAnswer) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    check_for_landing();
    // check_for_previous_quests();
  }, [showTemplates.id])

  useEffect(() => {
    get_my_landings();
    get_my_ad()
  }, [])

  
  if (!landing.code) return <Main_Loader />

  if (loading) return <Main_Text_Loader text='Creating landing...' />
  return (
  
    <LandingBuilder>
      <Helmet>
        <title>Questad | Create landing page</title>
      </Helmet>
      {/* {confirmation ? <Confirmation_
            message={t("Would you like to reuse an already existing landing?")}
            no={t("No")}
            onNo={() => choice_no()}
            onYes={()=> choice_yes()}
            yes={t("Yes")}
        /> : <></>} */}
        {loading && <Partial_Loader />}
        <Alert_ 
          messages={alerts} 
          duration = {5000}
        />
        {showTemplates.slider &&
          <Landing_Templates landingsLoading={landingsLoading} setShowTemplates={setShowTemplates} landings={landings} />
        }
        {landingPreview ?
          <Landing_Form_Preview 
            onSave= {()=> edit_landing_page(false)} 
            adImg={adImg} 
            landingNew 
            disableForm 
            viewMode={true} 
            toggleLandingPreview={toggleLandingPreview}
          />
        :
          <Landing_New 
            onBack={() => navigate(`/${lang}/webpage-helper`)} 
            onNext={()=>toggleLandingPreview(true)} 
            onSave={()=> edit_landing_page(false)} 
            adImg={adImg} 
            disableForm
            specialAnswer={is_special_ad_category()}
          />
        }
    </LandingBuilder>
 
  )
}
