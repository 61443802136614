import { Card, Focus_, Focus_Plain, Grey_, Grey_Link, IconSvg, Main_Plain, Red_, device } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prettifyDates } from '../../../../helpers/prettifiers'
import q  from '../../../../services/api/Quests';
import p  from '../../../../services/api/Payments';
import { useParams } from 'react-router-dom'
import store from '../../../../redux/store'

const SubscriptionCard = styled(Card)`
  background: ${p => p.theme.low};
  padding: 2rem;

  @media ${device.sm} {
      margin-bottom: 10rem;
  }
`

const SubscriptionCardBot = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 2rem;
`

const SubscriptionCardActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;


`

const SubscriptionCardBottomActions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
`

const SubscriptionCardInfo = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;


  @media ${device.lg} {
    flex-direction: column;
  }

  @media ${device.md} {
    flex-direction: row;
  }

  @media ${device.sm} {
    flex-direction: column-reverse;
    padding-bottom: 4rem;
  }
`

const SubscriptionCardGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;
  margin-top: 2rem;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }

  @media ${device.md} {
    grid-template-columns: auto 1fr;
  }

  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
`

const SubscriptionCardLeft = styled.div``

const SubscriptionCardAmountPaid = styled.div`

  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  @media ${device.sm}{
    flex-direction: column;
    gap: 0;
  }
`

const SubscriptionCardAmountPaidInner = styled.div`
  display: flex;
  align-items: baseline;
  h1{
    font-size: 4rem;
    text-align: center;
  }
`

const SubscriptionPlanCard = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
`

const SubscriptionPlanCardNo = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 1rem;

    svg{
        width: 3rem;
        fill: ${p => p.theme.color};
    }
`


export default function Subscription_Card(props) {

  const {t} = useTranslation();
  const [transactions, setTransactions] = useState([])
  const [ initialLoading, setInitialLoading ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(true);

  const [amount,setAmount] = useState('');
  const redirect = `${window.location.origin}${window.location.pathname}?name=Questad&category=dailyprofit`;


  const {lang} = useParams();
  const userId = store.getState()?.user?.user?.sub;

  const SubscriptionStatus = {
    active: (t("active")),
    past_due: (t("payment failed"))
  };

  const go_to_edit_subscription = () => {
      const customer = props.customerId;

    p.questad_customer_portal(customer, redirect)
      .then(data => {
        if (data?.data?.url) {

          console.log('DATAAA', data);
          
          setIsLoading(false)
          window.location.assign(data?.data?.url)
        }
        else isLoading(true);
      })
      .catch(err => {})
  }



  useEffect(()=>{
    if(props.customerId){
      p.getTransactions(props.customerId)
        .then((res) => {
          console.log('resRESSSSSSSSS', res)
          setTransactions(res?.data);
          setInitialLoading(false)
        })
        .catch((err) => {console.log(err)});
    }
  }, [props.customerId])


  return (
    <SubscriptionCard>
      <SubscriptionCardInfo>
        <SubscriptionCardLeft>
          <SubscriptionCardAmountPaid>
            <SubscriptionCardAmountPaidInner>
            <h1>€{props.amountPaid}</h1>
            <h5>,00</h5>
            </SubscriptionCardAmountPaidInner>
            <h5>(VAT incl.)</h5>
          </SubscriptionCardAmountPaid>
          <SubscriptionCardGrid>
            {props.isOldBudgetUser ? null 
            :
            <>  <p>{t("Adspend")}:</p>
               <h5>€{Number(props.adspend || 0).toFixed(2).replace(".", ",")}</h5>
               
            </> }
            <p>{t("Payment interval")}:</p>
            <h5>{t("30 days")}</h5>
            <p>{t("Subscription Status")}:</p>
            <h5>{SubscriptionStatus[props.subscriptionStatus]}</h5>
            <p>{t("Next payment")}:</p>
            <h5>{!props.subscriptionCancelOnPeriodEnd ? prettifyDates(props.subscriptionNextPayment * 1000) : (t("Next scheduled payment canceled"))}</h5>
            {!props.subscriptionCancelOnPeriodEnd && props?.amountTopUp ? <>
              <p>{t("Next invoice is set to")}:</p>
              <h5>€ {props.amountTopUp},00</h5>
            </> : null}
          </SubscriptionCardGrid>
        </SubscriptionCardLeft>
       {!props.isPocketAgencyUser && !props.subscriptionCancelOnPeriodEnd ? <SubscriptionCardActions>
          <Focus_ noPadding text={t("Change budget")} size="small" iconLeft="Settings" onClick={props.onSubscriptionTopup} />
        </SubscriptionCardActions> :null}
      </SubscriptionCardInfo>
        <SubscriptionCardBottomActions>
         

          <SubscriptionCardBot>
            {/* <Grey_Link text={t("View subscription details")} iconLeft="Group" noPadding size="small" onClick={props.onSubscriptionDetails}/> */}
            {props.subscriptionCancelOnPeriodEnd ? 
            <Focus_ size="small" noPadding iconLeft="Swap" text={t("Renew")}  onClick={props?.onSubscriptionRenew}
          />
            :
            <Red_ size="small" noPadding iconLeft="Delete" text={t("Cancel")} onClick={props.onSubscriptionCancel} />
            }
          </SubscriptionCardBot>
          <SubscriptionPlanCard>
            <SubscriptionPlanCardNo>
                <IconSvg Icon="Payment" />
                <h5>*</h5>
                <h5>{transactions[transactions?.length - 1]?.customer?.invoice_settings?.default_payment_method?.card?.last4 || 'no card'}</h5>
            </SubscriptionPlanCardNo>
            <Main_Plain iconLeft="Edit" noPadding text={t("Payment")} onClick={()=> go_to_edit_subscription()}/> 
          </SubscriptionPlanCard>
      </SubscriptionCardBottomActions>
    </SubscriptionCard>
  )
}
