
const load_landing = (landingDoc, code) => {

    let doc = {header: {}, colors: {}, defaultFormFields: [
        "Name",
        "Surname",
        "Tel",
        "FullName",
        "City"
    ],
    form: {
        gdpr: false
    },
    customFormField: {
        "visibility": false,
        "label": ""
    },
 
    };

    if (landingDoc?.form) doc.form = landingDoc.form;
    doc.header.title = landingDoc?.title;
    doc.header.subtitle = landingDoc?.subtitle;
    doc.colors.background = landingDoc?.bgColor;
    doc.colors.text = landingDoc?.formColor;
    doc.image = landingDoc?.image;
    doc.draft = landingDoc?.draft;
    doc.questId = landingDoc?.questId;
    doc.userId = landingDoc?.userId;
    doc.gdpr = landingDoc?.gdpr;
    doc.code = code;
    
    if (landingDoc?.customFormField) doc.customFormField = convert_to_array(landingDoc?.customFormField);
    if (landingDoc?.defaultFormFields) doc.defaultFormFields = landingDoc?.defaultFormFields;
    if (landingDoc?.image) doc.image = landingDoc?.image;

    return doc;
}


const convert_to_array = (customField) => {

    if (!customField?.length) {
      return [{...customField, type:'input'}];
    }
    return customField;
}

const load_form = (formDoc, landingId) => {

    let doc = {};
    console.log("FORM DOC", formDoc)
    doc['landingId'] = landingId;

    if (formDoc['Name']) doc.Name = formDoc['Name'];
    if (formDoc['Surname']) doc.Surname = formDoc['Surname'];
    if (formDoc['FullName']) doc.FullName = formDoc['FullName'];
    if (formDoc['Tel']) doc.Tel = formDoc['Tel'];
    if (formDoc['Email']) doc.Email = formDoc['Email'];
    if (formDoc['City']) doc.City = formDoc['City'];
    if (formDoc['CustomField']) doc.CustomField = formDoc['CustomField'];
    if (formDoc?.gdpr) doc.gdpr = formDoc['gdpr'];
  
    return doc;
}

export {
    load_landing,
    load_form
}