import axios from "axios"
import { config } from "../../config"


const get_pre_signed_url = (mimetype) => {
    return new Promise((resolve, reject) => {
        
        axios.post(`${config.DAILYPROFIT_BACKEND_URL}/uploads/presignedUrl`, 
        {
            mimetype: mimetype,
            
        })
        .then(res => {
            console.log('[presigned url] >',res.data);
            resolve(res)
        })
        .catch(() => {
            console.error('[Error | presignedUrl] > ')
            resolve(false);
        })
    })
}

const upload_media = (file, setLoading) => {
    return new Promise(async(resolve, reject) => {

        // 1. Get server-signed url from backend
        get_pre_signed_url(file?.type).then(url => {

            const presignedUrl = url?.data?.data?.url;
            const fileName = url?.data?.data?.fileName;
        
            // 2. Transfer file from Frontend -> Minio S3
            axios.put(`${presignedUrl}`, file)
            .then(res => {
                console.log('[upload] >',res?.status);
                
                if (res?.status == 200) {
                    resolve({fileName: fileName, code: res?.data?.code})
                }
                else {
                    setLoading(false);
                    resolve(res);
                }
            })
        })
        .catch(() => {
            console.error('[Error | upload] > ');
            setLoading(false);
            resolve(false);
        })
    })
}


const uploadEndpoints = {
    upload_media
}

export default uploadEndpoints;
