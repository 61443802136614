
const load_my_landing = (landingDoc, code) => {

    let doc = {header: {}, colors: {}, defaultFormFields: [
        "Name",
        "Surname",
        "Tel",
        "FullName",
        "City"
    ],
    form: {},
    customFormField: {
        "visibility": false,
        "label": ""
    }};

    doc.header.title = landingDoc?.title;
    doc.header.subtitle = landingDoc?.subtitle;
    doc.colors.background = landingDoc?.bgColor;
    doc.colors.text = landingDoc?.formColor;
    doc.image = landingDoc?.image;
    doc.draft = landingDoc?.draft;
    doc.questId = landingDoc?.questId;
    doc.userId = landingDoc?.userId;
    doc.code = code;
    
    if (landingDoc?.customFormField) doc.customFormField = convert_to_array(landingDoc?.customFormField);
    if (landingDoc?.defaultFormFields) doc.defaultFormFields = landingDoc?.defaultFormFields;
    if (landingDoc?.image) doc.image = landingDoc?.image;
    if (landingDoc?.campaigns) doc.campaigns = landingDoc?.campaigns;
    if (landingDoc?.specialAnswer) doc.specialAnswer = landingDoc?.specialAnswer;

    return doc;
}

const convert_to_array = (customField) => {

    if (!customField?.length) {
      return [{...customField, type:'input'}];
    }
    return customField;
}


const prepare_landing_for_saving_in_DB = (landing, isDraft) => {

    let landingFinal = {};

    landingFinal.title = landing?.header?.title;
    landingFinal.subtitle = landing?.header?.subtitle;
    landingFinal.bgColor = landing?.colors?.background;
    landingFinal.formColor = landing?.colors?.text;
    landingFinal.draft = isDraft;
    landingFinal.questId = landing?.questId;

    landingFinal.customFormField = landing?.customFormField;
    landingFinal.defaultFormFields = landing?.defaultFormFields;

    if (landing?.image) landingFinal.image = landing?.image;

    return landingFinal;
}

const campaign_of_type_instant_form_exists = (campaigns) => {

    if (!campaigns?.length) return false;

    let detected = false;

    campaigns.forEach(campaign => {
        // this ensures that there exists an active, non-failed LEADS campaign
        if (campaign?.type == 'INSTANT_FORM' && campaign?.campaign_id) {
            detected = true;
        }
    })

    return detected;
}

export {
    load_my_landing,
    prepare_landing_for_saving_in_DB,
    campaign_of_type_instant_form_exists
}
