import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  AlertContext,
  Alert_,
  Builder_,
  IconSvg,
  Radio_,
  Steps_Actions,
} from "monica-alexandria";
import { StepContext } from "../../context/StepContext";
import { useTranslation } from "react-i18next";
import { push_new_answer, renderStepDescription } from "../../pages/questionnaireB/utils";
import Double_Range from "../../library/range/Double_Range";
import { StepsTitle } from "../../styles/Layout";

const ClientAgeGender = styled.div`
  .RadioDisabled{
    opacity: 0.5;

    label, input{
      cursor: default;

    }
   
  
  }

  
  
`

const ClientAgeGenderRange = styled.div`
  margin-top: 1rem;
`

const ClientAgeGenderHelper = styled.div`
    border: 3px dotted ${p => p.theme.background};
    background: ${p => p.theme.mid};
    padding: var(--normalPads);
    border-radius: 1rem;

    h5{
        color: var(--greyDark);
        margin-bottom: 1rem;
    }

    svg{
        width: 2rem;
        margin-bottom: 5px;
        fill: var(--warning);
    }
`

export default function Client_Age_Gender(props) {

  const { t } = useTranslation();
  const { step, setStep } = useContext(StepContext);
  const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
      question: (t(`${props.Q3.question}`)),
      type: `${props.Q3.type}`,
      answer: {minAge: props?.Q3?.answer?.minAge || 18, maxAge:props?.Q3?.answer?.maxAge || 65, gender: props?.Q3?.answer?.gender || 'both'}
    })

const handleRangeChange = ({ min, max }) => {
  setStepInfo((prevStepInfo) => ({
    ...prevStepInfo,
    answer: { ...prevStepInfo.answer, minAge: min, maxAge: max },
  }));
};

const updateGender = (newGender) => {
  setStepInfo((prevStepInfo) => {
    const updatedAnswer = {
      ...prevStepInfo,
      answer: {
        ...prevStepInfo.answer,
        gender: newGender,
      },
    };
    return updatedAnswer;
  });
};

console.log('props?.Q3',props?.Q3);

return (
  <ClientAgeGender>
    <StepsTitle>        
      <h3>{t('Describe the age and gender of your prospective clients')}</h3>
      {renderStepDescription(step?.step, t)}
    </StepsTitle>
    <Builder_ title="Age range">
      {/* TODO: replace with alexandria */}
      <ClientAgeGenderRange>
        <Double_Range
          min={18}
          max={65}
          defaultMin={stepInfo?.answer?.minAge}
          defaultMax={stepInfo?.answer?.maxAge}
          onChange={({ min, max }) => handleRangeChange({ min, max })}
          disabled={props.specialCategory}
        />
      </ClientAgeGenderRange>
    </Builder_>
    <Builder_ title="Gender">
      <Radio_
          id="both"
          text={t("Both")}
          name="per"
          value="products offline"
          checked={stepInfo.answer.gender == "both"}
          onClick={() => updateGender("both")}
          // className={props.specialCategory ? 'RadioDisabled' : ''}
        />
      <Radio_
        id="male"
        text={t("Male")}
        name="per"
        value="male"
        checked={stepInfo.answer.gender == "male"}
        onClick={() => updateGender("male")}
        className={props.specialCategory ? 'RadioDisabled' : ''}
      />
      <Radio_
        id="female"
        text={t("Female")}
        name="per"
        value="female"
        checked={stepInfo.answer.gender == "female"}
        onClick={() => updateGender("female")}
        className={props.specialCategory ? 'RadioDisabled' : ''}

      />
     
    </Builder_>
    {props.specialCategory ? 
        <ClientAgeGenderHelper>
          <IconSvg Icon="Tip" />
          <p>{t("Due to legal and Meta policy requirements, age and gender targeting options are restricted for Special Ad Categories. This ensures compliance with anti-discrimination laws related to credit, employment, housing, and social issues.")}</p>
        </ClientAgeGenderHelper>
    : null}
    <Steps_Actions
      icon_Grey="Back"
      icon_Main="Next"
      onClick_Grey={() => props?.specialCategory ? setStep({ ...step, step: step?.step - 2 }) : setStep({ ...step, step: step?.step - 1 })}
      onClick_Main={() =>
        setStep({
          step: step?.step + 1,
          answers: push_new_answer(step, stepInfo),
        })
      }
      text_Grey={t("Back")}
      text_Main={t("Next")}
    />
  </ClientAgeGender>
);

}
