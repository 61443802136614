export const config = {
        BACKEND_URL: process.env.REACT_APP_BACKEND_PROTOCOL +
                        process.env.REACT_APP_BACKEND_HOST +
                        process.env.REACT_APP_BACKEND_PORT,
        KEYMAKER: process.env.REACT_APP_KEYMAKER_URL_PROTOCOL +
                        process.env.REACT_APP_KEYMAKER_URL_HOST +
                        process.env.REACT_APP_KEYMAKER_URL_PORT,
        GRIPHOOK_BACKEND_URL: process.env.REACT_APP_GRIPHOOK_BACKEND_PROTOCOL +
                        process.env.REACT_APP_GRIPHOOK_BACKEND_HOST +
                        process.env.REACT_APP_GRIPHOOK_BACKEND_PORT,
        ERISED_BACKEND_URL: process.env.REACT_APP_ERISED_BACKEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_BACKEND_HOST +
                        process.env.REACT_APP_ERISED_BACKEND_PORT,
        DAILYPROFIT_BACKEND_URL: process.env.REACT_APP_DAILYPROFIT_BACKEND_PROTOCOL +
                        process.env.REACT_APP_DAILYPROFIT_BACKEND_HOST +
                        process.env.REACT_APP_DAILYPROFIT_BACKEND_PORT,
        erised: process.env.REACT_APP_ERISED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_ERISED_FRONTEND_HOST +
                        process.env.REACT_APP_ERISED_FRONTEND_PORT,
        minio:  process.env.REACT_APP_MINIO_PROTOCOL +
                        process.env.REACT_APP_MINIO_HOST,
        griphook: process.env.REACT_APP_GRIPHOOK_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_HOST +
                        process.env.REACT_APP_GRIPHOOK_FRONTEND_PORT,
        akihiko: process.env.REACT_APP_AKIHIKO_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_HOST +
                        process.env.REACT_APP_AKIHIKO_FRONTEND_PORT,
        dailyprofit: process.env.REACT_APP_DAILYPROFIT_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_HOST +
                        process.env.REACT_APP_DAILYPROFIT_FRONTEND_PORT,
        syrax:          process.env.REACT_APP_SYRAX_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_SYRAX_FRONTEND_HOST +
                        process.env.REACT_APP_SYRAX_FRONTEND_PORT,
        whitecastle:    process.env.REACT_APP_WHITECASTLE_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_WHITECASTLE_FRONTEND_HOST +
                        process.env.REACT_APP_WHITECASTLE_FRONTEND_PORT,
        lois:           process.env.REACT_APP_LOIS_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_LOIS_FRONTEND_HOST +
                        process.env.REACT_APP_LOIS_FRONTEND_PORT,
        fahed:           process.env.REACT_APP_FAHED_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_FAHED_FRONTEND_HOST +
                        process.env.REACT_APP_FAHED_FRONTEND_PORT,
        miniverse:      process.env.REACT_APP_MINIVERSE_FRONTEND_PROTOCOL +
                        process.env.REACT_APP_MINIVERSE_FRONTEND_HOST +
                        process.env.REACT_APP_MINIVERSE_FRONTEND_PORT,
        MINIVERSE_BACKEND_URL: process.env.REACT_APP_MINIVERSE_BACKEND_PROTOCOL +
                        process.env.REACT_APP_MINIVERSE_BACKEND_HOST +
                        process.env.REACT_APP_MINIVERSE_BACKEND_PORT,
        }
      



