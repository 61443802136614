import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from 'react'
import { AdMockButton } from '../../styles/components/AdMockup';
import NoImg from '../../assets/ad/NoImgAd.jpg'
import { findLabelByValue } from '../../utils/findCtaLabel';
import { useTranslation } from 'react-i18next';
import { LimitCharacters } from 'monica-alexandria';
const FirstRotate = keyframes`
    0%{
        transform: translateX(0);
    }

    100%{
        transform: translateX(-40%);
    }
`

const LastRotate = keyframes`
    0%{
        transform: translateX(-40%);
    }

    100%{
        transform: translateX(0%);
    }
`

const FbCarousel = styled.div`
    overflow: hidden;
`

const FbCarouselContainer = styled.div`
    transform: ${p => p.rotate ? 'translateX(0%)' : 'translate(-40%)'};
    animation: ${p => p.rotate ? LastRotate : FirstRotate} .5s linear;
`

const FbCarouselItem = styled.div`
    border-radius: 1rem;
    background: ${p => p.theme.low};
    overflow: hidden;
    position: relative;
`

const FbCarouselSaleLabel = styled.div`
    position: absolute;
    top: 2rem;
    left: 0.5rem;
    background-color: #F4A615;
    border-radius: var(--normalRadius);
    padding: .2rem 1rem;
    display: flex;
    align-items: center;
    gap: .5rem;

    h6{
        color: var(--white);
        font-size: 1.5rem;
    }
    p{
        color: var(--white);
        text-decoration: line-through;
        font-size: 1.5rem;
        opacity: 0.9;

    }
`

const FbCarouselImg = styled.img`
    width: 100%;
    aspect-ratio: 5 / 4;
    object-fit: cover;
    height: auto !important;
`

const FbCarouselText = styled.div`
    padding: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
`

const FbCarouselWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(${(p) => p.items}, 70%);
    grid-gap: 1rem;
    margin: 1rem;
`

export default function Fb_Carousel(props) {

    const [rotate, setRotate] = useState(true);
    const {t} = useTranslation();

    const initialImgs = [
        {
            "image_url": NoImg,
            "name": ""
        },
        {
            "image_url": NoImg,
            "name": ""
        },
        {
            "image_url": NoImg,
            "name": ""
        },
    ]

    const [images, setImages] = useState(props?.products?.length>0 ? props?.products : initialImgs)


    const getFirstImageUrl = (imageUrl) => {
        return imageUrl.split(',')[0].trim();
    };
    
    useEffect(() => {
        if (props?.products?.length>0) setImages(props?.products)
        else setImages(initialImgs)
    }, [props?.products])
    

    console.log('props?.products?',images);
    

  return (
    // <FbCarousel>
    //     <FbCarouselContainer rotate={rotate} onClick={() => setRotate(!rotate)}>
            <FbCarouselWrapper items={images.length}>
                {images?.map((image, i) =>
                    <FbCarouselItem>
                        <FbCarouselImg src={getFirstImageUrl(image.image_url)} />
                        {props.adInfo?.showDiscount && image?.sale_price  ? 
                            <FbCarouselSaleLabel>
                                <p>{image?.price || null}</p>
                                <h6>{image?.sale_price || null}</h6>
                            </FbCarouselSaleLabel>
                        :null}
                        {props.onlyImgs ? null : 
                        <FbCarouselText>
                            <div>
                                <h5>{LimitCharacters(image?.name, 18)}</h5>
                                <p>{props.adInfo?.showDiscount && image?.sale_price ? image?.sale_price: image?.price}</p>
                            </div>
                            {/* <h5>{props.productTitleLength && image.name?.length > props.productTitleLength ? image.name.slice(0,props.productTitleLength)+'...' : image?.name}</h5> */}
                            <AdMockButton>
                                <span>{findLabelByValue(props.adInfo?.cta, t)}</span>
                            </AdMockButton>
                        </FbCarouselText>}
                    </FbCarouselItem>
                )}
            </FbCarouselWrapper>
    //     </FbCarouselContainer>
    // </FbCarousel>
  )
}
