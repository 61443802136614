import { AlertContext, Alert_, CardsForty, CardsFull, CardsSixty, Confirmation_, Dropdown_, Grey_Link, Main_, Main_Loader, Main_Plain, On_Click_Card, PageAlwaysVisible, PageDesktopOnly, PageDesktopPadding, PageFull, PageSixty, PageTitle, Preset_Card, Red_, Section_Title, Slider_, View_Card } from 'monica-alexandria'
import React, { useContext, useEffect, useState } from 'react'
import q  from '../../services/api/Quests';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { Quest_Card } from './components/Quest_Card';
import { Dashboard_Right } from './components/Dashboard_Right';
import { alertMessage } from '../../utils/messagesAlerts';
import { Dropdown_Button } from '../../library/buttons/Dropdown_Button';
import { check_for_drafts, check_status_of_quests_and_organize_them_in_sections } from './utils';
import noQuests from '../../assets/dashboard/NoQuests.svg'
import { Helmet } from 'react-helmet';
import Dashboard_Notifications from './components/Dashboard_Notifications';
import { Dashboard_Topup } from './components/Dashboard_Topup';
import { Dashboard_History_Notifications } from './components/Dashboard_History_Notifications';
// import Notification_ from '../../components/notification/Notification_';

const DashboardLeadsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    
`

const FirstQuestWrapper = styled.div`
    max-width: 65rem;
    width: 100%;
    padding: 2rem;
    box-shadow: ${p => p.theme.outFocus};
    border-radius: var(--normalRadius);
       
    background: ${p => p.theme.low};


    img {
        
        padding-bottom: 4rem;
        max-width: 50rem;
    }
`

const FirstQuestText = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    h3{
        padding-bottom: 1rem;
        text-align: center;
    }
    p{
        text-align: center;
    }
`
const FirstQuestActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 4rem;
`

const DashboardLeads = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);

`

const DashboardLead = styled.div`
    display: flex;
    align-items: center;

    h5{
        color: var(--main);
    }
    
`

const DashboardText = styled.div`

    h6{
        color: var(--greyDark);
    }
    
`
export default function DASHBOARD_() {

    const [quests, setQuests] = useState(false);
    const [problems, setProblems] = useState(false);
    const [selectedQuest, setSelectedQuest] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {lang} = useParams();
    const [loading, setLoading] = useState(true);
    const [confirmation, setConfirmation] = useState(false);
    const {alerts, setAlerts} = useContext(AlertContext);
    const [kpisMetrics, setKpisMetrics] = useState([{impressions:0}, {reach:0}, {frequency:0}, {clicks:0}]);
    const [formLeads, setFormLeads] = useState([]);
    const {questId} = useParams(); 
    const user = store.getState()?.user?.user;
    const isKnight = user?.roles?.includes('Knight');
    const [showTopUp, setShowTopUp] = useState(false);
    const [historyNotifications, setHistoryNotifications] = useState([])

    const activeQuests = quests?.quests?.filter((quest) => quests?.activeQuests?.includes(quest?._id))
    const draftQuests = quests?.quests?.filter((quest) => quest?.draft)
    const pausedQuests = quests?.quests?.filter((quest) => quests?.pausedQuests?.includes(quest?._id))
    const oldQuests = quests?.quests?.filter((quest) => (!quest?.draft && !quest?.active) || quests?.oldQuests?.includes(quest?._id))
    const problematicQuests = quests?.quests?.filter((quest) => quest?.active == true && quest?.campaignStatus == 'PAUSED')

    
    const get_my_quests = () =>{
        q.getMyQuestsWithKPIS(false)
        .then(res => {
            console.log('RESPONSE GET QUESTS' , res);
            if (res?.data?.code == 'Q5006') {
                setLoading(false);
                setQuests({...quests, quests: res?.data?.data});
                q.healthCheckQuests()
                .then(health => {
                    // check_status_of_quests_and_organize_them_in_sections(health, quests, setQuests, setLoading, res);
                    check_status_of_quests_and_organize_them_in_sections( quests, setQuests, setLoading, health);
                })
            }
            q.healthCheckQuests()
              .then(health => {
                  let problemsTemp = [];
                  health?.data?.data?.forEach(quest => {
                      if (quest?.problematic == true) {
                          problemsTemp.push(quest?.questId);
                      }
                  })
                  setProblems(problemsTemp);
              })
        })

       // setLoading(false);
    }

    const get_all_notifications = () =>{
        q.getAllNotifications()
        .then(res => { 
          console.log('notifications',res?.data?.notifications)  
          setHistoryNotifications(res?.data?.notifications)
        })
        .catch(err => console.log(err))
      }
  

    // deletes draft quest and everything related
    const choice_yes = () => {
        q.deleteDraftQuest(confirmation)
          .then(res => {
            if (res?.data?.code == 'Q2006') {
                setConfirmation(false);
                // notifes frontend that the flow we are about to enter is "START_NEW_QUEST"
                store.dispatch({type:'REUSE_LANDING', payload: 'NOT_DECIDED'});
                store.dispatch({type:'REUSE_AD', payload: 'NOT_DECIDED'});
                store.dispatch({type:'ENTER_NEW_FLOW', payload: "START_NEW_QUEST"});
                window.location.assign(`/${lang}/questionnaire/alpha`, {state: {flow: 'START_NEW_QUEST'}});
            }
            else {
                alertMessage(alerts, setAlerts, (t('Something went wrong.')),'Error');
            }
          })
    }

    const choice_no = () => {
        store.dispatch({type:'REUSE_LANDING', payload: 'NOT_DECIDED'});
        store.dispatch({type:'REUSE_AD', payload: 'NOT_DECIDED'});
        store.dispatch({type:'ENTER_NEW_FLOW', payload: "START_NEW_QUEST"});
        navigate(`/${lang}/facebook-login`);
    }

    const handleViewOptions = (quest) =>{ 
        console.log('quest clicked', quest);
      
        setSelectedQuest((prevSelectedQuest) =>
            prevSelectedQuest?._id == quest?._id ? false : quest
        );
        setShowTopUp(false);
      //  console.log('quest clicked pref', prevSelectedQuest);
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    //if window size is less than 992 then dont show device mockups
    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    
    useEffect(()=>{
        get_my_quests();
        get_all_notifications();
    },[])
        


    if (loading || !problems) return <Main_Loader />

    console.log("sdasdsadasdas:", draftQuests);
    console.log("sdasdsadasdas:", quests);
    console.log("TOP UP:", showTopUp);
    console.log('get_all_notifications', historyNotifications);
    console.log('activeQuests', activeQuests);

  return (
    <PageSixty>
        <Helmet>
            <title>Questad | Dashboard</title>
        </Helmet>    
        <Alert_ messages={alerts} />
        {confirmation ? 
            <Confirmation_
                message={t("If you create a new quest, your unfinished draft quest will be deleted. Do you wish to continue?")}
                no= {t('Cancel')}
                //onNo={() => choice_no()}
                onNo={() => setConfirmation(false) }
                onYes={()=> choice_yes()}
                yes={("Yes")}
            /> 
        : 
            null
        }
   

    {!activeQuests?.length && !pausedQuests?.length && !oldQuests?.length && !quests?.oldQuests?.length ?

        <PageAlwaysVisible>
            
            <PageTitle cta>
                <h1>{t("Dashboard")}</h1>
            </PageTitle>
            <FirstQuestWrapper>
                <FirstQuestText>
                    <img src={noQuests} />
                    <h3>{t("Seems like there are no quests available for creating ads on Meta at the moment.")}</h3>
                    <p>{t("Why not take the initiative and start a new quest? Let's kickstart your advertising journey by crafting your own ad!")}</p>
                    <p>{t("Unleash your creativity and let the quest for the perfect ad begin!")}</p>
                </FirstQuestText>
                <FirstQuestActions>
                    {draftQuests?.length? 
                        <Main_Plain text= {t("Continue Quest")} iconLeft='Edit' onClick={()=> choice_no()}/>
                    : <div></div>} 
                    <Main_ text= {t("New Quest")} iconLeft='Add' onClick={() => check_for_drafts(quests, navigate, lang, setConfirmation)}/>
                </FirstQuestActions>
            </FirstQuestWrapper>
            {/* {draftQuests?.length ? (
                    <div>
                        <Section_Title text={t("Old quests")} />
                        {draftQuests?.map((quest) => (
                            <Quest_Card 
                                key={quest.id} 
                                quest={quest} 
                                onClick={() => handleViewOptions(quest)}
                                kpisMetrics={kpisMetrics}
                                formLeads={formLeads}
                            />
                        ))}
                    </div>
                ) : (
                null
                )} */}
        </PageAlwaysVisible>

     : 
        <PageAlwaysVisible>
                    
                <PageTitle cta>
                    {/* <Grey_Link text={t("Back")} iconLeft="Back" noPadding /> */}
                    <h1>{t("Dashboard")}</h1>
                    {draftQuests?.length?
                        <Dropdown_Button
                            textMain={t('Quest')}
                            text1={t('Continue Quest')}
                            textLast={t('New Quest')}
                            icon1 = 'Edit'
                            iconLast = 'Add'
                            onClick1={()=> choice_no()}
                            onClickLast={() => check_for_drafts(quests, navigate, lang, setConfirmation)} 
                        />
                    : 
                    <Main_ text={t('New quest')} iconLeft='Add' onClick={() => check_for_drafts(quests, navigate, lang, setConfirmation)} /> 
                    }
                </PageTitle>
                {/* {draftQuests?.length ? (
                    <div>
                        <Section_Title text={t("Old quests")} />
                        {draftQuests?.map((quest) => (
                            <Quest_Card 
                                key={quest.id} 
                                quest={quest} 
                                onClick={() => handleViewOptions(quest)}
                                kpisMetrics={kpisMetrics}
                                formLeads={formLeads}
                            />
                        ))}
                    </div>
                ) : (
                null
                )} */}
                <Section_Title text={t("Active quests")} />
                    {activeQuests?.length > 0 ? 
                        activeQuests?.map((quest) => {
                            const problem = problems?.includes(quest._id);
                            const review = quests?.review?.includes(quest._id);

                            return (
                                <Quest_Card problem={problem} review={review} quest={quest} activeQuest={true} onClick={() => handleViewOptions(quest)} />
                            );
                        })
                        : <DashboardText>
                            <h6>{t("Currently, there are no ongoing quests.")}</h6>
                        </DashboardText> 
                    }
                {pausedQuests?.length ? (
                    <div>
                        <Section_Title text={t("Paused quests")} />
                        {pausedQuests?.map((quest) => {
                            const error = quests?.problems?.includes(quest._id);
                            return (
                                <Quest_Card 
                                    matchingKpis={quest?.matchingKpis}
                                    graphicalKpis={quest?.graphicalKpis}
                                    formLeads={formLeads} kpisMetrics={kpisMetrics} error={error} quest={quest} onClick={() => handleViewOptions(quest)} 
                                />
                            )
                        })}
                    </div>
                ) : (
                null
                )}
                {oldQuests?.length ? (
                    <div>
                        <Section_Title text={t("Old quests")} />
                        {oldQuests?.map((quest) => (
                            <Quest_Card formLeads={formLeads} kpisMetrics={kpisMetrics} key={quest.id} quest={quest} onClick={() => handleViewOptions(quest)} />
                        ))}
                    </div>
                ) : (
                null
                )}
        </PageAlwaysVisible>
     
    }
    {/* {isKnight ? 
        <PageAlwaysVisible>
            <PageDesktopPadding>
                <Dashboard_Notifications />
            </PageDesktopPadding>
        </PageAlwaysVisible> : null} */}
    {historyNotifications?.length ? 
        <PageAlwaysVisible>
            <PageDesktopPadding>
                <Dashboard_History_Notifications get_all_notifications={get_all_notifications} quests={quests?.quests || []} notifications={historyNotifications}/>
            </PageDesktopPadding>
        </PageAlwaysVisible>
    : null}
        
    {selectedQuest ? 
        windowWidth < 992 ? (
        <Slider_
            title = {t("Quest")}
            text = {t('Done')}
            onClick = {() => handleViewOptions(false)}
            onClose = {() => handleViewOptions(false)}
        >           
            {showTopUp ? 
                <Dashboard_Topup selectedQuest={selectedQuest} active={quests?.activeQuests?.includes(selectedQuest?._id)}/> 
            :        
                <Dashboard_Right setShowTopUp={setShowTopUp} selectedQuest={selectedQuest} active={quests?.activeQuests?.includes(selectedQuest?._id)}/>
            }
        </Slider_>
        ): (
            <PageDesktopOnly>
                <PageDesktopPadding>
                    <Dashboard_Right 
                        selectedQuest={selectedQuest} 
                        active={quests?.activeQuests?.includes(selectedQuest?._id)}
                    />
                </PageDesktopPadding>
            </PageDesktopOnly> 
        )
    :null}
   
</PageSixty>
  )
}