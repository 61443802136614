import { AlertContext, Alert_, Builder_, Checkbox_, Dropdown_, Edit_, Form, IconSvg, Input_, Main_, Slider, Slider_ } from 'monica-alexandria'
import React, { useContext, useState, useTransition } from 'react'
import styled from 'styled-components'
import { Landing_Form_Slider } from './Landing_Form_Slider'
import { use } from 'i18next'
import queryString from 'query-string';
import { useTranslation } from 'react-i18next'
import { LandingContext } from '../../context/LandingContext'
import { load_form } from '../../pages/landingView/utils'
import l from '../../services/api/Landings'
import { alertMessage } from '../../utils/messagesAlerts'

const LandingForm = styled.div`
    .Check{
        border: 2px solid var(--greyDark) !important;
    }
`

const LandingFormWrapper = styled(Form)`
    position: relative;
    margin-top: 4rem;
    background: var(--white) !important;
    padding: ${p => p.mobilePreview ? '2rem' : ''} !important;

    h5{
        color: var(--black);
        padding-bottom: 1rem;
    }
`

const FormWrapper = styled.div`
    pointer-events: ${p => p.disableForm ? 'none' : 'default'} !important;

    .FormInput{
        background: var(--white) !important;
        color: var(--black) !important;
        box-shadow: var(--insetShadow);
        font-size: ${p => p.mobilePreview ? '1rem' : ''} !important;
        padding: ${p => p.mobilePreview ? '1rem' : ''} !important;
    }

    .FormSelect{
        background: var(--white) !important;
        color: var(--black) !important;
        box-shadow: var(--insetShadow);
        font-size: ${p => p.mobilePreview ? '1rem' : ''} !important;
        padding: ${p => p.mobilePreview ? '1rem' : ''} !important;
        border : 1px solid var(--grey)
    }
    .Dropdown_IconAdapt {
        fill: var(--black) !important;
    }
    .LandingForm{
        margin-bottom: 1rem;
    }

    .Checkbox p {
        color: var(--black);
        font-size: ${p => p.mobilePreview ? '1rem' : ''} !important;
    }

    .Checkbox .Check {
        background: var(--white);
        box-shadow: var(--insetShadow);
        height: ${p => p.mobilePreview ? '1.5rem' : ''} !important;
        width: ${p => p.mobilePreview ? '1.5rem' : ''} !important;
    }
`

const FormButton = styled.div`
    padding-top: 2rem;
    .FormButton{
        width: 100%;
        /* background-color: ${(p)=> p.backgroundColor} !important; */
        /* border-color: ${(p)=> p.backgroundColor}; */
        display: flex;
        align-items: center;
        justify-content: center;

        /* opacity: ${(p)=> p.disabled ? '0.5' : '1'} !important;
        pointer-events:  ${(p)=> p.disabled ? 'none' : 'auto'} !important;; */

        span{
       
            /* color: ${(p)=> p.textColor}; */
        }
    }
`

const LandingFormEdit = styled.div`
    padding: 1rem; 
    position: absolute;
    top  : 1rem;
    right: 1rem;

  
`
 const FormSuccess = styled.div`
    border-left: 4px solid ${(p) => p.backgroundColor} !important;
    border-radius: 0.2rem;
    padding: 1rem 2rem;

h3{
    color: var(--black);
    padding-top: 2rem;
    margin-bottom: 0rem;
}

`


const FormSuccessIcon = styled.div`
    background-color: var(--grey);
    padding: 1rem;
    border-radius: 50%;
    width: fit-content;

    svg{
     
     width: 4rem;
     fill: ${(p)=> p.theme.text};
 }
`


const FormPrivacy = styled.div`
    padding-top: 1rem;
    p{
        color: var(--black);
    }

`

const FormQuestions = styled.div`
`


const FormCheckboxWrapper = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
    
    /* .formMultiple{
        box-shadow: var(--insetShadow);
    } */

`

export const Landing_Form = (props) => {

    const parsedQuery = queryString.parse(window.location.search);
    const id = parsedQuery.id;


    const [edit, toggleEdit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const messagesInitialState = {
        Tel: {
            errors: [],
            helpers: [],
            successes: [],
        },
        Email: {
            errors: [],
            helpers: [],
            successes: [],
        },
    }

    const {landing, setLanding} = useContext(LandingContext);
    const {alerts, setAlerts} = useContext(AlertContext);
    const [messages, setMessages] = useState(messagesInitialState);
    
    // const [customField, setCustomField] = useState(landing?.customFormField?.label);

    const {t} = useTranslation();
    
    const updateForm = (field, value, key) => {
        let newLanding = JSON.parse(JSON.stringify(landing));
    
        if (field === "CustomField") {
            if (!newLanding.form[field]) newLanding.form[field] = [];
            
            // Find existing field entry
            let existingFieldIndex = newLanding.form[field].findIndex(item => item.key === key);
            if (existingFieldIndex > -1) {
                newLanding.form[field][existingFieldIndex].value = value;
            } else {
                newLanding.form[field].push({ key, value });
            }
        } else {
            newLanding.form[field] = value;
        }
    
        if (field === 'Tel') validateTel(value);
        else if (field === 'Email') validateEmail(value);
    
        setLanding(newLanding);
    };
    
   

    const validateTel = (telNumber) => {
        let helpers = [];
        let successes = [];
    
        // Regular expression to match a valid telephone number format
        const telRegex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
        
        if (telNumber !== '') {
            if (!telRegex.test(telNumber)) {
                helpers.push("Please enter a valid telephone number");
            } else {
                successes.push("Telephone number is valid");
            }
        }

        setMessages(prevMessages => ({
            ...prevMessages,
            Tel: {
                ...prevMessages.Tel,
                helpers: helpers,
                successes: successes,
            }
        }));
    };
    
    const validateEmail = (email) => {
        let helpers = [];
        let successes = [];
    
        // Regular expression to match a valid email format
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,50}$/;
    
        if (email !== '') {
            if (!emailRegex.test(email)) {
                helpers.push(t("Correct email format email@domain.com"));
            } else {
                successes.push(t("Email address is valid."));
            }
        }
        
        setMessages(prevMessages => ({
            ...prevMessages,
            Email: {
                ...prevMessages.Email,
                helpers: helpers,
                successes: successes,
            }
        }));
    };
    

    // Function to clear form fields
    const clearFormFields = () => {
        let newLanding = JSON.parse(JSON.stringify(landing));
        // Reset all form fields to default or empty values
        newLanding.form = {};
        landing?.defaultFormFields?.forEach(field => {
            newLanding.form[field] = ''; // Set default or empty value for each default form field
        });
        // if (landing?.customFormField?.visibility) {
            newLanding.form['CustomField'] = ''; // Set default or empty value for custom form field if present
        // }
        setLanding(newLanding);
    }

  
    const checkForm = () => {
        const emailHelpers = messages?.Email?.helpers || [];
        const telHelpers = messages?.Tel?.helpers || [];
        
        if (emailHelpers.length || telHelpers.length ) {
            if (emailHelpers.length) {
                setMessages(prevMessages => ({
                    ...prevMessages,
                    Email: {
                        ...prevMessages.Email,
                        errors: emailHelpers,
                        helpers: []
                    }
                }));
            }
            if (telHelpers.length) {
                setMessages(prevMessages => ({
                    ...prevMessages,
                    Tel: {
                        ...prevMessages.Tel,
                        errors: telHelpers,
                        helpers: []
                    }
                }));
            }
       //     if (!landing?.form?.gdpr) alertMessage(alerts, setAlerts, (t('GDPR must be checked')), 'Error');
            clearFormFields();
        } else {
            submit_form();
            // clearFormFields();
        }
    }

        //checks default value input && sets context
        const handleCustomFieldChange = () => {
            // if (landing.customFormField.visibility && customField.trim() === '' && landing?.customFormField?.label ==='') {
            //     alertMessage(t('There are empty fields'), 'Error')
            // } else {
                // setLanding({
                //     ...landing,
                //         customFormField: {
                //         ...landing.customFormField,
                //         label: customField,
                //     },
                // })
                toggleEdit(false)
           // }
            };

    const submit_form = () => {
        
        let form = load_form(landing.form, id);
        // This detects if we are located at the View Landing Page
        // it prevents form submission on preview landing, create landing pages
    
        alertMessage(alerts, setAlerts, (t('Form is being submitted')), 'Loading');
        if (landing?.code == 'L2003') {
            l.submitForm(form)
            .then(res => {
                if (!res?.data?.code?.includes('L4')) {
                    alertMessage(alerts, setAlerts, (t('Form submitted successfully')), 'Success');
                    clearFormFields();
                    setIsSubmitting(true);
                    setMessages(messagesInitialState);
                } else {
                    if (res?.data?.code === 'L4000-1') alertMessage(alerts, setAlerts, (t('Name must not be empty')), 'Error');
                    else if ((landing?.defaultFormFields?.includes('Tel') && landing?.defaultFormFields?.includes('Email')) && res?.data?.code === 'L4000-3') alertMessage(alerts, setAlerts, (t('At least one contact field must be filled')), 'Error');
                    else if (res?.data?.code === 'L4000-3') alertMessage(alerts, setAlerts, (t('Contact field must not be empty')), 'Error');
                    else if (res?.data?.code === 'L4000-4' ) alertMessage(alerts, setAlerts, (t('GDPR must be checked')), 'Error');
                    else if (res?.data?.code === 'L4004') alertMessage(alerts, setAlerts, (t('You have already submitted this form')), 'Error');
                    setIsSubmitting(false);
                }
            })
        }
        else 
        alertMessage(alerts, setAlerts, (t('There was an error. Please try again later')), 'Error');
      }
      
      console.log('landing !!!!!!!!!!!!', landing);

      
  return (
    <LandingForm>
        {/* {alerts && 
            <Alert_ messages={alerts} duration={2000}/>
        } */}
        {edit ? 
            <Slider_
                text={t("Done")}
                grey={t("Cancel")}
                onClose={() =>handleCustomFieldChange()}
                title={t("Edit form")}
                onClick={() =>handleCustomFieldChange()}    
            >
                <Landing_Form_Slider
                //  customField={customField} 
                //  setCustomField={setCustomField} 
                 toggleEdit={toggleEdit} 
                 />
            </Slider_>
        : null }
        <LandingFormWrapper mobilePreview={props.mobilePreview}>
            <h5>{t('Contact information')}</h5>
            {!isSubmitting ? 
            <FormWrapper disableForm={props.disableForm} mobilePreview={props.mobilePreview}>
                {landing?.defaultFormFields.includes('Name') && 
                    <Input_ 
                        placeholder={t('Name')}
                        className='LandingForm' 
                        value={landing?.form ? landing?.form.Name : ''} 
                        onChange={e => updateForm('Name', e.target.value, false)} 
                        required
                    />
                }
                {landing?.defaultFormFields.includes('Surname') && 
                    <Input_ 
                        placeholder={t('Surname')}
                        className='LandingForm' 
                        value={landing?.form ? landing?.form.Surname : ''} 
                        onChange={e => updateForm('Surname', e.target.value, false)} 
                        required
                    />
                }
                  {landing?.defaultFormFields.includes('FullName') && 
                    <Input_ 
                        placeholder={t('Full name')}
                        className='LandingForm' 
                        value={landing?.form ? landing?.form.FullName : ''} 
                        onChange={e => updateForm('FullName', e.target.value, false)} 
                        required
                    />
                }
                {landing?.defaultFormFields.includes('City') && !props?.specialAnswer && 
                    <Input_ 
                        placeholder={t('City')}
                        className='LandingForm' 
                        value={landing?.form ? landing?.form.City : ''} 
                        onChange={e => updateForm('City', e.target.value, false)} 
                        required
                    />
                }
                {landing?.defaultFormFields.includes('Email') && 
                    <Input_ 
                        placeholder={t('Email')}
                        className='LandingForm' 
                        value={landing?.form ? landing?.form.Email : ''} 
                        onChange={e => updateForm('Email', e.target.value, false)} 
                        helpers={messages?.Email?.helpers}
                        successes={messages?.Email?.successes}
                        errors={messages?.Email?.errors}
                        required
                    />
                }
                    {/* <h5>{field?.title}</h5>
                    <Input_ 
                        required 
                        placeholder={landing?.customFields[0]?.title} 
                        value={landing?.form ? landing?.form?.CustomField : '' } 
                        className='LandingForm' 
                        //onChange={e => updateForm('CustomField', e.target.value)} 
                    />  */}


                  {landing?.defaultFormFields.includes('Tel') && 
                    <Input_ 
                        placeholder={t('Phone number')}
                        className='LandingForm' 
                        value={landing?.form ? landing?.form.Tel: ''} 
                        onChange={e => updateForm('Tel', e.target.value, false)} 
                        helpers={messages?.Tel?.helpers}
                        successes={messages?.Tel?.successes}
                        errors={messages?.Tel?.errors}
                        required
                    />
                }

                
                {/* {landing?.customFormField?.visibility ?  */}
                {/* {props.oldLanding && landing?.customFormField?.label ? 
                    <Input_ placeholder={landing?.customFormField?.label} value={landing?.form ? landing?.form?.CustomField : '' } className='LandingForm' onChange={e => updateForm('CustomField', e.target.value)} />
                : null}
                { props.landingNew && landing?.customFormField?.label?
                    <Input_ required placeholder={landing?.customFormField?.label} value={landing?.form ? landing?.form?.CustomField : '' } className='LandingForm' onChange={e => updateForm('CustomField', e.target.value)} />

                 : null} */}
                    {/* :null} */}
                {/* <Checkbox_
                    id="id1523"
                    checked={landing?.form?.gdpr}
                    onClick={()=>  updateForm('gdpr', !landing?.form?.gdpr)}
                    text={t("By accessing and using our app, you acknowledge that you have read and agree to our Privacy Policy GDPR and Terms and Conditions of this site.")}
                /> */}
           
              
                
            {landing?.customFormField?.map((field, i)=>(
                field.type === 'input' ?
                    <FormQuestions>
                        <Input_ 
                            required 
                            placeholder={field?.label} 
                            className='LandingForm' 
                            value={landing?.form ? landing?.form?.CustomField?.value : '' } 
                            onChange={e => updateForm('CustomField', e.target.value, field?.label)}
                        />  
                    </FormQuestions>
                    :
                    <FormCheckboxWrapper>
                            <Dropdown_>
                                <select className='FormSelect' 
                                    value={landing?.form?.CustomField?.find(f => f.key === field.label)?.value || 'none'}                                
                                    onChange={e => updateForm('CustomField', e.target.value, field?.label)}>       
                                <option value={'none'} disabled> {field?.label}</option>
                                {field?.choices?.map((choice, i) => (
                                                    <option
                                                        key={i}
                                                        value={choice}
                                                    >
                                                        {choice}
                                                    </option>                                    
                                                ))}
                                </select>
                                </Dropdown_>

                    </FormCheckboxWrapper>
                ))}
                <FormPrivacy>
                    <p>By clicking Submit, you agree to send your info to Doitforme.eu who agrees to use it according to their privacy policy. Facebook will also use it subject to our Data Policy, including to auto-fill forms for ads. View Facebook Data Policy. Privacy Policy</p>
                </FormPrivacy>
                <FormButton backgroundColor = {landing?.colors?.background} textColor = {landing?.colors?.text}>
                    <Main_  className='FormButton' text={t('Submit')} size='large' onClick={() => checkForm()}/>
                </FormButton>
            </FormWrapper>
            :
            <FormSuccess  backgroundColor = {landing?.colors?.background}>
                <FormSuccessIcon>
                    <IconSvg Icon='Check'/>   
                </FormSuccessIcon>
                <h3>{t('Form submitted successfully')}</h3>
            </FormSuccess>
            }
            {!props.viewMode &&  
                <LandingFormEdit>
                    <Edit_ icon='Edit' onClick = {()=> toggleEdit(true)}/>
                </LandingFormEdit>
            }
        </LandingFormWrapper>
    </LandingForm>
  )
}
