import axios from "axios"
import { config } from "../../config"
import store from "../../redux/store"


const protocol = process.env.REACT_APP_MINIVERSE_BACKEND_PROTOCOL;
const url = process.env.REACT_APP_MINIVERSE_BACKEND_HOST;
const port = process.env.REACT_APP_MINIVERSE_BACKEND_PORT;

const getMyWebsites = () => {
    return new Promise((resolve, reject) => {
        console.log('tsifsa');
        
        axios.get(`${protocol}${url}${port}/websites/getMyWebsites`)
        .then(res => {
            console.log('[getMyWebsites] >',res.data);
            resolve(res);
        })
        .catch(() => {
            console.error('[Error | getMyWebsites] > ')
        })
    })
}


const miniEndpoints =  {
    getMyWebsites
};

export default miniEndpoints;