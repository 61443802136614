import { AlertContext, Builder_, Input_, Textarea_, Upload_Media } from 'monica-alexandria'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { alertMessage } from '../../utils/messagesAlerts'

const CreateAdServiceOnlyFields = styled.div``

export const Create_Ad_Service_Only_Fields = ({adInfo, setAdInfo,emptyFields, setEmptyFields,mediaErrors, setMediaErrors}) => {
    const {t} = useTranslation();
    const {alerts, setAlerts} = useContext(AlertContext);
    // const [mediaErrors, setMediaErrors] = useState([]);


    const upload_pic = async (files) => {
        if (files?.length && files[0]?.preview) {
            const isVideo = (files[0].type == 'video/mp4' || files[0].type == 'video/mpeg' || files[0].type == 'video/webm');
            setAdInfo({...adInfo, media: `${files[0]?.name}`, file: files[0] , isVideo:isVideo })  
            setEmptyFields(prevFields => prevFields.filter(field => field !== 'Media'));
            setMediaErrors([])
        }   
    };
    
    const delete_pic =  () => {
        setAdInfo({...adInfo, media: '', file: {}, isVideo:false})  
    };


    const handleBoomRejected = (type) => {

        console.log('TYPe', type);
        let reason = '';
        switch (type) {
          
            case 'fileMimetype': {
                reason = (t('File must be png, jpeg, mp4, mpeg, webm'));
                break;
            }
            case 'fileSize': {
                reason = (t('File must be less than 100MB'));
                break;
            }
        }
        setMediaErrors( [(t('Could not upload image'))+": "+reason])
        alertMessage(alerts, setAlerts, (t('Upload failed')),'Error');
    };


  return (
    <CreateAdServiceOnlyFields>
           <Builder_ title = {t('Write a short headline')}>
            <Textarea_
                required
                valueLength = {adInfo?.title?.length}
                label={t('Headline')}
                limit={50}
                rows="1"
                value = {adInfo.title}
                errors = {emptyFields?.includes('Headline') ? ['Headline must not be empty'] : []}
                placeholder = {t('Headline')}
                onChange = {(e) => {setAdInfo({...adInfo, title: e.target.value })
                    setEmptyFields(prevFields => prevFields.filter(field => field !== 'Headline'));
                    if (adInfo?.title?.length === 50)  alertMessage(alerts, setAlerts, (t('Headline limit has been reached')),'Error'); 
                }}
                tips={[
                    (t("Briefly describe the action you want your prospective customer to take e.g. 'Unique offers' ")),
                ]}
            />
        </Builder_> 
    
        <Builder_>
            <Upload_Media
                title={t("Upload photo or video")}
                upload={t("Upload")}
                getFilesFromChild={(files) => {upload_pic(files)}}
                filename={adInfo?.media || adInfo?.visualElement}
                errors = {mediaErrors?.length ? mediaErrors : emptyFields?.includes('Media') ? ['Media must not be empty'] :  []}
                // emptyFields?.includes('Media') ? ['Media must not be empty'] : []}
                onDelete={() => delete_pic()}
                delete={t("Delete")}
              //  uploaded={adInfo?.media || adInfo?.visualElement ? true : false}
                uploaded={adInfo?.media ? true : false}
                boomRejected={(type) => handleBoomRejected(type)}
                helpers={[(t('Acceptable files png, jpg, jpeg, mp4, mpeg, webm less than 100mb'))]}
                maxFiles="1"
                maxSize="105000000"
                type ='media'
            />
        </Builder_>
    </CreateAdServiceOnlyFields>
  )
}
