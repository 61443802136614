import React from 'react'

import { Button, device, Focus_, Focus_Plain, Grey_, Grey_Link, IconSvg, Main_, Main_Plain, Red_ } from 'monica-alexandria'

import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Notification = styled.div`
`

const NotificationPopupWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    background: ${p => p.theme.overlay};
    padding: 4rem 1rem;
`

const NotificationBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4999;
    background: ${p => p.theme.overlay};
`
const NotificationPopup = styled.div`
    position: relative;
    width: 100%;
    max-width: 50rem;
    z-index: 5001;
    border-radius: var(--normalRadius);
    background: ${p => p.theme.background};
    margin: 0 auto;


    /* padding: 1rem 2rem; */
`

const NotificationPopupHeader = styled.div`
    padding: 1rem;
    display: flex;
    justify-content:right;
    align-items: center;
    
`

const NotificationTitle = styled.div`
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    gap:1rem;
    
`

const NotificationGrid = styled.div`
    /* display: grid;
    grid-template-columns:1fr 40%; */
    height: 100%;
    align-items: start;
    grid-gap: 2rem; 
    /* @media ${device.lg}{
        display: flex;
        flex-direction: column; 
    } */
     
`
const VideoPlayer = styled.div`
  
    height: 100%;
    max-height: 70rem;
    width: 100%;

    @media ${device.md}{
        
        position: relative;
        min-height: 35rem;
        width: 100%;
    }
     
`

const ImagePlaceholder = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(180deg, ${(p)=>p.domColor} 0%, ${(p)=> p.theme.high} 50%, ${(p)=> p.theme.high} 100%);  
    padding: 0 4rem;
    img{
        width: 100%;
    }
`

const NotificationInstructions = styled.div`
    padding: 0 2rem 2rem 2rem;
    ul{
        padding-left: 1rem;
    }
    h3{
        margin: 2rem 0;
    }

    p{
        margin-bottom: 1rem;
    }
    
`



const VideoClosePopup = styled.div`
    position: absolute;
    top: -2rem;
    right: 0rem;
    z-index: 5003;
    @media ${device.lg}{
        right: 10%;
    }
    @media ${device.md}{
        right: 5%;
    }
`

const NotificationLabel = styled.div`
    padding: var(--normalPads);
    border-radius: var(--smallRadius);
    background: var(--focusLight);
`
const NotificationActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem 1rem 2rem;
    `

export default function Notification_(props) {
    const { t } = useTranslation();

  return (
    <Notification>    
    <NotificationPopupWrapper>
        <NotificationBackground/>
        <NotificationPopup>
            <NotificationPopupHeader>
                {/* <Focus_ text={props.cta} /> */}
             
                <Grey_ size='small' iconLeft="Xicon" onClick={() => props.onClose()} />
            </NotificationPopupHeader>
            <NotificationGrid>
                <ImagePlaceholder>
                    <img src='https://genius1071.friktoriaservers.net/doitforme/morning/app/dailyprofit/Mini3.svg' />
                </ImagePlaceholder>
                <NotificationInstructions>
                    <NotificationTitle>
                        <NotificationLabel><h5>{t('New')}</h5></NotificationLabel> 
                        <h3>{t("Extra Exposure & Free Promotion via Doitforme!")}</h3>
                    </NotificationTitle>
                    <p>{t("We have created a completely FREE Mini Website for you to enhance your online presence!.")}</p>
                    <p>This means we will showcase your business through our network in Google searches, making it easier for customers to find you.</p>
                    <h5>{t("What’s next?")}</h5>
                    <ul>
                        <li><p>{t("Enhance your page with more details and high-quality photos.")}</p></li>
                        <li><p>{t("Double-check that all your business information is accurate and complete.")}</p></li>
                    </ul>
                    <p>{t('Make the most of your new page and start attracting more customers today!')}</p>
                </NotificationInstructions>
            </NotificationGrid>
            <NotificationActions>
                <Grey_ text={t('Edit')} iconLeft='Edit' onClick={props.onClose}/>
                <Main_  text={t("Close")} iconRight='Check' onClick={props.onMain}/>
            </NotificationActions>
        </NotificationPopup>
    </NotificationPopupWrapper>
</Notification>
  )
}
