import { Button, device, Focus_Plain, Grey_, Grey_Link, IconSvg, Main_, Main_Plain, Red_ } from 'monica-alexandria'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const VideoHelper = styled.div`
  
`

const VideoHelperPopupWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    width: 100%;
    min-height: 100vh;
    background: ${p => p.theme.overlay};
`


const VideoHelperBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4999;
`
const VideoHelperPopup = styled.div`
    max-height: calc(100vh - 10rem); 
    /* min-height: 50rem; */
    height: 100%;
    position: fixed;
    top: 5rem;
    left: 50%;
    width: calc(100vw - 60rem);
    transform: translateX(-50%);
    z-index: 5001;
    border-radius: var(--normalRadius);
    background: ${p => p.theme.background};

    @media ${device.xl}{
       
        width: calc(100vw - 20rem);
      

    }
    @media ${device.md}{
        left: 0;
        width: calc(100vw - 4rem);
        transform: translateX(5%);
        overflow-y: auto;

    }
`

const VideoHelperGrid = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: start;
    grid-gap: 2rem; 
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    @media ${device.md}{
        display: flex;
        flex-direction: column-reverse; 
    }
     
`
const VideoPlayer = styled.div`
    position: sticky ;
    top: 0;
    max-height: calc(100vh - 10rem);
    height: 100%;
    border-top-right-radius: var(--normalRadius);
    border-bottom-right-radius: var(--normalRadius);
    overflow: hidden;

    @media ${device.md}{
        position: relative;
        min-height: 35rem;
        width: 100%;
    }
     
`

const VideoHelperInstructions = styled.div`
    padding: 0 2rem;
       h2{
            margin: 2rem 0;
        }
    
     
        h5{
            margin-bottom: 1rem;
        }

        p{
            margin-bottom: 1rem;
        }

        h6{
            margin-bottom: 1rem;
        }

        ul{
            margin-left: 2rem;

            li{
                display: flex;
                align-items: center;
                gap: 1rem;

                svg{
                    width: .7rem;
                    margin-bottom: 1rem;
                    fill: ${p => p.theme.color};
                }
            }
        }

        a{
            color: var(--main);
            cursor: pointer;

            &:hover{
                color: var(--mainLight);
                transition: color .5s;
            }
        }


    
`
const VideoHelperInstructionsTitle = styled.div`

    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    padding-right: 0.5rem;
    cursor: pointer;
    padding-bottom: .5rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
    border-bottom: 1px solid var(--grey);
    z-index: 5009;

    
    
    h4{
        font-family: "fontLight", sans-serif !important;
        letter-spacing: 1px !important;
        color: var(--greyDark) !important;
        
        &:first-of-type{
            margin-top: 0 !important; 
        }
    }
    

    &:hover{
        cursor: pointer;
    }
  

`

const VideoHelperInstructionsTitleInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &:hover{
        cursor: pointer;
    }
   
    .rotate{  
        width: 1rem;
        fill: var(--greyDark);
        display: inline-block;
        transform: ${({ isOpen }) => (isOpen ? 'rotate(-90deg)' : 'rotate(0deg)')};
        transition: transform 0.3s ease;  
    }

`

const VideoClosePopup = styled.div`
    position: absolute;
    top: -4rem;
    right: 0rem;
    z-index: 5003;
    @media ${device.lg}{
        right: 10%;
    }
    @media ${device.md}{
        right: 5%;
    }
`


const VideoHelperList = styled.ul`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};  
`;


const VideoHelperForm = styled.div`
    border: 3px dotted ${p => p.theme.background};
    background: ${p => p.theme.mid};
    padding: var(--normalPads);
    border-radius: 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 2rem;
`

const VideoHelperRight = styled.div`
    h5{
        color: var(--greyDark);
        margin-bottom: 1rem;
    }

    button{

        svg{
            width: 2rem;
            margin-bottom: 5px;
            fill: var(--warning);
        }
    }
`



const VideoHelperLeft = styled.div`
        width: 3rem;
        fill: var(--warning);
`

const VideoMainBtn = styled.div`
    display: flex;
    align-items: flex-start;
    gap:1rem;
`

    export const Video_Helper = (props) => {
        const { t } = useTranslation();
        
        // Set the first section to be open initially
        const [openSections, setOpenSections] = useState(() => {
            if (props.mainVideo) return Array(props.sections?.length).fill(false)
            else return Array(props.sections.length)?.fill(false).map((_, i) => i === 0);
        });
        
        const [instructions, toggleInstructions] = useState(false);
    
        // Consolidated state for playing video
        const [videoState, setVideoState] = useState({
            playingSection: props.mainVideo? 'main' : 0, // Default to the first section
            isPlaying: false, // Start with playing the first video
            currentVideoUrl:  props.mainVideo? props.mainVideo : props.sections[0]?.videoUrl // Default to the first video URL
        });
    

        // Toggle the visibility of a specific section by index
        const toggleSection = (index) => {
        setOpenSections((prevOpenSections) =>
            prevOpenSections?.map((isOpen, i) => (i === index ? !isOpen : isOpen))
        );
        };
    
        // Toggle the play state for a specific section
        const togglePlay = (index, url) => {
        setVideoState((prevState) => {
            if (prevState.playingSection === index && index!== 'main') {
            // If the same section, just toggle play/pause
            return { ...prevState, isPlaying: !prevState.isPlaying };
            } else {
            // If a different section, set the new URL and play it
            return {
                playingSection: index,
                isPlaying: prevState?.playingSection === index ? !prevState.isPlaying : true ,
                currentVideoUrl: url,
            };
            }
        });
        };
    
        // Handle video pause and play events from the ReactPlayer
        const handlePause = () => {
            setVideoState((prevState) => ({ ...prevState, isPlaying: false }));
        };
    
        const handlePlay = () => {
            setVideoState((prevState) => ({ ...prevState, isPlaying: true }));
        };

        console.log('videoState', videoState);
        
    
        return (
        <VideoHelper>
            <VideoHelperForm>
            {!props.textBtn ? (
                <VideoHelperLeft>
                <IconSvg Icon="Tip" />
                </VideoHelperLeft>
            ) : null}
            <VideoHelperRight>
                <h5>{props.text}</h5>
                {props.textBtn ? (
                <Main_Plain noPadding iconLeft="Tip" text={props.textBtn} onClick={() => toggleInstructions(!instructions)} />
                ) : null}
            </VideoHelperRight>
            </VideoHelperForm>
    
            {instructions ? (
    
                <VideoHelperPopupWrapper>
                    <VideoHelperBackground onClick={() => toggleInstructions(false)} />
                    <VideoHelperPopup>
                        <VideoClosePopup>
                            <Grey_ size='small' iconLeft="Xicon" text={t("Close")} onClick={() => toggleInstructions(false)} />
                        </VideoClosePopup>
                        <VideoHelperGrid>
                            <VideoHelperInstructions>
                                <h2>{props.title}</h2>
                            
                                {props.mainVideo ? 
                                <VideoMainBtn>
                                    {videoState.playingSection === 'main' ?
                                        <Main_ iconLeft={videoState.isPlaying ? 'Pause': 'Play'}  size='small' onClick={() => togglePlay('main', props.mainVideo)}/>
                                        : <Grey_ iconLeft='Play' size='small' onClick={() => togglePlay('main', props.mainVideo)} /> }
                                        <h4>{props.mainVideoTitle}</h4>
                                    </VideoMainBtn>
                                : null}
                                {props.sections?.map((section, index) => (
                                    <div key={index}>
                                        <VideoHelperInstructionsTitle>
                                            { section?.videoUrl? 
                                            videoState.playingSection === index ?
                                            <Main_ iconLeft={videoState.isPlaying ? 'Pause': 'Play'} size='small' onClick={() => togglePlay(index, section.videoUrl)}/>
                                            : <Grey_ iconLeft='Play' size='small' onClick={() => togglePlay(index, section.videoUrl)} /> 
                                            : <div></div> }
                                            <VideoHelperInstructionsTitleInner  
                                                isOpen={openSections[index]}
                                                onClick={() => toggleSection(index)}>
                                            
                                                <h4>{section.title}</h4>
                                                {section.icon && <IconSvg Icon={section.icon} class_Icon='rotate' />}
                                            </VideoHelperInstructionsTitleInner>
                                        </VideoHelperInstructionsTitle>
                                        <VideoHelperList isVisible={openSections[index]}>
                                    
                                        {section?.content?.map((item, i) => (
                                            <li key={i}>{item}</li>
                                        ))}
                                        </VideoHelperList>
                                    </div>
                                ))}
                            </VideoHelperInstructions>
                            <VideoPlayer>
                                {videoState.currentVideoUrl && (
                                <ReactPlayer
                                    url={videoState.currentVideoUrl}
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                    playing={videoState.isPlaying}
                                    onPause={handlePause}
                                    onPlay={handlePlay}
                                />
                                )}

                            </VideoPlayer>
                        </VideoHelperGrid>
                    </VideoHelperPopup>
                </VideoHelperPopupWrapper>
            
            ) : null}
        </VideoHelper>
        );
    };