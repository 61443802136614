import { IconSvg, Slider_ } from 'monica-alexandria';
import React from 'react'
import { useTranslation } from 'react-i18next'
import Dashboard_Edit from '../edit/Dashboard_Edit';
import FbLogo from "../../../../assets/dashboard/FbLogo.png";
import NoImg from "../../../../assets/ad/NoImgAd.jpg";
import { findLabelByValue } from '../../../../utils/findCtaLabel';
import store from '../../../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { campaign_of_type_instant_form_exists } from '../../../editLanding/utils';
import styled from 'styled-components';


const minioUrl =
  process.env.REACT_APP_MINIO_PROTOCOL +
  process.env.REACT_APP_MINIO_HOST +
  process.env.REACT_APP_MINIO_PORT +
  "/" +
  process.env.REACT_APP_MINIO_BUCKET +
  "/";

  const ArchivedTip = styled.div`
    display: flex;
    align-items: center;
    gap: .5rem;
    background: transparent;
    padding: .5rem 1rem;

    svg{
      fill: ${(p)=>p.theme.color};
      width: 1.6rem;
    }
 
`
export default function Archived_Old_Preview(props) {
    const {t} = useTranslation();
    const navigate= useNavigate();
    const {lang} = useParams();

    console.log('selectedArchived', props.selectedQuest);
    

    const permissionToEdit = store.getState()?.user?.user?.roles.includes("Squire") ? false: true;

    const get_ad_image = () => {

        if (props.selectedQuest?.Meta?.mode ==='productCatalog' && props.selectedQuest?.Meta?.productSetImg) {
          return props.selectedQuest?.Meta?.productSetImg;
        }
    
        if (
          props.selectedQuest?.adInfo?.thumbnail &&
          props.selectedQuest?.adInfo?.thumbnail !== "default.jpg"
        ) {
          return props.selectedQuest?.adInfo?.thumbnail;
        }
    
     
        if (props.selectedQuest?.Meta?.mode !=='productCatalog' && props.selectedQuest?.adInfo?.visualElement) {
          return `${minioUrl}${props.selectedQuest?.userId}/${props.selectedQuest?.adInfo?.visualElement}`;
        }
    
        return NoImg;
    
      };
    
      const getLandingCustom = (customField) => {
        if (!customField?.length && customField?.label === '') {
          return [];
        }
        if (!customField?.length) {
          return [{ ...customField, type: 'input' }];
        }
        return customField;
      };
      
   
  return (
    <Slider_
        title={t("Preview archived ad")}
        text = {t("Done")}
        onClose = {() => props.onClose()}
        onClick = {() => props.onClose()}
    > 
        <ArchivedTip>
            <IconSvg Icon="Tip" />
            <p>{t('Ad edits are only available when the ad is active.')}</p>
        </ArchivedTip>
        <Dashboard_Edit 
            type={props.selectedQuest?.questionnaires[0]?.answer}
            // onEditPauseAds={props.onEditPauseAds}
            adAvatar={FbLogo}
            adPage={props.selectedQuest?.Meta?.facebookPageName}
            adCaption={props.selectedQuest?.adInfo?.caption}
            adImg={get_ad_image()}
            adTitle={props.selectedQuest?.adInfo?.title}
            adUrl={props.selectedQuest?.eShopUrl}
            adCta={findLabelByValue(props.selectedQuest?.adInfo?.cta,t)}
            landingCustom={getLandingCustom(props.selectedQuest?.landingInfo?.customFormField)}
            landingImg={campaign_of_type_instant_form_exists(props.selectedQuest?.campaigns) ? get_ad_image() :`${minioUrl}${props.selectedQuest?.userId}/${props.selectedQuest?.landingInfo?.image}`}
            landingHero={props.selectedQuest?.landingInfo?.title}
            landingSub={props.selectedQuest?.landingInfo?.subtitle}
            landingFields={props.selectedQuest?.landingInfo?.defaultFormFields}
            questLocations={props.selectedQuest?.questionnaires[2]?.answer}
            questInterests={props.selectedQuest?.questionnaires[3]?.answer}
            questMinAge={props.selectedQuest?.questionnaires[4].answer?.minAge}
            questMaxAge={props.selectedQuest?.questionnaires[4].answer?.maxAge}
            questGender={props.selectedQuest?.questionnaires[4].answer?.gender}
            page={props.selectedQuest?.Meta?.facebookPageName}
            pagePicture={props.selectedQuest?.Meta?.facebookPagePicture}
        />
    </Slider_>
  )
}
