import { AlertContext, Builder_, Input_, Steps_Actions,Main_Loader, Main_Plain, Slider_, Grey_Link, Toggle_Stat } from 'monica-alexandria';
import React, { startTransition, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { StepContext } from '../../context/StepContext';
import { useTranslation } from 'react-i18next';
import { push_new_answer, renderStepDescription } from '../../pages/questionnaireB/utils';
import store from '../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from '../../localization/i18next'
import q from '../../services/api/Quests'
import m from '../../services/api/Meta'
import { StepsTitle } from '../../styles/Layout';
import { Home_Pricing } from '../../pages/homeOld/components/Home_Pricing';

const MonthlyBudget = styled.div`

    .Slider{

        h3{
            margin: 0;
        }
    }

    .disabledInput{
        opacity: 0.6;
    }
`

const BudgetFee = styled.div`
    border-left: 4px solid var(--focus);
    border-radius: 0.2rem;
    background: ${(p)=> p.theme.mid};
    padding: 1rem;


    h3{
        margin-bottom: 0;
    }
   
`
const BudgetFeeCommission = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
 
/* 
    h1{
        font-size: 3.5rem;
    } */
`


const BudgetFeeInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;

`

const BudgetFeeInfo = styled.div`

    /* p{
        font-size: 1.3rem;
    } */
`

const EshopConfigurePixelHelper = styled.div`
    border: 3px dotted ${p => p.theme.background};
    background: ${p => p.theme.mid};
    padding: var(--normalPads);
    border-radius: 1rem;

    h5{
        color: var(--greyDark);
        margin-bottom: 1rem;
    }

    svg{
        width: 2rem;
        margin-bottom: 5px;
        fill: var(--warning);
    }
`


const NetworkConsent = styled.div`
    border: 3px dotted ${p => p.theme.background};
    margin-top: 1rem;
    background: ${p => p.theme.mid};
    padding: var(--normalPads);
    border-radius: 1rem;

    opacity: ${(p)=> p.consent ? 1 : 0.6};

    li{
        /* list-style-type: disc; */
        margin-left: 1rem;
        list-style-type: circle;
    }
    li::marker {
        color:  var(--main);
        }
    h3{
        /* color: var(--focus  ); */
        margin-bottom: 1rem;
    }
    p,h5{
        padding-bottom: 1rem;
    }
    p{
        font-size: 1.3rem;
    }
`
const MonthlyBudgetConverter = styled.div`

`

const CommissionFee = styled.div`
    padding-top: 4rem;
`
const lang = i18n.language ? i18n.language : 'el';

export default function Monthly_Budget(props) {
    const { alerts, setAlerts } = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [instructions, toggleInstructions] = useState(false);
    const [converter, setConverter] = useState("");

    const user = store.getState()?.user?.user;
    const isKnight = user?.roles?.includes('Knight');

    const [commission, setCommission] = useState({percentage:'', type:'', fee:''});
    const [consent,setConsent] = useState(true)
    const {questId} = useParams();
    const { t } = useTranslation();
    const { step, setStep } = useContext(StepContext);
    const navigate = useNavigate();

    const [messages, setMessages] = useState({
        errors: [], 
        helpers: [],
        successes: [],
    });

    const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
        question: (t(`${props.Q4.question}`)),
        type: `${props.Q4.type}`,
        answer: `${props.Q4.answer}`,
        consent: props.Q4.consent ?? true
    })

    console.log('props.Q4.consent', props.Q4.consent);
    

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };
    
    const handleInputChange = (e) => {
        // Check if the entered value is a valid number (float or integer)

        let helpers = [];
        let successes = [];

        const inputValue = e.target.value; 

        if (inputValue != '') {
            if(inputValue < 50 && !props?.editQB)  helpers.push(t('Minimum budget is 50€/month')) 
            else if (inputValue >= 50) successes.push(t('Minimum budget is 50€/month'))

            if (!Number.isInteger(Number(inputValue))) helpers.push(t('Do not include decimals')) 
            else successes.push(t('Do not include decimals'))

            if(inputValue > 10000 )  helpers.push(t('Maximum budget is 10000€/month')) 
            else if (inputValue <= 10000) successes.push(t('Maximum budget is 10000€/month'))

      
           
        }
            setStepInfo({ ...stepInfo, answer: inputValue.trim() });
    
            setMessages({...messages, helpers: helpers, successes: successes})
        
      };
    
   
      
    const submit_data_and_exit_questionnaire = () => {
        console.log('');
     
        let budgeterrors = [];  

        if (messages.helpers?.length !== 0) budgeterrors = messages.helpers;

        if (stepInfo.answer === '')  alertMessage(t('Montly budget must not be empty.'), 'Error')
        else if ((stepInfo.answer < 50 || !Number.isInteger(Number(stepInfo.answer))) && !props?.editQB ) {

            // alertMessage(t('Monthly budget must be greater than 40€.'), 'Error') 
            setMessages({ ...messages, errors: budgeterrors });
            setStepInfo({...stepInfo, answer: ''})
        } else {
            setStep({step: step?.step + 1, answers: push_new_answer(step, stepInfo)})
            
            let questionnaireA = props.questionnairea ? props?.questionnairea : store.getState().answers;
            let questionnaireB = push_new_answer(step, stepInfo);

            console.log('questionnaireBSUBMIT', questionnaireB);
            // let combined_questionnaires = [...questionnaireA, ...questionnaireB];

            let combined_questionnaires = [...questionnaireA, ...questionnaireB];

            // Then, update the specific question by position
            // combined_questionnaires = combined_questionnaires.map((q, index) => {
            //     if (index === combined_questionnaires.length - 1) {  // Modify based on the correct position
            //         return { ...q, consent: consent };
            //     }
            //     return q;
            // });
            console.log('combined questionnaires', combined_questionnaires)
            console.log(' props?.business_id', props?.business_id)
            console.log(' props?.ad_account_id',props?.ad_account_id)


            

            props.setLoading(true)
            q.editQuest(combined_questionnaires, props?.questId, false, props?.business_id, props?.ad_account_id,consent)
            .then(res => {

                console.error('adfsffsf EDW', res)
                if (res?.data?.code == 'C2004') {
                    alertMessage(t('You do not have access to this ad account'), 'Error');
                    navigate(`/${lang}/ad-accounts`)
                    return;

                }
                        
                store.dispatch({
                    type: 'SAVE_ACCOUNT_SELECTION',
                    payload: {
                        business_id: props?.business_id,
                        ad_account_id: props?.ad_account_id
                    },
                });
                console.log('Qustionnaire combined', combined_questionnaires[0]?.answer)
                //props.editQB is passed as true if we are editting quest/aire b from dashboard
                if (!props.editQB && combined_questionnaires[0]?.answer == 'eshop') navigate (`/${lang}/configure-eshop`)
                else if (!props.editQB && ['Q2003', 'Q2005'].includes(res?.data?.code)) navigate(`/${lang}/ad-helper`) 
                else if (props.editQB && ['Q2003', 'Q2005'].includes(res?.data?.code)) {
                    m.editAdDemographics(questId)
                      .then(resQ => {
                          if (resQ?.data?.code == 'C2008') {
                            console.log(resQ.data.code)
                            //alertMessage(res?.data?.msg, 'Success')
                            navigate(`/${lang}/dashboard`)
                          }
                          else {
                            setStep({...step, step: step?.step - 1})
                            alertMessage(resQ?.data?.msg, 'Error')
                          }
                          props.setLoading(false)
                      })
                }
            })
            .catch(err => {
                console.log(err)
                props.setLoading(false)
            })
        } 
    }

    useEffect(() => {    

        let commission;

        // if (stepInfo.answer < 350)
             setCommission({percentage: '13', type: (t('commission fee')),fee:(stepInfo.answer * 0.13).toFixed(2)});
        // else {
        //     if (stepInfo.answer >= 350 && stepInfo.answer < 401) {
        //         commission = (9 + (((8-9)/(401 - 350)) * (stepInfo.answer - 350)));
        //     }
        //     else if (stepInfo.answer >= 401 && stepInfo.answer < 751) {
        //         commission = 8;
        //     }
        //     else if (stepInfo.answer >= 751 && stepInfo.answer < 801) {
        //         commission = (8 + (((7-8)/(801 - 751)) * (stepInfo.answer - 751)));
        //     }
        //     else if (stepInfo.answer >= 801 && stepInfo.answer < 1000) {
        //         commission = 7;
        //     }
        //     else if (stepInfo.answer >= 1000 && stepInfo.answer < 1551) {
        //         commission = (7 + (((4-7)/(1551 - 1000)) * (stepInfo.answer - 1000)));
        //     }
        //     else if (stepInfo.answer >= 1551) {
        //         commission = 4;
        //     }
        //     else {
        //         commission = 4;
        //     }
        //     setCommission({percentage: commission.toFixed(2), type: (t('commission fee')), fee: (commission*0.01*stepInfo.answer).toFixed(2)});
        // }
      }, [stepInfo.answer]);

    //for fb review 
    useEffect(() => {
        if (isKnight && !stepInfo.answer) {
            setStepInfo({ ...stepInfo, answer: 50 });
        }
      }, []);

      console.log('messages', messages);
      console.log('stepInfo', stepInfo);

  //if (loading) return <Main_Loader />
  return (
    <MonthlyBudget>
        {instructions ?
            <Slider_ title={t("Budget helper")} onClose={() => toggleInstructions(!instructions)} onClick={() => toggleInstructions(!instructions)} text={t("Done")}>
                <Builder_>
                    <p>{t('Setting a clear monthly marketing budget helps optimize ad campaigns for the best results')}</p>
                    <p>{t('We help you determine the optimal budget by asking')}</p>
                    <p>{t('How much money can i spend daily? Multiply that by 30.')}</p>
                    <p>{t("Sometimes, just 'a beer/day' can significantly impact your business!")}</p>
                </Builder_>
                <Builder_ split title={t("Daily to monthly budget converter")}>
                    <Input_ placeholder={t("Daily budget")} onChange={(e) => setConverter(e.target.value)}/>
                    <MonthlyBudgetConverter>
                        <h5>{t("Your monthly budget is")}:</h5>
                        <h3>€ {converter * 30},00</h3>
                    </MonthlyBudgetConverter>
                </Builder_>
                {/* <CommissionFee>
                    <Home_Pricing hideTitle/>
                </CommissionFee> */}
            </Slider_>
        :
            null
        }
        <StepsTitle>        
            <h3>{t("What's your monthly budget?")}</h3>
            {/* {renderStepDescription(step?.step, t)} */}
        </StepsTitle>
        <Builder_>
            <BudgetFee>
            <p>{t("A 13% commission fee applies.")}</p>

            {stepInfo.answer >= 50?
                <BudgetFeeCommission>
                    <h3>{commission.fee ? '€' + commission.fee : null} </h3>
                    {/* <BudgetFeeInner>
                        <h6>= {commission.percentage}%</h6>
                        <h6>{commission.type}</h6>
                    </BudgetFeeInner> */}
                </BudgetFeeCommission>
                :null}
            {/* <BudgetFeeInfo>
                    {
                    stepInfo.answer < 50?
                        <div>
                        <div>
                            <p>
                                {t("For budgets exceeding €350, there is a staggered commission reduction from 9 to 4%. You can learn more ")}
                                <a href="#" onClick={(e) => toggleInstructions(true)}>
                                {t('here')}
                                </a>.
                            </p>
                            </div>
                            <p>{t("For budgets up to €350, a nominal 9% fee applies.")}</p>
                        </div>
                    : stepInfo.answer < 350 ?
                    <div>
                        <p>
                            {t("For budgets exceeding €350, there is a staggered commission reduction from 9 to 4%. You can learn more ")}
                            <a href="#" onClick={(e) => toggleInstructions(true)}>
                            {t('here')}
                            </a>.
                        </p>
                    </div>
                    :null
                    }
                </BudgetFeeInfo> */}
            </BudgetFee>
        </Builder_>
        <Builder_ title={t("Fill in your monthly marketing expenditures")} >
            <Input_ 
                placeholder={t('Budget')} 
                value={props.editQB && !props?.draftQuest ? Number(stepInfo.answer || 0).toFixed(2).replace(".", ",") : stepInfo.answer} 
                onChange = {handleInputChange}
                errors={messages?.errors}
                helpers={(props.editQB && !props?.draftQuest) ? [(t('Budget cannot be changed since ads are running'))] : messages?.helpers}
                successes={messages?.successes}
                disabled = {props.editQB && !props?.draftQuest ? true : false}
                className = {props.editQB && !props?.draftQuest ? 'disabledInput' :''}
            />
        </Builder_>
        <EshopConfigurePixelHelper>
            <h5>{t("If you are not sure on what to set as your monthly budget follow our guide.")}</h5>
            <Main_Plain onClick={()=>toggleInstructions(!instructions)} noPadding size="small" iconLeft="Tip" text={t("How to find out my budget")} />
        </EshopConfigurePixelHelper>
            {props.editQB ? null :
                <NetworkConsent consent={stepInfo?.consent}>
                    <h3>{t('Extra Promotion & Exposure Through the Doitforme Network!')}</h3>
                    <ul>
                        <li><h5>{t("List your products on our marketplace and gain extra visibility at no cost!")}</h5></li>
                        <li><h5>{t("Showcase your profile in our exclusive Google search results for greater reach!")}</h5></li>
                    </ul>
                    <p>{t("By registering, you agree to allow Doitforme to collect, store, and use your professional data to enhance your visibility and connect you with potential clients. Your information will only be used for lead generation and promotional purposes within the network. You can update or withdraw your consent at any time by contacting support@doitforme.eu")}</p>
                    <Toggle_Stat text={t('I agree')} active={stepInfo?.consent}  onClick={()=>setStepInfo({...stepInfo, consent: !stepInfo.consent})}/>
                </NetworkConsent>
            }
        <Steps_Actions
            icon_Grey="Back"
            icon_Main="Next"
            onClick_Grey={() => setStep({...step, step: step?.step - 1})}
            onClick_Main={()=>submit_data_and_exit_questionnaire()}
            text_Grey={t("Back")}
            text_Main={t("Next")}
        />
    </MonthlyBudget>
  )
}
