import React, { useState } from 'react'
import styled from 'styled-components'
import Dashboard_Quest from '../Dashboard_Quest'
import Archived_Old from './Archived_Old'
import Archived_Old_Preview from './Archived_Old_Preview'

const DashboardArchived = styled.div``

export default function Dashboard_Archived(props) {
  
  return (
    <DashboardArchived>
   
        <Archived_Old 
          type={props.type}
          archivedOpenLeads={props.archivedOpenLeads}
          archivedQuests={props.archivedQuests}
          archivedSetSelectedQuest={props.archivedSetSelectedQuest}
          archivedOpenBudget={props.archivedOpenBudget}
          onPreviewArchived={props.onPreviewArchived}
        />
    </DashboardArchived>
  )
}
