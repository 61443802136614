import { Main_Loader, PageFull } from 'monica-alexandria'
import {React, useContext, useEffect, useState} from 'react'
import { Landing_ } from '../../components/landing/Landing_'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string';
import l from '../../services/api/Landings';
import a from '../../services/api/Ads';

import i18n from '../../localization/i18next'
import { LandingContext } from '../../context/LandingContext';
import { load_landing } from './utils';
import { Helmet } from 'react-helmet'


const LandingView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999;
  height: 100vh;
  width: 100vw;
  overflow: auto;
`

let minioUrl = process.env.REACT_APP_MINIO_PROTOCOL +
process.env.REACT_APP_MINIO_HOST + 
process.env.REACT_APP_MINIO_PORT+ '/' +
process.env.REACT_APP_MINIO_BUCKET + '/';

export default function LANDING_VIEW() {

  const parsedQuery = queryString.parse(window.location.search);
  const id = parsedQuery.id;
  const {landing, setLanding} = useContext(LandingContext);
  const [form, setForm] = useState({});
  const navigate = useNavigate();
  const lang = i18n.language ? i18n.language : 'el';

  const [adImg, setAdImg] = useState('');

  const get_my_ad = ()=> {

    a.getAdByIdForMiniverse(id)
    .then(res => {
        if (res?.data?.code == "A2003") {
          // const image = res?.data?.ad?.thumbnail && res.data?.ad?.thumbnail !== 'default.jpg' ?  res.data?.ad?.thumbnail  : `${minioUrl}${res.data?.ad?.userId}/${res.data?.ad?.visualElement}`
          const image = res?.data?.thumbnail && res.data?.thumbnail !== 'default.jpg' ?  res.data?.thumbnail  : `${minioUrl}${res.data?.userId}/${res.data?.visualElement}` ;
          setAdImg(image)
           console.log('res?.data?.ad', res?.data?.ad);
        }
    })
  }

  const get_landing_by_id = () => {
    l.getLandingById(id)
      .then(res => {
        if (res?.data?.code == 'L2003') setLanding(load_landing(res?.data?.landing, res.data.code));
        else navigate(`/${lang}/page-not-found`)

      })
  }

  useEffect(() => {
    get_landing_by_id();
    get_my_ad();
  }, [])

  console.log(landing)
  if (!landing?.code) return <Main_Loader />

  return (
    <LandingView>
      <Helmet>
        <title>{landing?.header?.title}</title>
      </Helmet>
        <Landing_ adImg={adImg} viewMode={true}/>
    </LandingView>
  )
}
