import React, { useContext, useState } from 'react'
import { StepContext } from '../../../context/StepContext';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Builder_, IconSvg, Main_Plain, Radio_, Steps_Actions, Steps_Helper } from 'monica-alexandria';
import styled from 'styled-components';
import { push_new_answer } from '../utils';
import i18n from '../../../localization/i18next';
import { Video_Helper } from '../../../components/videoHelper/Video_Helper';

const BusinessType = styled.div`
  .notAvailable {
    opacity: 0.4;
    pointer-events: none;
  }
`;




export default function Business_Type() {

    const { step, setStep } = useContext(StepContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

   // const lang = i18n.language ? i18n.language : 'en';

  const {lang} = useParams();
    const [stepInfo, setStepInfo] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
      question: (t("Are you selling services or products?")),
      type: 'radio',
      answer: 'services'
    })


    const sectionData = [
      {
          title: (t('Criteria for your choice')),
          icon: 'Back',
          content: [
              <>
                  <IconSvg Icon="Bullet" /> <h5>{t('Nature of Offering')}</h5>
              </>,
              <p>{t('Is it tangible (like clothes or food) or intangible (like consulting services)?')}</p>,
              <>
                  <IconSvg Icon="Bullet" /> <h5>{t('Sales Venue')}</h5>
              </>,
              <p>{t('Do you sell online, have a physical location, or both?')}</p>,
              <>
                  <IconSvg Icon="Bullet" /> <h5>{t('Target Audience Interaction')}</h5>
              </>,
              <p>{t('How do you want your target audience to interact with your ads?')}</p>,
          ],
      },
      {
          title: (t('Services')),
          icon: 'Back',
          content: [
            <p>{t("Choose this if your business provides non-tangible value like consultancy, design, educational courses, or any form of service.")}</p>,
            <h5>{t("Expectation")}</h5>,
            <p>{t("The main objective is lead generation. At first we target new visitors and then through retargeting we try to maximise the conversions through lead generation.")}</p>
          ],
      },
      {
          title: (t('Products on eShop')),
          icon: 'Back',
          content: [
            <p>{t("Ideal for businesses that sell products online through an eCommerce platform.")}</p>,
            <h5>{t("Expectation")}</h5>,
            <p>{t("The main objective is to maximize the number of visits on your eshop in order then to maximise the number of sales your eshop will generate. We target your audience and then retarget the audience depending their website visit or ad view engagement.")}</p>
          ],
      },
      {
        title: (t('Physical Stores')),
        icon: 'Back',
        content: [
          <p>{t("If you have a brick-and-mortar location where customers can visit, like a retail store or an office, this is your category.")}</p>,
          <h5>{t("Expectation")}</h5>,
          <p>{t("The main objective is to maximize the engagement and frequency of your ads. This will help make your brand memorable during time.")}</p>
        ],
    },
      {
        title: (t('Bars and Restaurants')),
        icon: 'Back',
        content: [
          <p>{t("This option is for dining and entertainment venues that serve food and drinks.")}</p>,
          <h5>{t("Expectation")}</h5>,
          <p>{t("When talking about bars and restaurants, each video or photo should receive the maximum number of interactions possible. The greater the repeatability we create, the more we increase the likelihood of getting customers when it comes to when they're considering a dinner or a bar. This campaign has an objective of engagement and creates through retargeting a high frequency ad.")}</p>
        ],
    },
  ];


    const updateBusinessType = (businessType) =>{
      setStepInfo({...stepInfo, answer: businessType})
    } 

  return (
    <BusinessType>
      <h3>{t('Are you selling services or products?')}</h3>
      <Builder_>
        <Radio_
          id="services"
          text={t('Services')}
          name = "businessType"
          value="services"
          checked={stepInfo.answer == "services"}
          onClick={()=>updateBusinessType('services')}
        />
        <Radio_
          id="eshop"
       //   className='notAvailable'
          text={t('Products on eShop')}
          name = "businessType"
          value="eshop"
          checked={stepInfo.answer == "eshop"}
          onClick={()=>updateBusinessType('eshop')}
        />
        <Radio_ //This is the same as Bars and restaurants
          id="offline"
          //className='notAvailable'
          text={t('Physical stores')}
          name = "businessType"
          value="products offline"
          checked={stepInfo.answer == "products offline"}
          onClick={()=>updateBusinessType('products offline')}
        />
        <Radio_ //This is the same as Physical stores
          id="restaurants"
          // className='notAvailable'
          text={t('Bars and restaurants')}
          name = "businessType"
          value="restaurants"
          checked={stepInfo.answer == "restaurants"}
          onClick={()=>updateBusinessType('restaurants')}
        />
      </Builder_>
      <Video_Helper
        mainVideo={'https://www.youtube.com/watch?v=YWKnjMiibJM'}
        mainVideoTitle={t('Questad | What should I choose? Services or products?')}
        title={t('What should you choose?')}
        sections = {sectionData}
        textBtn={t('Help me identify')}
        text={t("Before diving into ad creation, the initial step is to clearly identify what you are selling. This is crucial because your offering determines your advertising strategy on Facebook and Instagram.")}
      />
      <Steps_Actions
        icon_Grey="Xicon"
        icon_Main="Next"
        onClick_Grey={() => navigate(`/${lang}/`)}
        onClick_Main={() => setStep({step: step?.step + 1, answers: push_new_answer(step, stepInfo)})}
        text_Grey={t("Exit")}
        text_Main={t("Next")}
      />
    </BusinessType>
  )
}
