import { Button, IconSvg } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import w from "../../../services/api/Websites"
import { useParams } from 'react-router-dom'

const DashboardSidebar = styled.div`
    padding: 2rem;
    height: 100%;
    overflow: auto;
    width: calc(100% - 10rem);
`

const DashboardSidebarSplitter = styled.div`
    display: flex;
    gap: 2rem;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 4rem);
`

const DashboardSidebarTop = styled.div``

const DashboardSidebarBot = styled.div``

const DashboardSidebarSep = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    h6{
        color: var(--greyDark);
    }

    svg{
        width: 1.4rem;
        fill: var(--greyDark);
    }

    div{
        height: 1px;
        width: 100%;
        background: var(--greyDark);
    }
`

const DashboardSidebarTitle = styled.div`
    margin-bottom: 2rem;
`

const DashboardSidebarSub = styled.ul`
    margin-bottom: 1rem;

    li{

    }
`

const DashboardSidebarSection = styled(Button)`
    background: transparent;
    margin-bottom: 1rem;
    transform: ${p => p.active ? 'translateX(-1rem)' : 'translateX(0)'};

    span{
       color: ${p => p.active ? 'var(--main)' : p.theme.color};
    }

    svg{
        fill: ${p => p.active ? 'var(--main)' : p.theme.color};
        width: 1.4rem;
        margin-bottom: 2px;
    }
`

const DashboardSidebarRecc = styled.div`
    margin-bottom: 1rem;
    padding: 2px;
    background: linear-gradient(to bottom right, var(--focus), var(--main));
    border-radius: .5rem;
    display: inline-block;

    button{
        background-color: ${p => p.theme.background};
        border-radius: .5rem;

        span{
            color: ${p => p.theme.color};
        }

        svg{
            fill: ${p => p.theme.color};
        }
    }
`

const clients = require('../../../config/index').config
export default function Dashboard_Sidebar(props) {

    const {t} = useTranslation();
    const [websites, setWebsites] = useState();
    const {lang} = useParams();

    const get_my_websites = async () => {
     
            w.getMyWebsites().then((res) => {
                const websitesRes = res.data?.data || [];  
                console.log('-----------------------res', res);
                setWebsites(websitesRes);

            })

    };

    useEffect(() => {
        get_my_websites();
    }, []);
  
console.log('---------------websites', websites);

  return (
    <DashboardSidebar>
        <DashboardSidebarTitle>
            <h6>{t("TOOLBAR")}</h6>
        </DashboardSidebarTitle>
        <DashboardSidebarSplitter>
            <DashboardSidebarTop>
                <DashboardSidebarRecc onClick={props.onReccomendations}>
                    <Button size="small">
                        <IconSvg Icon="Thunder" />
                        <span>{t("Reccomendations")}</span>
                    </Button>
                </DashboardSidebarRecc>
                <DashboardSidebarSep>
                    <IconSvg Icon="EyeOpen" />
                    <h6>{t("View")}</h6>
                    <div></div>
                </DashboardSidebarSep>
                <DashboardSidebarSub>
                    <li>
                        <DashboardSidebarSection active={props.view === 'viewStats'} noPadding onClick={props.onViewStats}>
                            <IconSvg Icon="Report" />
                            <span>{t("Stats")}</span>
                        </DashboardSidebarSection>
                    </li>
                    {props.type === 'services' ?
                        <li>
                            <DashboardSidebarSection active={props.view === 'viewLeads'} noPadding onClick={props.onViewLeads}>
                                <IconSvg Icon="User" />
                                <span>{t("Leads")}</span>
                            </DashboardSidebarSection>
                        </li>
                    :
                        null
                    // :props.type === 'eshop' ?
                    //     <li>
                    //         <DashboardSidebarSection active={props.view === 'viewPageviews'} noPadding onClick={props.onViewPageviews}>
                    //             <IconSvg Icon="File" />
                    //             <span>{t("Pageviews")}</span>
                    //         </DashboardSidebarSection>
                    //     </li>
                    // :
                    //     <li>
                    //         <DashboardSidebarSection active={props.view === 'viewEngagements'} noPadding onClick={props.onViewPageviews}>
                    //             <IconSvg Icon="Heart" />
                    //             <span>{t("Engagements")}</span>
                    //         </DashboardSidebarSection>
                    //     </li>
                    }
                    {props.isPocketAgencyUser ? null 
                    :
                        <li>
                            <DashboardSidebarSection active={props.view === 'viewHistory'} noPadding onClick={props.onViewHistory}>
                                <IconSvg Icon="History" />
                                <span>{t("History")}</span>
                            </DashboardSidebarSection>
                        </li>
                    }
                </DashboardSidebarSub>
                <DashboardSidebarSep>
                    <IconSvg Icon="Services" />
                    <h6>{t("Manage")}</h6>
                    <div></div>
                </DashboardSidebarSep>
                <DashboardSidebarSub>
                {props.isPocketAgencyUser ? null :
                    <li>
                        <DashboardSidebarSection active={props.view === 'editQuest'} noPadding onClick={props.onEditQuest}>
                            <IconSvg Icon="Edit" />
                            <span>{t("Quest")}</span>
                        </DashboardSidebarSection>
                    </li>
                }
                    <li>
                        <DashboardSidebarSection active={props.view === 'editSubscription'} noPadding onClick={props.onEditSubscription}>
                            <IconSvg Icon="Payment" />
                            <span>{t("Subscription")}</span>
                        </DashboardSidebarSection>
                    </li>
                    {websites?.length > 0 ? 
                        <li>
                            <DashboardSidebarSection noPadding onClick={()=>window.location.assign(`${clients['miniverse']}/${lang}/`)}>
                                <IconSvg Icon="Website" />
                                <span>{t("Mini website")}</span>
                            </DashboardSidebarSection>
                        </li>
                    :null}
                </DashboardSidebarSub>
            </DashboardSidebarTop>
            {props.isPocketAgencyUser ? null 
            :<DashboardSidebarBot>
                <DashboardSidebarSep>
                    <IconSvg Icon="Rocket" />
                    <h6>{t("Quests")}</h6>
                    <div></div>
                </DashboardSidebarSep>
               
               :<DashboardSidebarSub>
                    <li>
                        <DashboardSidebarSection active={props.view === 'questsActive'} noPadding onClick={props.onQuestsActive}>
                            <IconSvg Icon="Folder" />
                            <span>{t("Active")}</span>
                        </DashboardSidebarSection>
                    </li>
                    <li>
                        <DashboardSidebarSection active={props.view === 'questsArchived'} noPadding onClick={props.onQuestsArchived}>
                            <IconSvg Icon="Folder" />
                            <span>{t("Archived")}</span>
                        </DashboardSidebarSection>
                    </li>
                </DashboardSidebarSub>
              
                {/* DRAFT QUESTS */}
                {/* <DashboardSidebarSep>
                    <IconSvg Icon="Recycle" />
                    <h6>{t("Draft")}</h6>
                    <div></div>
                </DashboardSidebarSep>
                <DashboardSidebarSub>
                    <li>
                        <DashboardSidebarSection noPadding onClick={props.onDraftQuests}>
                            <IconSvg Icon="Folder" />
                            <span>{t("Quest")}</span>
                        </DashboardSidebarSection>
                    </li>
                    <li>
                        <DashboardSidebarSection noPadding onClick={props.onQuestsArchived}>
                            <IconSvg Icon="Folder" />
                            <span>{t("Ad placements")}</span>
                        </DashboardSidebarSection>
                    </li>
                    <li>
                        <DashboardSidebarSection noPadding onClick={props.onQuestsArchived}>
                            <IconSvg Icon="Folder" />
                            <span>{t("Landing pages")}</span>
                        </DashboardSidebarSection>
                    </li>
                </DashboardSidebarSub> */}
            </DashboardSidebarBot>
            }
        </DashboardSidebarSplitter>
    </DashboardSidebar>
  )
}
