import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
// import './Range_.css';

const SliderContainer = styled.div``;

const Slider = styled.div`
  position: relative;
  width: 100%;
`;

const Track = styled.div`
  border-radius: var(--smallRadius);
  height: .5rem;
  position: absolute;
  background-color: var(--grey);
  width: 100%;
  z-index: 1;
`;

const Range = styled.div`
  border-radius: 3px;
  height: 5px;
  position: absolute;
  background-color: var(--mainLight);
  z-index: 2;
`;

const Thumb = styled.input.attrs({
  type: 'range',
  thumbClass: props => (props.zIndex ? `thumb thumb--zindex-${props.zIndex}` : 'thumb'),
  })`
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  z-index: ${props => (props.zIndex ? props.zIndex : 1)};

  &::-webkit-slider-thumb {
    -webkit-appearance: none; 
    appearance: none;
    background-color: var(--main);
    border: none;
    border-radius: 50%;
    cursor:  ${p => (p.disabled ? 'not-allowed' : 'pointer')};
    
    margin-top: .4rem;
    pointer-events: all;
    position: relative;
    height: 1.5rem;
   width: 1.5rem; 
  }

  &::-moz-range-thumb {
    background-color: var(--mainLight);
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

`;

const RangeValues = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
`

const Double_Range = ({ min, max, onChange, defaultMin, defaultMax,  disabled }) => {
  const [minVal, setMinVal] = useState(defaultMin || min);
  const [maxVal, setMaxVal] = useState(defaultMax || max);

    const range = useRef(null);

    const minValRef = useRef(null);
    const maxValRef = useRef(null);


    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100), [min, max]
    );
    
    // Set width of the range to decrease from the left side
    useEffect(() => {
      if (maxValRef.current) {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(+maxValRef.current.value); 
    
        if (range.current) {
          range.current.style.left = `${minPercent}%`;
          range.current.style.width = `${maxPercent - minPercent}%`;
        }
      }
    }, [minVal, getPercent]);
    
    // Set width of the range to decrease from the right side
    useEffect(() => {
      if (minValRef.current) {
        const minPercent = getPercent(+minValRef.current.value);
        const maxPercent = getPercent(maxVal);
    
        if (range.current) {
         range.current.style.width = `${maxPercent - minPercent}%`;
        }
      }
    }, [maxVal, getPercent]);

    useEffect(() => {
      // Set initial values when the component mounts
      setMinVal(defaultMin || min);
      setMaxVal(defaultMax || max);
    }, [defaultMin, defaultMax, min, max]);
    
    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: minVal, max: maxVal });
    }, [minVal, maxVal]);

    return (
    <SliderContainer>
        <Slider>
            <Thumb
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={(event) => {
                    // + converts value to a number
                    const value = Math.min(+event.target.value, maxVal - 1);
                    setMinVal(value);
                    event.target.value = value.toString();
                }}
                zIndex={minVal > max - 100 ? 5 : undefined}
                disabled={disabled} 
            />
            <Thumb
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={(event) => {
                    const value = Math.max(+event.target.value, minVal + 1);
                    setMaxVal(value);
                    event.target.value = value.toString();
                }}
                zIndex={4}  
                disabled={disabled} 
            />
            {/* background */}
            <Track />
            {/* fill */}
            <Range ref={range} />
            <RangeValues>
              <h5>{minVal}</h5>
              <h5>{maxVal === max ? `${maxVal}+` : `${maxVal}`}</h5>
            </RangeValues>
        </Slider>
    </SliderContainer>

  );
};

export default Double_Range;