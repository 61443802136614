import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const GraphChart = styled.div`
    height: 35rem;
`

const GraphChartTitle = styled.h4`
    color: var(--greyDark) !important;
    text-align: center;
`

const GraphChartSubtitle = styled.p`
    color: var(--greyDark) !important;
    text-align: center;
`

const GraphChartSep = styled.div`
    height: 2px;
    border-radius: 1px;
    background: var(--greyDark);
    margin: .5rem auto 1rem auto;
    width: 90%;
    text-align: center;
`

const GraphChartTotal = styled.h2`
    margin-bottom: 2rem;
    text-align: center;
    font-size: 5rem;
`

const GraphChartWrapper = styled.div`
    height: calc(100% - 14rem);

    .recharts-cartesian-grid-horizontal{

        line{
            stroke: var('grey');
            opacity: .3;
            stroke-width: 2px;
        }
    }

    .recharts-curve{
        stroke: var(--main);
        stroke-width: 3px;
    }

    .recharts-yAxis{
        transform: translateX(-.7rem);

        line{
            display: none;
        }

        text{
            tspan{
                font-family: var(--fontBold);
                fill: ${p => p.theme.color};
            }
        }
    }

    .recharts-xAxis{

        line{
            display: none;
        }

        tspan{
            font-family: var(--fontBold);
            fill: ${p => p.theme.color};
        }

        transform: translate(1rem, 1rem);
    }
    
`

const DashboardTooltip = styled.div``

export default function Graph_Chart(props) {  

    const {t} = useTranslation();
    
    function CustomTooltip({ active, payload, label }) {
        if (active && payload && payload.length) {
          return (
            <DashboardTooltip>
                <h6>{props.title}: {payload[0].value}</h6>
            </DashboardTooltip>
          );
        }
      
        return null;
    }

    function getMonthAbbreviation(monthNumber) {
        const months = [
            (t('Jan')), 
            (t('Feb')), 
            (t('Mar')), 
            (t('Apr')), 
            (t('May')), 
            (t('Jun')),
            (t('Jul')), 
            (t('Aug')), 
            (t('Sep')), 
            (t('Oct')), 
            (t('Nov')), 
            (t('Dec'))
        ];
        
        if (monthNumber < 1 || monthNumber > 12) {
            return "Invalid month number";
        }
        
        return months[monthNumber - 1];
    }

    function formatXAxis(dateString) {
        // Split the date string into year, month, and day
        const [year, month, day] = dateString.split('-');

        // Remove leading zeros if present
        const formattedDay = day.replace(/^0+/, '');
        const formattedMonth = month.replace(/^0+/, '');



        // Return the formatted date
        return `${formattedDay} ${getMonthAbbreviation(formattedMonth)}`;
    }

    function formatYAxis(number) {
        // Check if the number is one thousand or more but less than a million
        if (number >= 1000 && number < 1000000) {
            return (number / 1000)?.toFixed(1).replace(/\.0$/, '') + 'K';
        }
        // Check if the number is one million or more
        else if (number >= 1000000) {
            return (number / 1000000)?.toFixed(1).replace(/\.0$/, '') + 'M';
        }
        // Handle numbers less than 1000 with up to two decimal places
        else if (!Number.isInteger(number)) {
            return number?.toFixed(2);
        }
        // Handle integer values under 1000
        return number;
    }

    function calculateXAxisInterval(dataLength) {
        // Ensure there's a division by zero check
        if (dataLength <= 5) return 0;  // If data length is 5 or fewer, show all ticks
        return Math.ceil(dataLength / 5) - 1;  // Calculate interval to fit roughly 5 labels
      }

    function formatTitle(title) {
        if (!title) {
            return;
        }
        switch (title) {
            case 'clicks':
                return { title: t("Clicks"), subtitle: t("The number of times users click on your ad.") };
            case 'frequency':
                return { title: t("Frequency"), subtitle: t("The average number of times a person sees your ad.") };
            case 'impressions':
                return { title: t("Impressions"), subtitle: t("The total number of times your ad is displayed.") };
            case 'reach':
                return { title: t("Reach"), subtitle: t("The number of unique users who see your ad.") };
            default:
                return { title: "", subtitle: "" };
        }
    }
    const { title, subtitle } = formatTitle(props.title);

  return (
    <GraphChart>
        <GraphChartTitle>{formatTitle(props.title)?.title}</GraphChartTitle>
        <GraphChartSubtitle>{formatTitle(props.title)?.subtitle}</GraphChartSubtitle>
        <GraphChartSep></GraphChartSep>
        <GraphChartTotal>{formatYAxis(props?.total)}</GraphChartTotal>
        <GraphChartWrapper>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    // TODO: create an object that has initial data as ZERO (0)
                    data={props?.data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid horizontal={true} vertical={false}/>
                    <XAxis dataKey={props.xDataKey} tickFormatter={formatXAxis} interval={calculateXAxisInterval(props.data?.length)} />
                    <YAxis axisLine={false} domain={['auto', 'auto']} tickFormatter={formatYAxis}/>
                    <Tooltip content={<CustomTooltip />} cursor={false}/>
                    <Line type="monotone" dot={false} dataKey={props.lineDataKey} activeDot={{r:2}}  />
                </LineChart>
            </ResponsiveContainer>
        </GraphChartWrapper>
    </GraphChart>
  )
}
